import Api from "./Api";

class AddressCitiesApi extends Api {
    constructor() {
        super("/camera-address");
    }

    getCities = async (page, pageSize) =>
        this.fetch(`page=${page}&size=${pageSize || ""}`);

    getAllCities = async () => {
        let cities = [];
        let nextContent;
        let page = 0;
        do {
            const { content } = await this.getCities(page++, 50);
            nextContent = content;
            cities = cities.concat(content);
        } while (nextContent.length > 0);
        return cities;
    };
}
export default AddressCitiesApi;
