import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import { FaStar, FaCheck } from "react-icons/fa";
import { Else, If, Then, When } from "react-if";
import classnames from 'classnames'
import styles from "./style.module.sass";

const Wapper = ({ popup, children }) => (
  <If condition={!popup}>
    <Then>{children}</Then>
    <Else>
      <span className={styles["popup"]}>{children}</span>
    </Else>
  </If>
)

export default ({ popup, setSelectedTag, selectedTag, tags, reverse=false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(v => !v);
  return (
    <Wapper popup={popup}>
      <div className={classnames(["d-flex", { "flex-row": !reverse, "float-left": reverse, "flex-row-reverse": reverse }])}>
        <span className={classnames(["mr-2", { "mt-3": reverse }])}>
          <When condition={!!selectedTag}>
            <Badge color="warning" className="text-center" style={{ textTransform: "uppercase" }}>
              {selectedTag?.name}
            </Badge>
          </When>
        </span>
        <span className={classnames([{ "mr-5": reverse }])}>
          <FaStar
            className={classnames([{ [styles["dropdown-toggle"]]: reverse }, styles["icon-overlay"]])}
            size="30"
            color={!selectedTag ? "#ddd" : "#ee0"}
            style={{ right: "0%" }}
            onClick={toggle}
          />
          <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle color="tranparent" className={styles["hidden-dropdown"]}/>
            <DropdownMenu className={classnames([styles["dropdown-menu"], { [styles.reverse]: reverse }])}>
              <DropdownItem style={{ cursor: "pointer" }} onClick={() => setSelectedTag(null)} active={!selectedTag}>
                <span className="nav-link">
                  <FaCheck color="green" />
                </span>
              </DropdownItem>
              {tags.map((opt) => (
                <DropdownItem key={opt.id} style={{ cursor: "pointer" }} onClick={() => setSelectedTag(opt)} active={selectedTag === opt}>
                  <span className="nav-link">{opt.name}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </span>
      </div>
    </Wapper>
  );
};
