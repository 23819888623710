const enumValue = description => Object.freeze({toString: () => description});

// export const SPOTLIGHT = enumValue('SPOTLIGHT');
// export const DATE_UPLOADED = enumValue('DATE_UPLOADED');
// export const DATE_CAPTURED = enumValue('DATE_CAPTURED');
// export const HITS = enumValue('HITS');

export const PLATE = enumValue('PLATE')
export const QUALIFIED_DATE = enumValue('QUALIFIED_DATE')
export const FOUND = enumValue('FOUND')
export const APPROVED = enumValue('APPROVED')
export const LAST_CAPTURE = enumValue('LAST_CAPTURE')
export const BATCH_CREATE = enumValue('BATCH_CREATE')
export const NO_SORT = enumValue('');

export function find(sort){
    return [
        PLATE,
        QUALIFIED_DATE,
        FOUND,
        APPROVED,
        LAST_CAPTURE,
        BATCH_CREATE,
        NO_SORT
    ].find(s => s.toString() === sort)
}
