import Api, { get, post, put } from "./Api";
import ndjson from 'fetch-ndjson'
// DOCS -> https://sandbox.carbigdata.com.br/api/v2/company-api/swagger-ui.html
// BFF DOCS -> https://sandbox.carbigdata.com.br/app/v1/company-app/swagger-ui.html
class CompanyApi extends Api {
  constructor() {
    super("/company");
  }

  create = data => post(`${process.env.REACT_APP_BFF_ENDPOINT}/company`, data)

  update = (data) => put(`${process.env.REACT_APP_BFF_ENDPOINT}/company`, data)

  getCompanies = (
    page,
    pageSize,
    company,
    typeId,
    order,
    sort
  ) => get(
    `${(process.env.REACT_APP_AUTH_ENDPOINT || "")}/company?` +
    `page=${page || ""}&size=${pageSize || ""}&order=${order}&sort=${sort}` +
    `&name=${company}&typeId=${typeId}`
  );

  getCompany = id => get(`${(process.env.REACT_APP_AUTH_ENDPOINT || "")}/company/${id}`);

  getCompanyTypes = () => get(`${process.env.REACT_APP_AUTH_ENDPOINT}/company-type`);

  getDepartments = (uuid) => get(`${this.baseUrl}/${uuid}/department`);


  getAllCompanies = async () => {
    let companies = [];
    let nextContent = [];
    let page = 0;
    do {
      const { content } = await this.getCompanies(
        page++,
        50,
        "",
        "",
        "",
        ""
      );
      nextContent = content;
      companies = companies.concat(nextContent);
    } while (nextContent.length > 0);
    return companies;
  };

  async getAllStream() {
    const response =  (await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/company/stream`,
      {
        headers:
        {
          Authorization: "Bearer " + localStorage.getItem("TOKEN")
        }
      }
    ))
    const companies = [];
    const reader = response.body.getReader();
    const readableStream = ndjson(reader);
    let endStream = false;
    while(!endStream){
      let {done, value} = await readableStream.next();
      if(value){
        companies.push(value);
      }
      endStream = done;
    }
    return companies;
  }

}
export default CompanyApi;
