import { observable, action, computed, runInAction } from 'mobx';
import { setDefaultHeader } from '../api/http';
import { authorizerApi } from '../api';
import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'TOKEN';
const USER_KEY = 'USER';

class AuthorizerStore{

    @observable
    user = '';
    @observable
    password = '';
    @observable
    token = '';
    @observable
    roles = [];
    @observable
    loading = false;
    @observable
    currentData = new Date()

    constructor(api){
        this.api = api;
        const token = localStorage.getItem(TOKEN_KEY);
        setInterval(
            () => runInAction(
                () => this.currentData = new Date()
            ),
            60000
        );
        if (!!token && this.isValid(token)) {
            this.setToken(token);
            this.setRoles(token);
        } 
    }

    @action
    setUser = user => this.user = user;

    @action
    setPassword = password => this.password = password;

    @action
    setToken = token => {
        this.token = token;
        setDefaultHeader('Authorization', token ? `Bearer ${token}`: '');
        localStorage.setItem(TOKEN_KEY, token);
        if (token){
            const exp = new Date(jwtDecode(token).exp * 1000)
            document.cookie = `cbd_jwt=${token}; expires=${exp.toUTCString()}; path=/; Domain=.carbigdata.com.br; SameSite=Lax;`;
        }
    }

    @action
    setName = token => {
        if(token){
            const { sub } = jwtDecode(token);
            localStorage.setItem(USER_KEY, sub);
        }
    }

    @action
    setRoles = token => {
        if(token) {
            const { Role } = jwtDecode(token);
            this.roles = Role;
        }
    }

    @computed
    get authenticated(){
        return this.token && this.isValid(this.token);
    }

    isAllowed(roles){
        return roles.length === 0 ||
            !!roles.map(
                role => role.toString()
            ).filter(
                role => this.roles.includes(role)
            ).length;
    }

    isValid(token){
        const decodedToken = jwtDecode(token);
        const { exp } = decodedToken
        const date = this.currentData.getTime();
        return exp * 1000 > date;
    }

    @action
    authenticate = () => {
        this.loading = true;
        const { user, password } = this;
        return this.api.authenticate(user, password)
            .then(this.success)
            .catch(this.fail);
    }

    @action
    success = token  => {
        this.setToken(token);
        this.setRoles(token);
        this.setName(token);
        this.setUser('');
        this.setPassword('');
        this.loading = false;
    }

    @action
    fail = err => {
        console.error(err);
        this.loading = false;
        throw err;
    }

    getCompany = () => {
        if(this.token) {
            const {Company} = jwtDecode(this.token);
            return Company;
        }
    }
}

const store = new AuthorizerStore(authorizerApi);
export default store;