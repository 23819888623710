export default class {
    constructor(name, action){
        this._name = name;
        this._action = action
    }
    get name() {
        return this._name;
    }

    execute(state, payload) {
        return this._action(state, payload);
    }
}

export function getReducer(actions) {
    return function(state, { payload, type }) {
        return actions[type]?.execute(state, payload)|| state;
    }
}