import Api, { downloadByLink } from "./Api";

export default class extends Api {
    constructor(){
        super(`${process.env.REACT_APP_BFF_ENDPOINT}/search-batch`)
    }

    downloadSource(uuid, filename){
        downloadByLink(
            `${this.baseUrl}/${uuid}/source`,
            filename
        );
    }

    export(uuid, filename, company, type='TARGET'){
        downloadByLink(
            `${this.baseUrl}/${uuid}/export?type=${type}&companyUuid=${company}`,
            filename
        );
    }

    upload(file, request){
        let form = new FormData();
        form.append("csvFile", file);
        const json = JSON.stringify(request);
        const blob = new Blob([json], {
           type: 'application/json'
        });
        form.append("batchRequest", blob);
        return this.create(form, {
            // cancelToken: source.token,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            // onUploadProgress,
        });
    }
}