const enumValue = (description, label) => Object.freeze({toString: () => description, label});

export const FIXED = enumValue('FIXED', 'Fixa');
export const MOBILE = enumValue('MOBILE', 'Móvel');
export const APP = enumValue('APP', 'Aplicativo');

export const values = () => [
    FIXED,
    MOBILE,
    APP
];

export const find = value => values().find(type => type.toString() === value)