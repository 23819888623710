import React, { Fragment, useEffect } from "react";
import { Row, Col, Alert, Table, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import uuid from 'react-uuid';
import { FaInfoCircle, FaCheckSquare } from "react-icons/fa";
import styles from "./style.module.sass";
import { Else, If, Then, When } from "react-if";
import { DataTypeEnum } from "../../model";

const Cell = ({ cell, validator, onValidate, required, min, max }) => {
    const danger = !validator?.validate(cell, min, max)|| false
    useEffect(() => {
        if (required && danger){
            onValidate(true)
        }
    }, [danger])
    return (
        <td key={uuid()} className={classnames([{ 'table-danger': required && danger, 'table-warning' : !required && danger }])}>
            <When condition={validator?.toString() === DataTypeEnum.ALPHANUMERIC.toString() && danger}>
                <strong>Consulte os limites minimos e máximos de caracteres para esse campo com o administrador.</strong>
                <br />
            </When>
            { cell?.toUpperCase() }
        </td>
    )
}

export default ({ preview, schema, onValidate }) => {
    const validate = schema||[]
    const headers = preview.reduce(
        (acc, curr) => new Set(
            [
                ...acc,
                ...Object.keys(curr).filter(key => !!key.trim()).map(key => key.toUpperCase())
            ]
        ),
        new Set()
    );
    const lines = preview.slice(0);
    const notFound = validate.filter(({ name, required }) => required && ![...headers].includes(name.toUpperCase()));
    useEffect(() => {
        if(notFound.length > 0){
            onValidate(true);
        }
    }, [notFound]);

    return (
        <If condition={!notFound.length}>
            <Then>
                <div className={styles.preview}>
                    <Alert color="info">
                        <Row>
                            <Col md="1">
                                <FaInfoCircle/>
                            </Col>
                            <Col>
                                O preview abaixo apresenta somente {preview.length > 15 ? 15 : preview.length} linhas do arquivo.
                            </Col>
                        </Row>
                    </Alert>
                    <Table striped bordered className={styles.table}>
                        <thead className="thead-dark">
                            <tr>
                                {
                                    [...headers].map(
                                        header => (
                                            <th key={uuid()}>
                                                {header}&nbsp;
                                                <When condition={!!validate.find(({ name }) => name === header)}>
                                                    <FaCheckSquare id={header}/>
                                                    <UncontrolledTooltip placement="top" target={header}>
                                                        Coluna validada pelas configurações da empresa
                                                    </UncontrolledTooltip>
                                                    &nbsp;
                                                    <FaInfoCircle id={`description-${header}`}/>
                                                    <UncontrolledTooltip placement="top" autohide={false} target={`description-${header}`}>
                                                        { validate.find(({ name }) => name === header)?.dataType?.description }
                                                    </UncontrolledTooltip>
                                                </When>
                                            </th>
                                        )
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lines.map((line, index) => (
                                    <When key={uuid()} condition={index < 15}>
                                        <tr>
                                            {
                                                [...headers].map(
                                                    header => {
                                                        const {
                                                            dataType: validator,
                                                            required,
                                                            constraint
                                                        } = validate.find(({ name }) => name.toUpperCase() === header)||{};
                                                        const { min, max } = constraint||{};
                                                        const cell = line[header]
                                                        return (
                                                            <Cell key={uuid()} { ...{ cell, validator, onValidate, required, min, max } }/>
                                                        )
                                                    }
                                                )
                                            }
                                        </tr>
                                    </When>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </Then>
            <Else>
                <Alert color="danger">
                    As seguintes colunas Obrigatórias estão faltando: <br/>
                    {
                        notFound.map(
                            ({ id, name, label }) => (
                                <Fragment key={id}>
                                    {name}&nbsp;
                                    <small>({label})</small>
                                    <br/>
                                </Fragment>
                            )
                        )
                    }
                </Alert>
            </Else>
        </If>
    )
} 