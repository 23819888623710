import axios from 'axios';
import AuthorizerStore from '../../stores/AuthorizerStore';

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;

axios.interceptors.response.use(
    res => !!res && !!res.data ? res.data : null,
    err => {
        const { response } = err;
        if (!!response && (response.status === 401 || response.status === 403)) {
            AuthorizerStore.setToken('');
        }
        return Promise.reject(err);
    }
);

export const setDefaultHeader = (header, value) => {
    if(!!value){
        axios.defaults.headers.common[header] = value;
    } else {
        delete axios.defaults.headers.common[header]
    }
};