import Api, { download, get } from "./Api";
import { formatDate } from "utils";

//DOCS -> https://sandbox.carbigdata.com.br/api/v1/capture-api/swagger-ui.html
class CaptureApi extends Api {

    constructor() {
        super(process.env.REACT_APP_CAPTURE_ENDPOINT);
        this.captureV2BaseUrl = process.env.REACT_APP_CAPTURE_ENDPOINT_V2
    }

    getImageV1 = (path, source, frameSize = "LARGE") =>
    new Promise(async (resolve, reject) => {
        try {
            const img = await get(
                `${this.baseUrl}/image/${path}?frameSize=${frameSize}&source=${source}`,
                { responseType: "blob" }
            );
            const reader = new FileReader();
            reader.readAsDataURL(
                new Blob([img], {
                    type: "image/jpg",
                })
            );
            reader.onloadend = () => resolve(reader.result);
        } catch (e) {
            reject(e);
        }
    });

    export(filter, order, sort, columns){
        const { openField, confidence, startDate, finishDate, devices, startTime, finishTime, spotlight, reproved, hash } = filter;
        let confidenceAtt = !!confidence.length ? `&confidence=${confidence}` : '';
        const url =
            `openFields=${openField}${confidenceAtt}&startDate=${formatDate(startDate)}&startTime=${startTime && startTime.slice(0,5)}` +
            `&finishDate=${formatDate(finishDate)}&finishTime=${finishTime && finishTime.slice(0,5)}&order=${order}&sort=${sort}&spotlight=${spotlight || ''}` +
            `&devices=${devices.map(({ value }) => value).join(',')}&reproved=${reproved || ''}&requestId=${hash}` + 
            `&${columns.join(',')}`;
        download(`${this.baseUrl}/export?${url}`, `.csv`, "text/csv");
    }


    getImage = (fullPath) =>
        new Promise(async (resolve, reject) => {
            try {
                const img = await get(`${this.captureV2BaseUrl}/image/${fullPath}`, { responseType: "blob" });
                const reader = new FileReader();
                reader.readAsDataURL(
                    new Blob([img], {
                        type: "image/jpg",
                    })
                );
                reader.onloadend = () => resolve(reader.result);
            } catch (e) {
                reject(e);
            }
        });

    getDevices = async () => {
        const data = await get(`${this.baseUrl}/device/`);
        return Object.keys(data).map((key) =>
            data.hasOwnProperty(key)
                ? {
                      label: key,
                      expanded: true,
                      children: data[key].map((opt) => ({
                          label: opt,
                          value: opt.split(" - ")[0],
                          className: "ellipsis",
                      })),
                  }
                : null
        );
    };

    getMapView = ({
        openField,
        confidence = [],
        startDate,
        finishDate,
        devices = [],
        startTime,
        finishTime,
        spotlight = "",
        reproved = "",
        avaliate,
        hash,
    }) => {
        const confidenceAtt = !!confidence.length
            ? `&confidence=${confidence}`
            : "";
        const devicesAtt = !!devices.length
            ? `&devices=${devices.map(({ value }) => value).join(",")}`
            : "";
        const url =
            `openFields=${openField}` +
            `${confidenceAtt}&startDate=${formatDate(startDate)}` +
            `&startTime=${startTime}&finishDate=${formatDate(
                finishDate
            )}&finishTime=${finishTime}${devicesAtt}` +
            `&spotlight=${spotlight || ""}&reproved=${reproved || ""}` +
            `&requestId=${hash}`;
        return get(`${this.baseUrl}/mapView?${url}`);
    };
}

export default CaptureApi;
