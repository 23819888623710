import Api, { get, post, put } from "./Api";

class HypothesisApi extends Api {
  constructor() {
    super("/hypothesis");
  }

  create = (data) => post(`${process.env.REACT_APP_ENDPOINT}/hypothesis`, data)

  update = (id, data) => put(`${process.env.REACT_APP_ENDPOINT}/hypothesis/${id}`, data)

  getAllHypotheses = async (uuid) => {
    const params = {}
    if(uuid) params['company'] = uuid
    let contentToReturn = [];
    let currentContent = [];
    let page = 0;
    do {
      const {content} = await get(`${process.env.REACT_APP_ENDPOINT}/hypothesis`, {params: {...params, page}});
      currentContent = content;
      contentToReturn = contentToReturn.concat(content);
      page++;
    } while(currentContent.length>0);
    return {content:contentToReturn}
  }

}

export default HypothesisApi