import React, { forwardRef } from "react";
import InputMask from "react-input-mask";
import { Input } from "reactstrap";

export default forwardRef(({ id, value, onChange, disabled, style={}, type="text" }, ref) => (
    <InputMask
        id={id}
        mask="aaa9*99"
        value={value}
        alwaysShowMask={false}
        maskChar=""
        placeholder="Placa"
        disabled={disabled}
        type={type}
        style={{ textTransform: 'uppercase', ...style }}
        onChange={({ target: { value } }) => onChange(value.toUpperCase())}>
        { inputProps => <Input innerRef={ref} {...inputProps}/> }
    </InputMask>
));