const enumValue = (description, translateDescription) =>
  Object.freeze({
    toString: () => description,
    translate: () => translateDescription,
  });

export const CONNECTED = enumValue("CONNECTED", "Conectada");
export const QUEUED = enumValue("QUEUED", "Na Fila");
export const DISCONNECTED = enumValue("DISCONNECTED", "Desconectada");
export const CONNECTING = enumValue("CONNECTING", "Conectando");
export const DISABLED = enumValue("DISABLED", "Desligada");

export const values = () => [CONNECTED, QUEUED, DISCONNECTED, CONNECTING, DISABLED];

export const parser = (status) => values().find((value) => value.toString() === status);