import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Col, Form, FormGroup, Label } from "reactstrap";
import { When } from "react-if";
import qs from "qs";
import FeedbackForm from "./Form";
import { useFeedbackModalContext } from "./Context";
import Loader, { Resolve } from "components/loader";
import { feedbackApi, occurrenceApi } from "api";
import { getCurrentUser, isAllow } from "utils";
import { Roles, DataTypeEnum } from "model";
import { ConfirmModal } from "components";

async function getInsureClaim(plate) {
    try {
        const { Company } = getCurrentUser();
        const filter = qs.stringify({ plate, companyUuid: Company });
        const [content] = (await occurrenceApi.fetch(filter)).content;
        const clientData = content?.clientData || [];
        const insureClaim = clientData?.find(({ dataType }) => dataType === DataTypeEnum.SINISTRO.toString())?.value;
        return insureClaim || "";
    } catch {
        return "";
    }
}

async function getFeedback(plate){
    const { Company, Email } = getCurrentUser();
    const filter = qs.stringify({ plate, company: Company, email: isAllow([Roles.ROLE_INSURANCE_MANAGER, Roles.ROLE_HOTLIST_MANAGER])? Email: '' }, { filter: (_, value) => value || undefined});
    const { content } = (await feedbackApi.fetch(filter));
    let feedback = content[0];
    if (isAllow([Roles.ROLE_INSURANCE_MANAGER, Roles.ROLE_HOTLIST_MANAGER])) {
        feedback = content.find(({ feedbackAnalysisDTO }) => !!feedbackAnalysisDTO);
    }
    return feedback;
}

async function loadFeedback(plate, id) {
    if(!id){
        const [feedback, insureClaim] =  await Promise.all([
            getFeedback(plate),
            getInsureClaim(plate)
        ]);
        try {
            if (feedback?.id) {
                const data = await feedbackApi.get(feedback.id);
                return { ...feedback, ...data, insureClaim };
            }
        } catch (e) {
            console.error(e);
        }
        return {};
    } else {
        return feedbackApi.get(id); 
    }
}

async function submitFeedback({ feedbackInspectDTO: _, feedbackAnalysisDTO, ...feedback }, onSubmit) {
    const { useful, hypothesis } = feedbackAnalysisDTO;
    if((useful && hypothesis?.id) || useful === false) {
        const { value, avoidedInquiry, refusedClaim, releasedClaim, notDefined, inquiryValue } = feedbackAnalysisDTO;
        const params = {
            ...feedback,
            value: useful && !releasedClaim && !notDefined? (value|| 0.0) : 0.0,
            feedbackAnalysisDTO: {
                ...feedbackAnalysisDTO,
                releasedClaim: useful && releasedClaim,
                refusedClaim: useful && refusedClaim,
                notDefined: useful && !releasedClaim && notDefined,
                avoidedInquiry: useful && !!avoidedInquiry,
                inquiryValue: useful && avoidedInquiry? inquiryValue : undefined,
            }
        }
        try{
            if(feedback.id){
                await feedbackApi.update(feedback.id, params);
                onSubmit();
            } else {
                await feedbackApi.create(params);
            }
        } catch (e) {
            throw e.resposne.status === 400? new Error("Reveja as informações do formulário"): e
        }
    } else {
        if (useful === null) {
            throw new Error('O campo “Pesquisa foi Útil” é obrigatório.');
        } else {
            throw new Error('O campo “Hipótese” é obrigatório.');
        }
    }
}

async function submitInspection({ feedbackInspectDTO, feedbackAnalysisDTO: _, ...feedback }, onSubmit) {
    const { Company } = getCurrentUser()
    const { others, coveredDamages } = feedbackInspectDTO;
    if(others || coveredDamages) {
        const { flaggedAnalyst, helpful, vehiclePartsDTO } = feedbackInspectDTO;
        const params = {
            ...feedback,
            value: coveredDamages? (feedback.value|| 0.0) : 0.0,
            feedbackInspectDTO: {
                coveredDamages,
                others,
                flaggedAnalyst: others && flaggedAnalyst,
                helpful: coveredDamages && helpful,
                vehiclePartsDTO: coveredDamages? vehiclePartsDTO.map(vehiclePart=> ({ ...vehiclePart, companyUuid: Company })): []
            }
        };
        try{
            if(feedback.id){
                await feedbackApi.update(feedback.id, params);
                onSubmit();
            } else {
                await feedbackApi.create(params);
            }
        } catch (e) {
            throw e.resposne.status === 400? new Error("Reveja as informações do formulário"): e
        }
    } else {
        throw new Error('O Campo “Hipótese” é obrigatório.')
    }
}

export default function FeedbackModal({ id, children, trackingId, plate, hits, onClose=()=>{} }) {
    const [context, { setBatchUUid }] = useFeedbackModalContext();
    const [isOpen, setOpen] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasErrorServer, setHasErrorServer] = useState(false);
    const [error, setError] = useState('');
    const isInspector = useMemo(() => isAllow([Roles.ROLE_INSPECTOR]), []);
    const isAvaliator = useMemo(() => isAllow([Roles.ROLE_INSURANCE_ANALYST, Roles.ROLE_INSURANCE_MANAGER, Roles.ROLE_HOTLIST_MANAGER]) || isInspector, []);
    const fetchData = useCallback(() => loadFeedback(plate, id), [plate, id]);
    const toggle = useCallback(() => setOpen(v => !v), []);
    const submit = useCallback(async feedback => {
        const params = { ...feedback, hits, plate };
        setHasError(false);
        setHasErrorServer(false);
        try {
            if (!isInspector) {
                await submitFeedback(params, onClose);
            } else {
                await submitInspection(params, onClose);
            }
            setOpen(false);
        } catch(e) {
            if(!e.response) {
                setError(e.message);
                setHasError(true);
            } else {
                setError("Ocorreu um problema ao comunicar com o servidor por favor tente novamente mais tarde.");
                setHasErrorServer(true);
            }
        }
    }, [plate, hits]);

    useEffect(() => { trackingId && setBatchUUid(trackingId)}, [trackingId]);

    return (
        <When condition={isAvaliator}>
            { children(toggle) }
            <ConfirmModal size="lg" isOpen={isOpen} title="Avaliação" confirmLabel="Salvar" cancelLabel="Cancelar" toggle={toggle} confirm={() => submit(context)}>
                <Form>
                    <Alert color="danger" isOpen={hasError || hasErrorServer}>
                        {error}
                    </Alert>
                    <FormGroup row>
                        <Label md="3">
                            <b>Placa</b>
                        </Label>
                        <Col>{plate}</Col>
                    </FormGroup>
                    <Loader promiseFn={fetchData}>
                        <Resolve>
                            { ({ plate:_, hits:__, ...feedback }) => <FeedbackForm id={feedback?.id} feedback={feedback} hasError={hasError} isInspector={isInspector}/> }
                        </Resolve>
                    </Loader>
                </Form>
            </ConfirmModal>
        </When>
    )
}