import qs from "qs";
import Api from "./Api";

export default class VehiclePartApi extends Api {
  constructor() {
    super(process.env.REACT_APP_VEHICLE_PART_ENDPOINT);
  }
  async getAllVehicleParts(companyUuid){
    const { content: vehicleParts, totalPages } = await this.fetch(qs.stringify({ companyUuid }));
    for (let page = 1; page < totalPages; page++) {
        const { content } = await this.fetch(qs.stringify({ companyUuid, page }));
        vehicleParts.push(...content);
    }
    return vehicleParts;
  }
}
