// import { isRange, toNumber } from "lodash";

class Address {
  latitude;
  longitude;
  label;
  address;
  number;
  locality;
  city;
  postalCode;
  stateInitials;
  state;
  countryInitials;
  country;

  constructor(
    latitude = "",
    longitude = "",
    label = "",
    address = "",
    number = "",
    locality = "",
    city = "",
    postalCode = "",
    stateInitials = "",
    state = "",
    countryInitials = "",
    country = ""
  ) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.label = label;
    this.address = address;
    this.number = number;
    this.locality = locality;
    this.city = city;
    this.postalCode = postalCode;
    this.stateInitials = stateInitials;
    this.state = state;
    this.countryInitials = countryInitials;
    this.country = country;
  }

  static of(address) {
    return new Address(
      address?.latitude,
      address?.longitude,
      address?.label,
      address?.address || address?.street,
      address?.number || address?.addressNumber || address?.streetNumber,
      address?.locality,
      address?.city,
      address?.postalCode,
      address?.stateInitials,
      address?.state,
      address?.countryInitials,
      address?.country
    );
  }

  isValid() {
    return this.isAddressValid();// && this.isGeoValid();
  }
  isAddressValid() {
    return (
      !!this.postalCode &&
      !!this.state &&
      !!this.city &&
      !!this.locality &&
      !!this.address
    );
  }
  isGeoValid() {
    const { latitude, longitude } = this;
    return isNaN(latitude) || isNaN(longitude);
  }

  addressNotBlank() {
    return (
      !!this.address || !!this.locality || !!this.city || (!!this.stateInitials|| !!this.state)
    );
  }

  formatAddress(blank=false) {
    if (this.addressNotBlank()) {
      return `${this.address || ""}${!!this.address ? ", " : ""}${this.number ||
        ""}${!!this.address && !this.number ? "S/N" : ""}` +
        `${!!this.address ? " - " : ""}${this.locality || ""}${
          !!this.locality ? ", " : ""
        }${this.city || ""}` +
        ` - ${this.stateInitials || this.state || ""}`;
    }
    if (!blank) {
      return "Não foi possivel recuperar a localização";
    }
    return '';
  }
}

export default Address;
