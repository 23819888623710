import QueryString from "qs";
import Api, { put } from "./Api";

export default class ProviderApi extends Api {
  constructor() {
    super(process.env.REACT_APP_PROVIDER_ENDPOINT);
  }

  updateProvider(provider){
    return put(this.baseUrl, provider);
  }

  getProviders(codProvider, providerName, companyUuid, page, size, sort, order) {
    const queryParams = QueryString.stringify({ codProvider, providerName, companyUuid, page, size, sort, order })
    return this.fetch(`${queryParams}`);
  }

  async getAllProviders(codProvider, providerName, companyUuid, sort, order) {
    let page = 0;
    const size = 50;
    let fullContent = [];
    let totalPages = 1;
    while (page < totalPages) {
      const { content, totalPages: totalPgs } = await this.getProviders(codProvider, providerName, companyUuid, page, size, sort, order);
      totalPages = totalPgs;
      fullContent = fullContent.concat(content);
      page++;
    }
    return fullContent;
  }
}