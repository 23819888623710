const enumValue = description => Object.freeze({
    toString: () => description
});

export const PLATE = enumValue('PLATE');
export const HIT = enumValue('HIT');
export const UTILITY = enumValue('UTILITY');
export const VALUE = enumValue('VALUE');
export const DATE_CREATION = enumValue('DATE_CREATION');
export const DATE_LAST_UPDATE = enumValue('DATE_LAST_UPDATE');
export const COMPANY = enumValue('COMPANY');
export const NO_SORT = enumValue('');