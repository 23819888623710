import Api, { get } from "./Api";

export default class FeedbackParameterApi extends Api {
  constructor() {
    super("/feedback-parameter");
  }


  getByCompany(companyUuid) {
    return get([this.baseUrl, companyUuid].join("/"));
  }
}