import Api, { del, get, patch, post, download } from "./Api";
import { CancelToken } from 'axios';
import FormData from "form-data";
import qs from "qs";

class UserApi extends Api {
  constructor() {
    super((process.env.REACT_APP_AUTH_ENDPOINT || "") + "/user");
    this.userBatchEndpoint = process.env.REACT_APP_ENDPOINT + "/user-batch";
  }

  getUsers = (
    page,
    size,
    status,
    filter,
    company,
    roles,
    order,
    sort
  ) => 
    this.fetch(
      qs.stringify({
        page,
        company,
        order,
        sort,
        size,
        roles,
        status,
        fullName: filter,
        username: filter,
      }, { arrayFormat: 'comma' })
    );

  enable = (user) => patch(`${this.baseUrl}/${user}/enable`);

  disable = (user) => patch(`${this.baseUrl}/${user}/disable`);

  invite = (user) => post(`${this.baseUrl}/${user}/invite`);

  forgot = (user) => del(`${this.baseUrl}/${user}/password`);

  activate = (user, password, token) =>
    post(`${this.baseUrl}/${user}/activate`, password, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "text/plain",
      },
    });

  changePassword = (user, oldPassword, newPassword) =>
    patch(`${this.baseUrl}/${user}/password`, { oldPassword, newPassword });

  subscribeBatch = (file, companyUuid, departmentUuid, roles, tokenTimeExpiration, onUploadProgress) => {
    let form = new FormData();
    form.append("csvFile", new Blob([file], { type: "text/csv" }), file.name);
    const userBatch = { companyUuid, userDepartmentUuid: departmentUuid, userTokenTimeExpiration: tokenTimeExpiration, userRoles: roles };
    form.append("userBatch", new Blob([JSON.stringify(userBatch)], { type: "application/json" }));
    const source = CancelToken.source();
    const request = post(this.userBatchEndpoint, form, {
      cancelToken: source.token,
      onUploadProgress,
    });
    return [
      {
        cancel: () => source.cancel("Operation canceled by the user."),
        progress: 0,
        request,
        file,
      },
    ];
  }

  disableBatch = (file, companyUuid, onUploadProgress) => {
    let form = new FormData();
    form.append("csvFile", new Blob([file], { type: "text/csv" }), file.name);
    form.append("companyUuid", new Blob([companyUuid], { type: "text/plain" }));
    const source = CancelToken.source();
    const request = del(this.userBatchEndpoint, {
      data: form,
      cancelToken: source.token,
      onUploadProgress,
    });
    return [
      {
        cancel: () => source.cancel("Operation canceled by the user."),
        progress: 0,
        request,
        file,
      },
    ];
  }

  async fetchUploads(queryString, config) {
    return get(`${this.userBatchEndpoint}?${queryString}`, config);
  }

  downloadSuccessesFile = async (id, filename) => {
    await download(
      this.userBatchEndpoint + `/${id}/export`,
      filename + ".csv",
      "text/csv"
    );
  };

  downloadSourceFile = async (id, filename) => {
    await download(
      this.userBatchEndpoint + `/${id}/source-file`,
      filename + ".csv",
      "text/csv"
    );
  };

  session = user => get(`${this.baseUrl}/${user}/session`)

}

export default UserApi