import React from "react";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { FaBars } from "react-icons/fa";

const DragHandle = sortableHandle(() => <FaBars style={{cursor: 'grab'}}/>);

const SortableItem = SortableElement(({ value }) => (
    <ListGroupItem>
        <Row>
            <Col md="1" className="text-center" >
                <DragHandle/>
            </Col>
            <Col>
                {value}
            </Col>
        </Row>
    </ListGroupItem>
));

const SortableList = SortableContainer(({ items }) =>
    <ListGroup>
        { items.map(({key, value}, index) => <SortableItem key={`item-${key}`} index={index} value={value} />) }
    </ListGroup>
);

export default ({ items, onSortEnd }) => {
    return <SortableList { ...{ items, onSortEnd } } useDragHandle/>
}