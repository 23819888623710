import React, { useCallback, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Input, FormGroup, FormText } from "reactstrap";
import { Else, If, Then, When } from "react-if";

const style = {
  width: "20px",
  cursor: "pointer",
  right: "10px",
  position: "absolute",
  top: "calc(50% - 12px)",
};

const PasswordToggle = ({ password, onChangePassword }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isCapsActive, setCapsActive] = useState(false);
  const toggleShowPassword = useCallback(() => setShowPassword(prevState => !prevState), []);
  const toggleCaps = useCallback(event => setCapsActive(event.getModifierState('CapsLock')), []);
  return (
    <>
      <FormGroup autoComplete="off">
        <Input autoComplete="off" className={showPassword ? "login-input" : "passwordInput"} type="text" placeholder="Senha" value={password} onChange={onChangePassword} onMouseDown={toggleCaps} onKeyUp={toggleCaps}/>
        <div onClick={toggleShowPassword} style={style}>
          <If condition={showPassword}>
            <Then>
              <FaEyeSlash />
            </Then>
            <Else>
              <FaEye />
            </Else>
          </If>
        </div>
      </FormGroup>
      <When condition={isCapsActive}>
        <FormText color="danger">Caps Lock ativo</FormText>
      </When>
    </>
  );
};

export default PasswordToggle;
