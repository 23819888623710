import React from "react";
import { ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Modal from "./Modal";

export default ({ isOpen, toggle, msg, success=false }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}> {success ? "Sucesso!": "Erro!"}</ModalHeader>
    <ModalBody>{msg}</ModalBody>
    <ModalFooter>
      <Button color={success ? "primary": "secondary"} onClick={toggle}>
        Fechar
      </Button>
    </ModalFooter>
  </Modal>
);
