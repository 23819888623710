import React, { useState } from 'react'
import { Button } from 'reactstrap';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

export default ({ buttonLabel, children, sizeButton, colorBotton, style, block, direction='right' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = () => setIsOpen(prevState => !prevState);

    return (
        <>
            <Button style={style} size={sizeButton} block={block} color={colorBotton} onClick={toggleDrawer}>{ buttonLabel }</Button>
            <Drawer
                size={450}
                open={isOpen}
                onClose={toggleDrawer}
                direction={direction}
            >
                { children({ onClose: toggleDrawer }) }
            </Drawer>
        </>
    )
};
