import React from "react";
import { FormGroup, Input, Label, Col, UncontrolledTooltip, Row } from "reactstrap";
import classnames from "classnames";
import { When } from "react-if";
import styles from "./style.module.sass";

const style = {
    height: 44,
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    marginTop: 24,
    background: 'linear-gradient(-37deg, transparent 49%, red 50%, transparent 51%), linear-gradient(37deg, transparent 49%, red 50%, transparent 51%)'
}

export default ({ color, className }) => {
    const { COLORS } = process.env;
    const value = (COLORS.colors[color] || '').trim() || (color || '').toUpperCase();
    let background = '';
    for (const key in COLORS.backgroundColor) {
        if (COLORS.backgroundColor.hasOwnProperty(key)) {
            const colors = COLORS.backgroundColor[key];
            if (colors.includes(value)) {
                background = key;
                break;
            }
        }
    }
    return (
        <Row className={classnames(["m-0 px-0", className])}>
            <Col md="10" className="pl-0 mx-0">
                <FormGroup className="mb-1">
                    <Label className="mb-0 pb-0">Cor</Label>
                    <Input className={styles['color-input-info']} value={value} readOnly />
                </FormGroup>
            </Col>
            <Col id="color-input-block" className="pr-0 mx-0" style={{ ...style, background: background|| style.background }}/>
            <When condition={!background && !!color}>
                <UncontrolledTooltip placement="right" target="color-input-block">
                    Cor não conhecida. Por favor, entrar em contato com os administradores.
                </UncontrolledTooltip>
            </When>
        </Row >
    );
}