import Api, { get, post, save } from "./Api";

class ReportTemplateApi extends Api {
  constructor() {
    super("/reportTemplate");
  }

  processReport = (id) => post(`${this.baseUrl}/${id}`);

  //TODO verificar onde ficaria melhor esse endpoint
  getStatus = (id) => get(`/report/${id}/status`);

  runPreview = (sql) =>
    post(`${this.baseUrl}/preview/`, sql, {
      headers: {
        "Content-Type": "text/plain",
      },
    });

  downloadPreview = async (previewSql) => {
    const response = await post(
      `${this.baseUrl}/preview/download`,
      previewSql,
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    save(
      response,
      "preview.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  };
}

export default ReportTemplateApi