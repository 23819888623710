import Api, { download, put } from './Api';
import { CancelToken } from 'axios';

export default class FeedbackBatchApi extends Api {
  constructor() {
    super(process.env.REACT_APP_FEEDBACK_IMPORT_ENDPOINT);
  }

  upload(file, onUploadProgress, companyUuid) {
    const source = CancelToken.source();
    const options = {
      cancelToken: source.token,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
    };
    const form = new FormData();
    form.append("csvFile", file);
    form.append("companyUuid", companyUuid);
    const request = put(this.baseUrl, form, options);
    return [
      {
        cancel: () => source.cancel("Operation canceled by the user."),
        progress: 0,
        request,
        file,
      },
    ];
  }

  downloadSuccessesFile = async (id, filename, cancel) => {
    await download(
        this.baseUrl + `/${id}/export`,
        filename + ".csv",
        "text/csv",
        cancel
    );
};

downloadSourceFile = async (id, filename, cancel) => {
    await download(
        this.baseUrl + `/${id}/source-file`,
        filename + ".csv",
        "text/csv",
        cancel
    );
};
}