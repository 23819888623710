import React, { useState } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import classnames from "classnames";
import { useMobile } from "hooks";
import { When } from "react-if";
import styles from "./style.module.sass";

const Prev = (clickHandler, hasPrev) => (
  <When condition={hasPrev}>
    <FaArrowCircleLeft onDoubleClick={e => e.stopPropagation()} onClick={clickHandler} className={classnames([styles.overlay, styles.previous])} />
  </When>
)
const Next = (clickHandler, hasNext) => (
  <When condition={hasNext}>
    <FaArrowCircleRight onDoubleClick={e => e.stopPropagation()} onClick={clickHandler} className={classnames([styles.overlay, styles.next])} />
  </When>
)

export default ({ children, infiniteLoop , onNext, onPrevious }) => {
  const isMobile = useMobile();
  const [currentSlide, setCurrentSlide] = useState(0);
  const next = () => {
    onNext && onNext(currentSlide + 1);
    setCurrentSlide(currentSlide + 1);
  }
  const prev = () => {
    onPrevious && onPrevious(currentSlide - 1);
    setCurrentSlide(currentSlide - 1);
  }
  const updateCurrentSlide = (index) => {
    if (currentSlide < index) {
      next();
    } else if (currentSlide > index) {
      prev();
    }
  };

  return (
    <Carousel
      statusFormatter={(current, total) => `${current} de ${total}`}
      emulateTouch={isMobile}
      showStatus={true}
      showThumbs={false}
      showArrows={!isMobile}
      showIndicators={false}
      infiniteLoop={infiniteLoop}
      selectedItem={currentSlide}
      onChange={updateCurrentSlide}
      renderArrowPrev={Prev}
      renderArrowNext={Next}
    >
      {children}
    </Carousel>
  );
};
