const enumValue = description => Object.freeze({
    toString: () => description
});

export const READY = enumValue('READY');
export const PARTIAL = enumValue('PARTIAL');
export const COMPLETED = enumValue('COMPLETED');
export const ERROR = enumValue('ERROR');

export const values = () => [
    READY,
    PARTIAL,
    COMPLETED
];

export const dossierStatusValues = () => [
    READY,
    PARTIAL,
    COMPLETED
]