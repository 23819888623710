import React, { useCallback, useEffect } from "react";
import IntlCurrencyInput from "react-intl-currency-input";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { If, Then, Else, Unless, When } from "react-if";
import { FaPlus } from "react-icons/fa";
import classnames from "classnames";
import { useFeedbackModalContext } from "./Context";
import { inquiryValues } from "./model";
import { hypothesisApi, feedbackParameterApi } from "api";
import Loader, { Resolve } from "components/loader";
import { Select, Switch } from "components";
import { getCurrentUser } from "utils";


async function getHypotheses(company) {
    const { content } = await hypothesisApi.getAllHypotheses(company);
    return content.map(hypothesis => ({ label: hypothesis.name, value: hypothesis.id }));
}

async function fetchInquiryValue(company) {
    try {
        return (await feedbackParameterApi.getByCompany(company)).inquiryValue || 0
    } catch {
        return 0
    }
}

async function load() {
    const { Company } = getCurrentUser();
    return Promise.all([
        getHypotheses(Company),
        fetchInquiryValue(Company)
    ]);
}

function getStyles(validate) {
    return {
        control: function(s) {
            return{
              ...s,
              border: validate ? "red 1px solid" : "",
            }
        }
    }
};

const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

function InnerForm({ hasError, hypotheses, defaultInquiryValue }) {
    const [{ value, feedbackAnalysisDTO: { useful, hypothesis, avoidedInquiry, releasedClaim, refusedClaim, notDefined }}, { setHypothesis, setUseful, onChangeInquiry, setNotDefined, setValue, setInquiryValue }] = useFeedbackModalContext();
    const onChangeUseful = useCallback(value => setUseful(value), []);
    const onChangeValue = useCallback( (_, value) => setValue(value), []);
    useEffect(() => { setInquiryValue(defaultInquiryValue); }, [defaultInquiryValue]);
    return (
        <>
            <FormGroup row style={{ paddingTop: "2%" }}>
                <Label md="3" className="font-weight-bold">
                    Pesquisa foi útil? *
                </Label>
                <Col md="3" className={classnames([{ 'text-danger': hasError && useful === null }, 'd-flex align-items-center'])} style={style} onClick={() => onChangeUseful(true)}>
                    <input type="radio" name="useful" value="true" className={classnames(['radioCheckbox', { 'border border-danger':  hasError && useful === null}])} checked={useful}/>
                    &nbsp;Sim
                </Col>
                <Col md="3" className={classnames([{ 'text-danger': hasError && useful === null }, 'd-flex align-items-center'])}  style={style} onClick={() => onChangeUseful(false)}>
                    <input type="radio" name="useful" value="false" className={classnames(['radioCheckbox', { 'border border-danger':  hasError && useful === null}])} checked={useful === false}/>
                    &nbsp;Não
                </Col>
            </FormGroup>
            <When condition={useful}>
                <FormGroup row>
                    <Label md="3" for="hypothesis" className="font-weight-bold">
                        Hipótese *
                    </Label>
                    <Col md="9">
                        <Select id="hypothesis" options={hypotheses} onChange={({ value }) => setHypothesis({ id: value })} value={hypotheses.find(({ value }) => value === hypothesis?.id)} placeholder="Selecione uma Hipótese" styles={getStyles(hasError && useful !== false && !hypothesis)}/>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Label className="font-weight-bold">Sindicância Evitada?</Label>
                    <Row style={{ paddingTop: "2%" }}>
                        {
                            inquiryValues().map(({ value, label, validate }, index) => (
                                <Col xs={index === 0 ? 4: index === 2? 2: ''} className={classnames([{ 'text-danger': hasError && useful && avoidedInquiry === null }])} key={value} style={style} onClick={() => onChangeInquiry(value)}>
                                    <input type="radio" name="avoidedInquiry" className="radioCheckbox" checked={validate(avoidedInquiry, releasedClaim, refusedClaim)} style={hasError && useful && avoidedInquiry === null? { border: "red 1px solid" }: {}}/>
                                    &nbsp;{label}
                                </Col>
                            ))
                        }
                    </Row>
                </FormGroup>
                <If condition={avoidedInquiry}>
                    <Then>
                        <FormGroup className={classnames([{ 'text-muted': releasedClaim }])}>
                            <Row>
                                <Label xs="6" for="amountSearch"className="font-weight-bold" >
                                Valor Economizado
                                </Label>
                                <Unless condition={releasedClaim}>
                                    <Col>
                                        <Switch id="notDefined" label="Valor a definir" onChange={value => setNotDefined(value)} checked={notDefined} floatRight />
                                    </Col>
                                </Unless>
                            </Row>
                            <Row>
                            <Col xs="1" className={classnames(['my-4', 'text-pimary',{ 'd-none': releasedClaim }])}>
                                <FaPlus />
                            </Col>
                            <Col>
                                <Row className={classnames(['mb-1', { 'd-none': releasedClaim }])}>
                                    <Col>
                                        <IntlCurrencyInput currency="BRL" config={currencyConfig} onChange={onChangeValue} max={9999999.99} value={value} className="form-control" disabled={notDefined}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <IntlCurrencyInput currency="BRL" config={currencyConfig} max={9999999.99} value={defaultInquiryValue} className="form-control" disabled/>
                                    </Col>
                                </Row>
                                <Row className={classnames([{ 'd-none': releasedClaim }])}>
                                    <Col>
                                        <Label for="amountSearch"className="font-weight-bold" >
                                            Valor Economizado Total&nbsp;{currencyFormatter.format(defaultInquiryValue + value)}
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            </Row>
                        </FormGroup>
                    </Then>
                    <Else>
                        <Row>
                            <Label xs="6" for="amountSearch"className="font-weight-bold" >
                                Qual o potencial Valor Economizado?
                            </Label>
                            <Col>
                                <Switch id="notDefined" label="Valor a definir" onChange={value => setNotDefined(value)} checked={notDefined} floatRight />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <IntlCurrencyInput currency="BRL" config={currencyConfig} onChange={onChangeValue} max={9999999.99} value={value} className="form-control" disabled={notDefined}/>
                            </Col>
                        </Row>
                    </Else>
                </If>
            </When>
        </>
    );
}

const style = { cursor: 'pointer' };

export default function ({ hasError }) {
    return (
        <Loader promiseFn={load}>
            <Resolve>
                { ([hypotheses, defaultInquiryValue]) => <InnerForm hasError={hasError} hypotheses={hypotheses} defaultInquiryValue={defaultInquiryValue} />}
            </Resolve>
        </Loader>
    )
}