import React, { Component } from "react";
import Pagination from "react-ultimate-pagination-bootstrap-4";
import { Row, Col, Input } from "reactstrap";
import { inject, observer } from "mobx-react";
import PageSearch from "./PageSearch";
import PropTypes from "prop-types";
import "./style.sass";

const language = "pt-BR";

@inject("UtilsStore")
@observer
class ResponsivePagination extends Component {
    state = { currentPage: 1 };

    constructor(props) {
        super();
        const { currentPage } = props;
        if (currentPage && currentPage !== this.state.currentPage) {
            this.state.currentPage = currentPage;
        }
    }

    componentDidUpdate() {
        const { currentPage } = this.props;
        if (currentPage && currentPage !== this.state.currentPage) {
            this.setState({ currentPage });
        }
    }

    static propTypes = {
        pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
        onChangePageSize: PropTypes.func.isRequired,
        totalPages: PropTypes.number.isRequired,
        children: PropTypes.node.isRequired,
        onChange: PropTypes.func.isRequired,
        total: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
    };

    getPage = (page) => (this.props.totalPages < page || page < 1 ? 1 : page);

    onChange = (page) => {
        page = this.getPage(page);
        this.props.onChange(page);
        this.setState({ currentPage: page });
    };

    render() {
        const {
            totalPages,
            size,
            onChangePageSize,
            pageSizes,
            children,
            total,
            UtilsStore: { isMobile },
        } = this.props;
        const { currentPage } = this.state;
        const page = this.getPage(currentPage);
        let recordsOnPage = page * size;
        let firstRecordOnPage = (page - 1) * size + 1;

        if (totalPages < currentPage) {
            this.setState({ currentPage: 1 });
        }

        if (total < recordsOnPage) {
            recordsOnPage = total;
        }
        if (total === 0) {
            firstRecordOnPage = 0;
        }
        const PageRow = () => (
            <Row>
                <Col md xs="12">
                    <Pagination
                        totalPages={totalPages}
                        currentPage={page}
                        siblingPagesRange={!isMobile ? 5 : 1}
                        onChange={this.onChange}
                        hidePreviousAndNextPageLinks={isMobile}
                        hideFirstAndLastPageLinks={isMobile}
                    />
                    Exibindo {firstRecordOnPage.toLocaleString(language)} à{" "}
                    {recordsOnPage.toLocaleString(language)} de{" "}
                    {total.toLocaleString(language)} Registros
                </Col>
                <Col md="4" xs="6">
                    <PageSearch value={page} onBlur={this.onChange} />
                </Col>
                <Col md="2" xs="6">
                    <Input type="select" value={size} onChange={onChangePageSize}>
                        {pageSizes.map((pageSize) => (
                            <option value={pageSize} key={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Input>
                </Col>
            </Row>
        );
        return (
            <>
                <PageRow />
                {children}
                <PageRow />
            </>
        );
    }
}

export default ResponsivePagination;
