import React from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { When } from 'react-if';
import Modal from './Modal';

export default ({
  children: msg,
  title = "Atenção!",
  toggle,
  isOpen,
  confirm,
  size,
  className,
  confirmLabel = "Sim",
  cancelLabel = "Não",
  hideCancel = false,
  confirmDisabled = false,
  hideConfirm = false,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} size={size} className={className}>
    <ModalHeader toggle={toggle}>{title}</ModalHeader>
    <ModalBody>{msg}</ModalBody>
    <ModalFooter>
      <When condition={!hideConfirm}>
        <Button onClick={confirm} color="primary" disabled={confirmDisabled}>
          {confirmLabel}
        </Button>
      </When>
      <When condition={!hideCancel}>
        <Button onClick={toggle}> {cancelLabel} </Button>
      </When>
    </ModalFooter>
  </Modal>
);