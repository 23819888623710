import React, { useState } from 'react';
import {
    Col, Form, FormGroup, Label,
    Input, Button, Row, TabPane,
    Alert, FormText
} from 'reactstrap';
import { userApi } from 'api';

export default ({ user, loadCallback, close }) => {
    const [ oldPassword, setOldPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isInvalid, setIsInvalid ] = useState(false);
    const clean = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }
    const onClick = async () => {
        try {
            isOpen && setIsOpen(false);
            isInvalid && setIsInvalid(false);
            loadCallback(true);
            await userApi.changePassword(user, oldPassword, newPassword);
            setIsOpen(true);
            clean();
        } catch (error) {
            setIsInvalid(true);
        } finally {
            loadCallback(false);
        }
    };
    const wrapperClose = () => {
        close();
        clean();
    }
    return (
        <TabPane tabId="2">
            <Form>
                <Alert color="success" { ...{ isOpen } }  toggle={() => setIsOpen(false)}>
                    Senha alterada com sucesso
                </Alert>
                <Alert color="danger" isOpen={isInvalid} toggle={() => setIsInvalid(false)}>
                    Senha inválida
                </Alert>
                <FormGroup row>
                    <Label for="old" md="3">Senha Atual</Label>
                    <Col md>
                        <Input type="password" id="old" placeholder="" value={oldPassword} onChange={({target: { value } }) => setOldPassword(value)} autoComplete="new-password"/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="new" md="3">Nova Senha</Label>
                    <Col md>
                        <Input type="password" id="new" placeholder="" value={newPassword} onChange={({target: { value } }) => setNewPassword(value)}
                            invalid={!!newPassword && (newPassword.length < 8 || newPassword.length > 16)} valid={newPassword.length >= 8 && newPassword.length <= 16}/>
                            <FormText color={!newPassword ? 'muted' : (newPassword.length < 8 || newPassword.length > 16) ?  'danger' : 'success' }>
                                A senha deve ter entre 8 e 16 caracteres.
                            </FormText>
                    </Col> 
                </FormGroup>
                <FormGroup row>
                    <Label for="confirm" md="3">Confirme Nova Senha</Label>
                    <Col md>
                        <Input type="password" id="confirm" placeholder="" value={confirmPassword} onChange={({target: { value } }) => setConfirmPassword(value)}
                            invalid={newPassword !== confirmPassword} valid={!!newPassword && newPassword === confirmPassword}/>
                        <FormText color={!newPassword && !confirmPassword ? 'muted' : newPassword !== confirmPassword ?  'danger' : 'success' }>
                            As senhas devem ser iguais.
                        </FormText>
                    </Col> 
                </FormGroup>
                <Row>
                    <Col>
                        <Button onClick={wrapperClose}>Fechar</Button>
                    </Col>
                    <Col>
                        <Button color="primary" onClick={onClick} className="float-right" 
                            disabled={!oldPassword || !newPassword || (newPassword.length < 8 || newPassword.length > 16) || newPassword !== confirmPassword}>
                            Confirmar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </TabPane>
    );
}