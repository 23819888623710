import React, { Component } from 'react';
import {FormGroup, Label } from 'reactstrap';
import DatePicker from 'react-date-picker';
import classnames from 'classnames';
import './dateTimePicker.sass'


export default class extends Component {

    static defaultProps = { maxDate: new Date() };

    componentDidMount() {
        let inputs = {};
        this.datePick.wrapper.firstChild.firstChild.childNodes.forEach(node => (!!node.placeholder) && (inputs[node.name] = node));
        const day = inputs['day'];
        const month = inputs['month'];
        const year = inputs['year'];
        const keyDown = 
            (validate, next) => 
                e => {
                    const { key } = e;
                    if(!!key.match(/[0-9]/)) {              
                        if(!validate(key)){
                            e.preventDefault();
                            if(!!next){
                                next.focus();
                            }
                        }
                    } else if(key.length === 1){
                        e.preventDefault();
                    }
                };
        day.onkeydown = keyDown(key => {
            const value = parseInt(day.value + key);
            return 1 <= value && value <= 31;
        }, month);
        month.onkeydown = keyDown( key => {
            const value = parseInt(month.value + key);
            return 1 <= value && value <= 12;
        }, year);
        year.onkeydown = keyDown(key => {
            const value = parseInt(year.value + key);
            return 1 <= value && value <= new Date().getFullYear();
        });
    }

    render() {
        const { datePick, onChangeDate, labelText, maxDate, minDate, required, disabled, className } = this.props;
        return (
        <FormGroup className={classnames(['date-input', { disabled, [className]: className }])}>
            <Label for="date" style={{color:'#6c757d'}} data-testid="date-picker-label">{labelText}:&nbsp;</Label>
            <DatePicker value={datePick} onChange={onChangeDate} locale="pt-BR" calendarIcon={null} showLeadingZeros={true} data-testid="date-picker"
                clearIcon={null} minDate={minDate|| undefined} ref={ref => this.datePick = ref} disabled={disabled} { ...{ maxDate, required } }
                />
        </FormGroup>
        )
    }
}