import React, { useEffect, useState } from 'react';
import {
    Col, Form, FormGroup, Label,
    Input, Button, Row, TabPane,
    Alert
} from 'reactstrap';
import { userApi } from 'api';

export default ({ fullName: name, user, roles, tokenTimeExpiration, onUpdate, loadCallback, close }) => {
    const [ fullName, setFullName ] = useState(name);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isInvalid, setIsInvalid ] = useState(false);
    const onClick = async () => {
        try {
            isOpen && setIsOpen(false);
            isInvalid && setIsInvalid(false);
            loadCallback(true);
            await userApi.update(user, {
                tokenTimeExpiration,
                fullName,
                roles
            });
            localStorage.setItem("USER", fullName);
            onUpdate();
            setIsOpen(true);
        } catch (error) {
            setIsInvalid(true);
        } finally {
            loadCallback(false);
        }
    };
    const wrapperClose = () => {
        close();
        setFullName(name);
    }

    useEffect(() => { setFullName(name) }, [name]);
    return (
        <TabPane tabId="1">
            <Form>
                <Alert color="danger" isOpen={isInvalid}  toggle={() => setIsInvalid(false)}>
                    Erro ao alterar seus dados
                </Alert>
                <Alert color="success" { ...{ isOpen } }  toggle={() => setIsOpen(false)}>
                    Dados alterados com sucesso
                </Alert>
                <FormGroup row>
                    <Label for="email" md="2">Email</Label>
                    <Col md>
                        <Input type="email" id="email" placeholder="" value={user} readOnly onChange={({target: { value } }) => setFullName(value)}/> 
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="name" md="2">Nome</Label>
                    <Col md>
                        <Input id="name" placeholder="" value={fullName} onChange={({target: { value } }) => setFullName(value)}/> 
                    </Col>
                </FormGroup>
                <Row>
                    <Col>
                        <Button onClick={wrapperClose}>Fechar</Button>
                    </Col>
                    <Col>
                        <Button color="primary" onClick={onClick} className="float-right" disabled={!fullName}>Confirmar</Button>
                    </Col>
                </Row>
            </Form>
        </TabPane>
    );
}