import React from "react";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useBreadCrumbLevels } from "./hooks";
import styles from "./style.module.sass";
import { When } from "react-if";


export default () => {
    const levels = useBreadCrumbLevels()
    const levelsClone = [...levels];
    const current = levelsClone.pop();
    const root = levelsClone.shift();
    return (
        <>
            <Row>
                <Col>
                    <Breadcrumb className={styles.breadcrumb}>
                        <When condition={root}>
                            <BreadcrumbItem className="d-flex align-items-center">
                                <Link to={root?.link} className="link" style={{ pointerEvents: root?.link !== '#'? 'auto': 'none' }}>
                                    <h3>{root?.title}</h3>
                                </Link>
                            </BreadcrumbItem>
                        </When>
                        { 
                            levelsClone.map(
                                ({ title, link }) => (
                                    <BreadcrumbItem className="d-flex align-items-center" key={`${title}-${link}`}>
                                        <Link to={link} className="d-flex align-self-end link" style={{ pointerEvents: link !== '#'? 'auto': 'none' }}>
                                            <h4>{title}</h4>
                                        </Link>
                                    </BreadcrumbItem>
                                )
                            )
                        }
                        <BreadcrumbItem className="d-flex align-items-center" active>
                            <h5 className="d-flex align-self-end">{current?.title}</h5>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
            <hr style={{ marginTop: 0 }}/>
        </>
    );
}