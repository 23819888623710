import Api, { download, get, patch, post, put } from "./Api";
import axios, { CancelToken } from "axios";

// DOCS -> https://sandbox.carbigdata.com.br/api/v1/customer-api/swagger-ui.html
class CustomerApi extends Api {
  
    constructor() {
        super(process.env.REACT_APP_CUSTOMER_ENDPOINT);
        this.customerBatchEndpoint =
            process.env.REACT_APP_CUSTOMER_BATCH_ENDPOINT;
        this.customerBFFEndpoint = process.env.REACT_APP_CUSTOMER_BFF_ENDPOINT;
    }

    searchPlateChassi(filter) {
        return get(`${this.customerBFFEndpoint}?${filter}`);
    } 

    edit(params) {
        return put(`${this.baseUrl}`, params);
    }

    fetchUploads(queryString, config) {
        return get(`${this.customerBatchEndpoint}?${queryString}`, config);
    }

    subscribeBatch = (file, company, onUploadProgress) => {
        let form = new FormData();
        form.append("csvFile", file);
        form.append("companyUuid", company);
        const source = CancelToken.source();
        const request = post(this.customerBatchEndpoint, form, {
            cancelToken: source.token,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress,
        });
        return [
            {
                cancel: () => source.cancel("Operation canceled by the user."),
                progress: 0,
                request,
                file,
            },
        ];
    };

    unsubscribeBatch = (file, company, onUploadProgress) => {
        let form = new FormData();
        form.append("csvFile", file);
        form.append("companyUuid", company);
        const source = CancelToken.source();
        const request = axios({
            method: "delete",
            url: this.customerBatchEndpoint,
            data: form,
            cancelToken: source.token,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress,
        });
        return [
            {
                cancel: () => source.cancel("Operation canceled by the user."),
                progress: 0,
                request,
                file,
            },
        ];
    };

    downloadSuccessesFile = async (id, filename, cancel) => {
        await download(
            this.customerBatchEndpoint + `/${id}/export`,
            filename + ".csv",
            "text/csv",
            cancel
        );
    };

    downloadSourceFile = async (id, filename, cancel) => {
        await download(
            this.customerBatchEndpoint + `/${id}/source-file`,
            filename + ".csv",
            "text/csv",
            cancel
        );
    };

    activate = async (id) => {
        return patch([this.baseUrl, id, "activate"].join("/"));
    };

    disable = async (id) => {
        return patch([this.baseUrl, id, "disable"].join("/"));
    };

}
export default CustomerApi;
