import { OrderEnum, SortTargetListEnum, TargetListStatus } from "model";
import { observable, action, runInAction } from "mobx";
import UtilsStore from "./UtilsStore";
import { targetListApi } from "api";
import { CancelToken } from "axios";
import { formatDate } from "utils";

class Filter {
  @observable
  name = "";
  @observable
  company = "";
  @observable
  startDate = "";
  @observable
  finishDate = "";

  @action
  reset() {
    this.name = "";
    this.company = "";
    this.startDate = "";
    this.finishDate = "";
  }
}

class TargetListStore {
  @observable
  targetLists = [];
  @observable
  size = 0;
  @observable
  pageSize = 10;
  @observable
  order = OrderEnum.DESC.toString();
  @observable
  sort = SortTargetListEnum.DATE_UPLOADED.toString();

  filter = new Filter();

  constructor(api) {
    this.api = api;
  }

  @action
  setPageSize = (value) => (this.pageSize = value);

  @action
  setOrder = (value) => (this.order = value);

  @action
  setSort = (value) => (this.sort = value);

  @action
  setName = (value) => (this.filter.name = value);

  @action
  setCompany = (value) => (this.filter.company = value);

  @action
  setStartDate = (value) => (this.filter.startDate = value);

  @action
  setFinishDate = (value) => (this.filter.finishDate = value);

  @action
  setTargetLists = (value) => (this.targetLists = value);

  @action
  setSize = (value) => (this.size = value);

  @action
  async fetch(page = 0) {
    UtilsStore.setLoading(true);
    const { order, sort, pageSize, filter } = this;
    const { name, company, startDate, finishDate } = filter;
    const url = `size=${pageSize}&page=${page}&order=${order}&sort=${sort}&startDate=${formatDate(
      startDate
    )}&fileName=${name}&finishDate=${formatDate(
      finishDate
    )}&companyUuid=${company}`;
    try {
      const { content, totalElements } = await this.api.fetch(url);
      this.setTargetLists(content);
      this.setSize(totalElements);
    } catch (err) {
      console.error(err);
    } finally {
      UtilsStore.setLoading(false);
    }
  }

  upload = (files, upload) =>
    files.map((file, index) => {
      let form = new FormData();
      form.append("parts", file);
      const source = CancelToken.source();
      const request = this.api.upload(
        form,
        {
          cancelToken: source.token,
          onUploadProgress: upload(index),
          headers: { "Content-Type": "multipart/form-data" },
        },
        file.company
      );
      return {
        cancel: () => source.cancel("Operation canceled by the user."),
        progress: 0,
        request,
        file,
      };
    });

  updateStatus = (index) => {
    const targetList = this.targetLists[index];
    this.api
      .get(targetList.id)
      .then((data) => runInAction(() => (this.targetLists[index] = data)));
  };

  @action
  processing = (index, period) => {
    let targetList = this.targetLists[index];
    switch (targetList.status) {
      case TargetListStatus.IMPORTED.toString(): {
        targetList.status = TargetListStatus.MATCHING.toString();
        const start =
          typeof period.start === "string"
            ? ""
            : period.start.toISOString().split("T")[0];
        const end =
          typeof period.end === "string"
            ? ""
            : period.end.toISOString().split("T")[0];
        return this.api.match(targetList.id, start, end).catch((err) => {
          runInAction(
            () => (targetList.status = TargetListStatus.IMPORTED.toString())
          );
          throw err;
        });
      }
      case TargetListStatus.MATCHED.toString(): {
        targetList.status = TargetListStatus.QUALIFYING.toString();
        return this.api.qualify(targetList.id).catch((err) => {
          runInAction(
            () => (targetList.status = TargetListStatus.MATCHED.toString())
          );
          throw err;
        });
      }
      case TargetListStatus.QUALIFIED.toString(): {
        targetList.status = TargetListStatus.IMPORTING.toString();
        return this.reset(index);
      }
      default:
        return new Promise((_, reject) => reject("Estado inválido"));
    }
  };

  resetFilter = () => {
    this.filter.reset("");
    this.setOrder(OrderEnum.DESC.toString());
    this.setSort(SortTargetListEnum.DATE_UPLOADED.toString());
    this.fetch();
  };

  @action
  reset = (index) => {
    const targetList = this.targetLists[index];
    const { status } = targetList;
    targetList.status = TargetListStatus.IMPORTING.toString();
    return this.api
      .reset(targetList.id)
      .then(() =>
        runInAction(
          () => (targetList.status = TargetListStatus.IMPORTED.toString())
        )
      )
      .catch((err) => {
        runInAction(() => (targetList.status = status));
        throw err;
      });
  };

  delete = async (index) => {
    const targetList = this.targetLists[index];
    await this.api.delete(targetList.id);
    this.fetch();
  };
}

const store = new TargetListStore(targetListApi);
export default store;
