import React from "react";
import { FaTrash } from "react-icons/fa";
import { Button } from "reactstrap";

export default ({ onClean, className, size, disabled, block }) => {
    return (
        <Button className={className} size={size} onClick={onClean} disabled={disabled} block={block}>
            <FaTrash/>&nbsp;Limpar
        </Button>
    )
}