import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Rect, Image, Transformer } from "react-konva";
import { FaTrash, FaSave } from "react-icons/fa";
import classnames from "classnames";
import { When } from "react-if";
import { calcWidth } from "utils";
import styles from "./style.module.sass";

const URLImage = ({ src, width, height }) => {
  const [image, setImage] = useState();
  useEffect(() => {
    if (src) {
      const img = document.createElement("img");
      img.src = src;
      setImage(img);
    }
  }, [src]);
  if (!image) {
    return null;
  }
  return <Image image={image} width={width <= 1600? width: 1600} height={height}/>;
};

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange, onDrag }) => {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      const { current: tr } = trRef;
      tr.nodes([shapeRef.current]);
      tr.getLayer().batchDraw();
      tr.rotateEnabled(false);
      tr.forceUpdate();
    }
  }, [isSelected]);

  return (
    <>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable
        {...shapeProps}
        onDragEnd={onDrag}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      <When condition={isSelected}>
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 100 || newBox.height < 50) {
              return oldBox;
            }
            return newBox;
          }}
        />
      </When>
    </>
  );
};

const into = ({ x: x1, y: y1, height, width }, x, y) => {
  const x2 = x1 + width,
    y2 = y1 + height;
  return x > x1 - 5 && x < x2 + 5 && y > y1 - 5 && y < y2 + 5;
};

export default ({ src, onSave, areas }) => {
  const [annotations, setAnnotations] = useState(areas);
  const [newAnnotation, setNewAnnotation] = useState([]);
  const [selected, setSelected] = useState(-1);
  const height = 600;

  const handleMouseDown = (event) => {
    if (newAnnotation.length === 0) {
      const { x, y } = event.target.getStage().getPointerPosition();
      if (annotations.every((el) => !into(el, x, y))) {
        if (selected > -1) {
          setSelected(-1);
          return;
        }
        setNewAnnotation([{ x, y, width: 0, height: 0, key: "0" }]);
      }
    }
  };

  const handleMouseUp = (event) => {
    if (newAnnotation.length === 1) {
      const { x, y } = newAnnotation[0];
      const { x: sx, y: sy } = event.target.getStage().getPointerPosition();
      const width = sx - x;
      const height = sy - y;
      const annotationToAdd = {
        key: annotations[annotations.length - 1]?.key + 1 || 1,
        x,
        y,
        width,
        height,
      };
      if (width >= 100 && height >= 50) {
        setAnnotations([...annotations, annotationToAdd]);
        setSelected(annotations.length - 1);
      }
      setNewAnnotation([]);
    }
  };

  const handleMouseMove = (event) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewAnnotation([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: "0",
        },
      ]);
    }
  };

  const onChange = (shape, i) => {
    const an = [...annotations];
    an[i] = shape;
    setAnnotations(an);
  };

  const onDelete = () => {
    if (selected > -1) {
      let an = [...annotations];
      an.splice(selected, 1);
      setAnnotations(an);
      setSelected(-1);
    }
  };

  const onKeyPress = ({ key }) => key === "Delete" && onDelete();

  useEffect(() => {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;";
    window.addEventListener("keydown", onKeyPress);
    return () => {
      document.getElementsByTagName("body")[0].style = "";
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [selected]);

  const annotationsToDraw = [...annotations, ...newAnnotation];
  const width = calcWidth();
  return (
    <>
      <div className={styles.toolbar}>
        <FaSave onClick={() => onSave && onSave(annotationsToDraw)} />
        <span className={classnames({ [styles.disabled]: selected === -1 })}>
          <FaTrash onClick={onDelete} />
        </span>
      </div>
      <Stage
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        width={width}
        height={height}
        style={{ maxWidth: '1600px', margin: 'auto'}}
        >
        <Layer>
          <URLImage src={src} width={width} height={height} />
          {annotationsToDraw.map((rect, i) => {
            return (
              <Rectangle
                key={rect.key}
                shapeProps={{
                  ...rect,
                  fill: "rgba(0, 0, 0, 0.6)",
                  stroke: "black",
                }}
                isSelected={selected === i}
                onChange={(shape) => onChange(shape, i)}
                onSelect={() => setSelected(i)}
                onDrag={({ target }) => {
                  const an = [...annotations];
                  an[i] = {
                    ...an[i],
                    x: target.x(),
                    y: target.y(),
                  };
                  setAnnotations(an);
                }}
              />
            );
          })}
        </Layer>
      </Stage>
    </>
  );
};
