import React from "react";
import InputMask from "react-input-mask";
import { Input } from "reactstrap";

export default ({ id, value, onChange }) => (
    <InputMask
        id={id}
        mask="99.999.999/9999-99"
        value={value}
        alwaysShowMask={false}
        maskChar=""
        placeholder="CNPJ"
        onChange={({ target: { value } }) => onChange(value.replace(/[./-]/g, ''))}>
        { inputProps => <Input type="tel" {...inputProps}/> }
    </InputMask>
)