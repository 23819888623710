import React, { createContext, useContext } from "react";
import classnames from "classnames";
import Switch from "../switch";
import styles from "./styles.module.sass";

const ControlContext = createContext();

export function useControlMap(controlName){
    return useContext(ControlContext)[controlName];
}

export const Control = ({ children }) => (
    <span>
        { children }
    </span>
)

export const HeartMapControl = () => {
    const controls = useControlMap(HeartMapControl.name);
    return (
        <Control>
            <Switch id="heatmap" label="Mapa de calor" defaultChecked={controls.defaultValue} onChange={controls.onChange} />
        </Control>
    )
}
export const DevicesMapControl = () => {
    const controls = useControlMap(DevicesMapControl.name);
    return (
        <Control>
            <Switch id="cameras" label="Esconder Câmeras" defaultChecked={controls.defaultValue} onChange={controls.onChange} />
        </Control>
    )
}
export default ({ controlComponents=[], controls={} }) => {
    return (
        <ControlContext.Provider value={controls}>
            <span className={classnames([styles.overlay, 'd-flex', 'flex-row-reverse'])}>
                { controlComponents.map(Component => <Component key={Component.name}/>) }
            </span>
        </ControlContext.Provider>
    )
}
