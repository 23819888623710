import qs from 'qs';
import Api, {post, patch} from './Api';

export default class AnalysisApi extends Api {
  constructor() {
    super(process.env.REACT_APP_HOTLIST_ANALYSIS_ENDPOINT);
  }

  start(type="STREAM", domainType="SEARCH", groupId='') {
    const filter = qs.stringify({ type, domainType, groupId });
    return post(`${this.baseUrl}?${filter}`);
  }

  approve = (id, spotlight, captureId) =>
    post(`${this.baseUrl}/${id}/report`, {
      analysisId: id,
      feedbacks: [{captureId, tags: spotlight ? [spotlight.id] : [], status: "APPROVED"}],
    });
  
  reportBatch = (analysisId, feedbacks) => 
    post(`${this.baseUrl}/${analysisId}/report`, {
      analysisId,
      feedbacks,
    });

    reportPair = (analysisId, status, captures) => 
      post(`${this.baseUrl}/${analysisId}/pair-report`, {
        analysisId,
        status,
        captures
      })
  

  reprove = (id, spotlight, captureId) =>
    post(`${this.baseUrl}/${id}/report`, {
      analysisId: id,
      feedbacks:[{captureId, tags: spotlight ? [spotlight.id] : [], status: "REPROVED"}],
    });

  suspend(id) {
    patch(`${this.baseUrl}/${id}/suspend`);
  }
}