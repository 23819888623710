import React from "react";
import classnames from "classnames";
import Collapse from "../Collapse";
import styles from "./styles.module.sass";

export default () => (
    <div className={classnames([styles.overlay, styles.legends, 'd-flex'])}>
        <span style={{ height: 'fit-content' }}>
        <Collapse label="Legenda" showCollapseIcon>
            <div>
                <span className={styles.color} />&nbsp;Camera com Capturas
            </div>
            <div>
                <span className={classnames([styles.color, styles.selected])} />&nbsp;Camera Selecionada
            </div>
            <div>
                <span className={classnames([styles.color, styles.top])} />&nbsp;Camera com Mais Capturas
            </div>
            <div className="text-nowrap">
                <span className={classnames([styles.color, styles.spotlight])} />&nbsp;Camera com Atipicidade em Captura
            </div>
            <div>
                <span className={classnames([styles.color, styles.frontier])} />&nbsp;Camera de fronteira
            </div>
            <div>
                <span className={classnames([styles.color, styles.device])} />&nbsp;Camera sem Capturas
            </div>
        </Collapse>
        </span>
    </div>
)