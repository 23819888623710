import Api from "./Api";

class SearchParameterApi extends Api {
  constructor() {
    super("/search-parameter");
  }

  getByCompanyUuid = async (uuid) => {
    return this.get(`${uuid}`);
  }
}

export default SearchParameterApi