import React, { useEffect, useState } from "react";
import { DirectionsRenderer } from "@react-google-maps/api";
import { When } from "react-if";

export default ({ origin, destination }) => {
    const [directions, setDirections] = useState(null);
    useEffect(() => {
        new window.google.maps.DirectionsService().route(
            {
                origin,
                destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            },
        );
    }, [origin, destination]);

    return (
        <When condition={directions}>
            <DirectionsRenderer options={{ directions }} />;
        </When>
    )
};