import Api, { post } from "./Api";

class AuthorizerApi extends Api {
  authenticate = (username, password) =>
    post((process.env.REACT_APP_AUTH_ENDPOINT || "") + "/authenticate", {
      deviceId: "000000000000",
      username,
      password,
    });
}

export default AuthorizerApi