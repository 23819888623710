import React from "react";
import { Modal } from "reactstrap";
import Portal from "../Portal";

export default ({ isOpen, toggle, children, ...rest }) => (
  <Portal>
    <Modal isOpen={isOpen} toggle={toggle} { ...rest }>
      { children }
    </Modal>
  </Portal>
);
