import { observable, runInAction, computed, action } from "mobx";

class UtilsStore {
    @observable
    _loading = 0;

    @observable
    isMobile = window.innerWidth <= 768;

    constructor(){
        window.addEventListener(
            "resize",
            () => runInAction(
                () => this.isMobile = window.innerWidth <= 768
            )
        );
    }

    set loading (value){
        this.setLoading(value);
    }
    
    @action
    setLoading(boolean){
      this._loading = !!boolean ? this._loading+1 : this._loading>0 ? this._loading-1 : 0
    }

    @action
    setForcedLoading(boolean){
        this._loading = !!boolean;
    }

    @computed
    get loading(){
        return this._loading;
    }
}

const store = new UtilsStore();
export default store;