const enumValue = (description, translateDescription) =>
  Object.freeze({
    toString: () => description,
    translate: () => translateDescription,
  });

export const CREATED = enumValue("CREATED", "Cadastrada");
export const DISABLED = enumValue("DISABLED", "Desabilitada");
export const ENABLED = enumValue("ENABLED", "Habilitada");
export const CHANGE_REQUEST = enumValue("CHANGE_REQUEST", "Pendente");
export const REACTIVATION_REQUEST = enumValue("REACTIVATION_REQUEST", "Aguardando Reativação");

export const values = () => [DISABLED, ENABLED, CREATED, CHANGE_REQUEST, REACTIVATION_REQUEST];

export const parser = (status) => values().find((value) => value.toString() === status);

