import React from "react";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from "history";
import { Redirect } from "react-router-dom";
import { Else, If, Then, } from 'react-if';
import { AuthorizerStore } from "../stores";
import { Breadcrumb } from "../components";
import { isAllow } from "../utils";


const browserHistory = createBrowserHistory();
export const RoutingStore = new RouterStore();
export const history = syncHistoryWithStore(browserHistory, RoutingStore);

export const NoMatch = () => <Redirect to="/login" />;

export class RouteInfo {
  constructor(path = "/", component = NoMatch, roles = []) {
    this.path = path;
    this.component = component;
    this.roles = roles;
  }
}

export const  AuthenticateRoute = ({ component: Component, roles,  ...props }) => (
  <If condition={AuthorizerStore.authenticated && isAllow(roles)}>
    <Then>
      <Breadcrumb/>
      <Component {...props}/>
    </Then>
    <Else>
      <NoMatch/>
    </Else>
  </If>
)