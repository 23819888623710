import Api from "./Api";

class TrackerParameterApi extends Api {
  constructor() {
    super("/tracker-parameter");
  }

  getByCompanyUuid = async (uuid) => {
    return this.get(`${uuid}`);
  }
}

export default TrackerParameterApi