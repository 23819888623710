import React from "react";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";
import { useSound } from "hooks";

export default ({ observable }) => {
    const [isMute, mute] = useSound(observable);
    const id = "sound-notification";
    return (
        <>
            { (!isMute ? FaVolumeUp: FaVolumeMute)({ onClick: mute, style: { cursor: 'pointer' }, id }) }
            <UncontrolledTooltip target={id}>
                {!isMute ? 'Des': 'H'}abilitar notificação sonora
            </UncontrolledTooltip>
        </>
    )
}