import React from "react";
import { When } from "react-if";
import { UncontrolledTooltip } from "reactstrap";

const { COLORS } = process.env;

export default ({ value: color, showLabel }) => {
    const value = (COLORS.colors[color]|| '').trim() || (color|| '').toUpperCase();
    let background = '';
    for (const key in COLORS.backgroundColor) {
        if (COLORS.backgroundColor.hasOwnProperty(key)) {
            const colors = COLORS.backgroundColor[key];
            if (colors.includes(value)) {
                background = key;
                break;
            }
        }
    }
    return (
        <>
            <When condition={showLabel}>
                <b>{ value }</b>&nbsp;
            </When>
            <span id={`id-${color}`} style={{
                background: background ? background: 'linear-gradient(-37deg, transparent 49%, red 50%, transparent 51%), linear-gradient(37deg, transparent 49%, red 50%, transparent 51%)',
                height: '25px',
                width: '25px',
                display: 'inline-block',
                verticalAlign: 'middle',
                border: '1px solid #ced4da',
                borderRadius: '0.25rem'
            }}/>
            <UncontrolledTooltip placement="top" target={`id-${color}`}>
                { color || `Cor não conhecida. Por favor, entrar em contato com os administradores (${color})`}
            </UncontrolledTooltip>
        </>
    ) 
}