import React, { Suspense } from 'react';
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { Router, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import { When } from 'react-if';
import { Provider, observer } from 'mobx-react';
import { PulseLoader } from 'react-spinners';
import { Container, Row } from 'reactstrap';
import ClearCache from "react-clear-cache";
import { withRouter } from "react-router";
import { CaptureStore, CameraStore, AuthorizerStore, ReportStore, TargetListStore, UtilsStore, QueryParamsStore } from './stores';
import { Conpass, CheckVersion, Downloader } from "./components";
import { RoutingStore, history, AuthenticateRoute } from './routing';
import { privateRoutes, publicRoutes } from './routes';
import Layout from './layout';
import 'react-toastify/dist/ReactToastify.css';
import './App.sass';

const Fallback = () => (
  <div className='h-100 d-flex'>
    <div className="m-auto">
      <PulseLoader/>
    </div>
  </div>
)

const App = withRouter(
  observer(
    () => (
      <Layout>
          <LoadingOverlay
            active={!!(UtilsStore.loading || AuthorizerStore.loading)}
            fadeSpeed={200}
            spinner
            styles={{
              wrapper: (base) => ({
                ...base, overflow: !!(UtilsStore.loading || AuthorizerStore.loading) ? 'hidden': ''
              }),
              overlay: (base) => ({
                ...base, zIndex: "2000 !important"
              })
            }}>
            <ToastContainer limit="1" />
            <ClearCache>
              {
                ({ emptyCacheStorage }) => {
                  let envVersion = process.env.VERSION;
                  const storedEnvVersion = localStorage.getItem("ENV_VERSION")
                  if (!!storedEnvVersion && storedEnvVersion !== envVersion) {
                    toast(
                      <>
                        Uma atualização foi detectada.
                        <br />
                        Clique aqui para atualizar.
                      </>
                      , {
                        closeButton: false,
                        closeOnClick: true,
                        autoClose: false,
                        position: toast.POSITION.TOP_CENTER,
                        bodyClassName: 'color-black',
                        onClick: e => {
                          e.preventDefault();
                          localStorage.setItem("ENV_VERSION", envVersion)
                          emptyCacheStorage();
                        },
                      });
                      return (
                        <Row className="d-flex flex-column align-items-center justify-content-center text-center p-5" style={{ fontSize: "1.7rem", height: "90vh" }}>
                          <div style={{ maxWidth: 600 }}>
                            Uma atualização foi detectada.
                            <br />
                            Por favor atualize a versão.
                          </div>
                        </Row>
                      );
                  }
                  return (
                    <CheckVersion>
                      <When condition={AuthorizerStore.authenticated}>
                        <Conpass />
                      </When>
                      <Suspense fallback={<Fallback/>}>
                        <Container fluid>
                          <Switch>
                            {
                              privateRoutes.map(
                                ({ component, roles, ...rest }, index) => (
                                  <Route key={`private-${index}`} {...rest} render={props => <AuthenticateRoute {...{ ...props, component, roles }}/>}/>
                                )
                              )
                            }
                            { publicRoutes.map((route, index) => <Route key={`public-${index}`} {...route} />) }
                          </Switch>
                          <br />
                          <Downloader/>
                        </Container>
                      </Suspense>
                    </CheckVersion>
                  );
                }
              }
            </ClearCache>
          </LoadingOverlay>
      </Layout>
    )
  )
);


const stores = {
  AuthorizerStore,
  CaptureStore,
  RoutingStore,
  CameraStore,
  ReportStore,
  TargetListStore,
  UtilsStore,
  QueryParamsStore,
}

export default () => (
  <Router history={history}>
    <Provider {...stores}>
      <AnimatePresence>
        <App />
      </AnimatePresence>
    </Provider>
  </Router>
);