import React from "react";
import Select from "react-select";

const cbdcColors = {
    primary: '#FF7200',
    primary25: 'rgba(255, 114, 0, .75)',
    primary50: 'rgba(255, 114, 0, .5)',
    primary75: 'rgba(255, 114, 0, .25)',
}

export default props => (
    <Select {...props} theme={(theme) => ({ ...theme, colors: { ...theme.colors, ...cbdcColors }})}/>
)