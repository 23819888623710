const enumValue = (description, value) => Object.freeze({ toString: () => description, getValue: () => value });

const ONE_HOUR = enumValue('1 hora', 60 * 60 * 1000);
const SIX_HOURS = enumValue('6 horas', 6 * ONE_HOUR.getValue());
const TWELVE_HOURS = enumValue('12 horas', 2 * SIX_HOURS.getValue());
const ONE_DAY = enumValue('24 horas', 2 * TWELVE_HOURS.getValue());
const ONE_WEEK = enumValue('1 semana', 7 * ONE_DAY.getValue());
const ONE_MONTH = enumValue('1 mês', 30 * ONE_DAY.getValue());
const SIX_MONTHS = enumValue('6 meses', 6 * ONE_MONTH.getValue());
const ONE_YEAR = enumValue('1 ano', 2 * SIX_MONTHS.getValue());
export const values = () => [
    ONE_HOUR,
    SIX_HOURS,
    TWELVE_HOURS,
    ONE_DAY,
    ONE_WEEK,
    ONE_MONTH,
    SIX_MONTHS,
    ONE_YEAR
];
export const getTimeExpiration = time => {
    const value = values().filter(expiration => expiration.getValue() === time);
    if(value.length > 0){
        return value[0].toString();
    }
    return 'Desconhecido';
};