import React, { useCallback, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import { NONE, values } from "./presetsEnum";
import { useEventListener } from "hooks";
import styles from "./style.module.sass";

export default ({ onChange }) => {
    const [selected, setSelected] = useState(NONE);
    const onChangeWrapper = useCallback(preset => {
        setSelected(preset);
        onChange(preset.value);
    }, [onChange]);
    const keyHandler = useCallback(event => {
        const { key } = event;
        if(['ArrowUp', 'ArrowDown'].includes(key)){
            event.preventDefault()
            const presets = values();
            const index = presets.findIndex(({ value }) => value === selected.value);
            const upIndex = index - 1
            const downIndex = index + 1
            const newPreset = {
                'ArrowDown': presets[downIndex < presets.length? downIndex: 0],
                'ArrowUp': presets[upIndex >= 0? upIndex: presets.length - 1]
            }[key];
            onChangeWrapper(newPreset);
        }
    },[selected, onChangeWrapper]);
    useEventListener('keydown', keyHandler);

    return values().map(preset => (
        <Col key={preset.value} xs="2">
            <FormGroup check>
                <Input className={styles.preset} name="presets" type="radio" checked={preset.value === selected.value} />
                <Label check onClick={() => onChangeWrapper(preset)}>
                    &nbsp;
                    {preset.label}
                </Label>
            </FormGroup>
        </Col>
    ));
}