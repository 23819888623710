import React from "react";
import { Else, If, Then } from "react-if";
import { Row } from "reactstrap";
import platform from "platform";
import uuid from "react-uuid";
import firefoxLogo from '../assets/firefox-logo.svg'
import chromeLogo from '../assets/chrome-logo.svg'
import edgeLogo from '../assets/microsoft-edge-logo.svg'

const platforms = ["Firefox", "Chrome", "Microsoft Edge"];

const links =[{
  logo: chromeLogo,
  link: 'https://google.com/chrome',
  name: 'Google Chrome'
}, {
  logo: firefoxLogo,
  link: 'https://www.mozilla.org/pt-BR/firefox/new/',
  name: 'Mozilla Firefox'
}, {
  logo: edgeLogo,
  link: 'https://www.microsoft.com/pt-br/edge/download?form=MA13FJ',
  name: 'MMicrosoft Edge'
}]

export default ({ children }) => (
  <If condition={platforms.some((plat) => platform.name.toLowerCase().includes(plat.toLowerCase())) &&  parseInt(platform.version.substring(0, platform.version.indexOf("."))) >= 90}>
    <Then>{children}</Then>
    <Else>
      <Row className="d-flex flex-column align-items-center justify-content-center text-center p-5" style={{ fontSize: "1.7rem", height: "90vh" }}>
        <div style={{ maxWidth: "600px" }}>
          <strong>
            Browser Incompatível
          </strong>
          <br /> <br />
          Este software é compatível apenas com os navegadores Google Chrome e Mozilla Firefox de versão 90 ou maior.
          <br /> <br />
          Abaixo estão disponíveis as últimas versões:
        </div>
        <div className="d-flex justify-content-around mt-5">
          {
            links.map(({ logo, link, name}) => (
              <a key={uuid()} href={link} target="_blank" rel="noopener noreferrer"
                className="d-flex align-items-center justify-content-around mr-4"
                style={{ height: "70px", width: "240px", backgroundColor: "#3456ED", borderRadius: "13px" }}>
                <img src={logo} alt="chrome-logo"  width="40" height="40" />
                <span style={{ fontSize: "18px", color: "white", marginRight: "1rem" }}>{name}</span>
              </a>

            ))
          }
        </div>
      </Row>
    </Else>
  </If>
);
