const enumValue = (description, label) => Object.freeze({ toString: () => description, label });

export const INACTIVE = enumValue('INACTIVE', 'Inativo');
export const PENDING = enumValue('PENDING', 'Pendente de Ativação');
export const ACTIVE = enumValue('ACTIVE', 'Ativo');

export const values = () => [
    INACTIVE,
    PENDING,
    ACTIVE   
]