
import React, { memo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Badge, Nav, NavItem, NavLink } from "reactstrap";
import { MdLogout } from "react-icons/md";
import classnames from "classnames"
import { When } from "react-if";
import uuid from "react-uuid";
import { productItems, apiItems, adminItems } from "./items";
import { AuthorizerStore } from "../stores";
import { isAllow } from "../utils";
import { useScrollEndDetection } from "hooks";
import styles from "./style.module.sass";

const Empty = () => <></>;

const hasSubItemActive = (subItems, pathname) => 
    subItems.some(({ target, subItems }) => !!subItems?.length ? hasSubItemActive(subItems, pathname) : pathname.startsWith(target))

export const SidebarItem = memo(({ title, target, isSidebarOpen, notification, onClick=() => {}, roles=[], icon: Icon = Empty, subItems=[] }) => {
    const { pathname } = useLocation();
    const [isOpen, setOpen] = useState(false);
    const [notifications, setNotifications] = useState(0);

    const onClickWrapper = (event) => {
        if (!!subItems.length) {
            event.preventDefault();
            setOpen(open => !open);
        } else {
            onClick(event);
        }
    }

    useEffect(() => {
        if (isSidebarOpen) {
            setOpen(!!subItems?.length ? hasSubItemActive(subItems, pathname) : pathname.startsWith(target));
        } else {
            setOpen(false);
        }
    }, [isSidebarOpen]);

    useEffect(() => {
        const id = notification?.run(setNotifications);
        return () => !!id && clearInterval(id);
    },[])

    const active = !!subItems?.length ? hasSubItemActive(subItems, pathname) : pathname.startsWith(target);

    return (
        <When condition={isAllow(roles)}>
            <NavItem active={active} className={classnames([{[styles.header]: !!subItems?.length, [styles.open]: isOpen}, 'pl-2'])}>
                <NavLink tag={Link} to={target} onClick={onClickWrapper}>
                    <Icon size={20}/>
                    <When condition={notifications}>
                        <span className={styles.close}>&nbsp;</span>
                        <Badge className={styles.close} color="danger">{notifications}</Badge>
                    </When>
                    <span className={classnames(["ml-3", { [styles.open]: isOpen }])} >{ title }</span>
                    <When condition={notifications}>
                        &nbsp;
                        <Badge color="danger">{notifications}</Badge>
                    </When>
                    <hr />
                </NavLink>
                <When condition={subItems.length}>
                    <Nav vertical navbar className={classnames([styles.submenu, 'ml-2'])}>
                        { subItems.map(item => <SidebarItem isSidebarOpen={isSidebarOpen} key={`item_${uuid()}`} { ...item } />) }
                    </Nav>
                </When>
            </NavItem>
        </When>
    )
});

export default memo(() => {
    const history = useHistory();
    const [isOpen, setOpen] = useState(false);
    const [elementRef, atEnd] = useScrollEndDetection();
    const logout = () => {
        AuthorizerStore.setToken("");
        history.push('/login');
    };
    return (
        <nav className={classnames([styles.sidebar, "navbar-dark bg-dark d-none d-md-flex"], { [styles.continue]: !atEnd })} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <ul ref={elementRef} className="navbar-nav">
                { [...productItems, ...apiItems, ...adminItems].map(item => <SidebarItem isSidebarOpen={isOpen} key={`item_${uuid()}`} { ...item } />)}
                <SidebarItem  isSidebarOpen={isOpen} title="Logout" icon={MdLogout} onClick={logout}/>
            </ul>
        </nav>
    )
})