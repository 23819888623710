import { observable, action } from 'mobx';

class QueryParamsStore {

  @observable
  queryParams = {};

  @action
  setQueryParams(changeQueryParamsFunction) {
    this.queryParams = changeQueryParamsFunction(this.queryParams);
  }

}
const store = new QueryParamsStore();
export default store 