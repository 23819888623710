import AddressCitiesApi from "./AddressCitiesApi"
import AgentApi from "./AgentApi";
import AnalysisApi from "./AnalysisApi";
import Api from './Api'
import AuthorizerApi from "./AuthorizerApi";
import CameraApi from "./CameraApi";
import CameraParameterApi from "./CameraParameterApi";
import CaptureApi from "./CaptureApi";
import CompanyApi from "./CompanyApi";
import CustomerApi from './CustomerApi';
import DashboardApi from "./DashboardApi";
import DossieApi from "./DossieApi";
import FeedbackApi from "./FeedbackApi";
import FeedbackParameterApi from "./FeedbackParameterApi";
import HypothesisApi from "./HypothesisApi";
import IssueApi from './IssueApi';
import IssueGroupsApi from "./IssueGroupsApi";
import KeyAuthApi from "./KeyAuthApi";
import ProviderApi from "./ProviderApi";
import ReportApi from "./ReportApi";
import ReportTemplateApi from "./ReportTemplateApi";
import SearchParameterApi from "./SearchParameterApi";
import SpotlightApi from "./SpotlightApi";
import TargetApi from "./TargetApi";
import TargetListApi from "./TargetListApi";
import TrackerParameterApi from './TrackerParameterApi';
import TrackingApi from "./TrackingApi";
import UserApi from "./UserApi";
import VehicleApi from "./VehicleApi";
import VehicleParameterApi from "./VehicleParameterApi";
import VehicleTagApi from "./VehicleTagApi";
import SchemaApi from "./SchemaApi";
import SearchBatchApi from "./SearchBatchApi";
import WebhookApi from "./WebhookApi";
import FraudParameterApi from "./FraudParameterApi";
import FraudRulesApi from "./FraudRuleApi";
import OccurrenceApi from "./OccurrenceApi";
import ClonedApi from "./ClonedApi";
import FeedbackBatchApi from "./FeedbackBatchApi";
import VehiclePartApi from "./VehiclePartApi";

export default Api;
export { get, post, patch, put, del, download, save } from "./Api";

//Singletons
export const addressCitiesApi = new AddressCitiesApi();
export const agentApi = new AgentApi();
export const analysisApi = new AnalysisApi();
export const authorizerApi = new AuthorizerApi();
export const cameraApi = new CameraApi();
export const cameraParameterApi = new CameraParameterApi();
export const captureApi = new CaptureApi();
export const companyApi = new CompanyApi();
export const customerApi = new CustomerApi();
export const dashboardApi = new DashboardApi();
export const dossieApi = new DossieApi()
export const feedbackApi = new FeedbackApi();
export const feedbackbatchApi = new FeedbackBatchApi();
export const feedbackParameterApi = new FeedbackParameterApi();
export const hypothesisApi = new HypothesisApi()
export const issueApi = new IssueApi();
export const providerApi = new ProviderApi();
export const issueGroupsApi = new IssueGroupsApi();
export const reportApi = new ReportApi()
export const reportTemplateApi = new ReportTemplateApi();
export const searchParameterApi = new SearchParameterApi();
export const spotlightApi = new SpotlightApi();
export const targetApi = new TargetApi();
export const targetListApi = new TargetListApi();
export const trackerParameterApi = new TrackerParameterApi();
export const trackingApi = new TrackingApi();
export const userApi = new UserApi();
export const vehicleApi = new VehicleApi()
export const vehicleParameterApi = new VehicleParameterApi();
export const vehicleTagApi = new VehicleTagApi();
export const keyAuthApi = new KeyAuthApi();
export const schemaApi = new SchemaApi();
export const searchBatchApi = new SearchBatchApi();
export const webhookApi = new WebhookApi();
export const fraudParameterApi = new FraudParameterApi();
export const fraudRulesApi = new FraudRulesApi();
export const occurrenceApi = new OccurrenceApi();
export const clonedApi = new ClonedApi();
export const vehiclePartApi = new VehiclePartApi();