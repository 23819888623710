import React, { useEffect, useState, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { ThemeProvider } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import ResponsiveMixed from "./mixed";
import styles from "./style.module.sass";

const filterType = (data, value) => data.filter(({ type }) => type === value);

const selectType = (type) => {
  switch (type) {
    case "bar":
      return ResponsiveBar;
    case "line":
      return ResponsiveLine;
    case "pie":
      return ResponsivePie;
    case "mixed":
      return ResponsiveMixed;
    default:
      break;
  }
};

const prepareBar = data => {
  return data
    .map(({ id, legend, value }) => ({
      id,
      [legend]: value,
    }))
    .reduce((acc, curr) => {
      if (!acc.length) {
        return [curr];
      }
      let find = false;
      for (const el of acc) {
        if (el.id === curr.id) {
          find = true;
          for (const key in curr) {
            if (Object.hasOwnProperty.call(curr, key) && key !== "id") {
              el[key] = curr[key];
            }
          }
        }
      }
      if (!find) {
        return acc.concat([curr]);
      }
      return acc;
    }, []);
}

const prepareLine = data => {
  return filterType(data, "line")
    .map(({ id, legend, value, type }) => ({
      id,
      data: [
        {
          x: legend,
          y: value,
        },
      ],
      type,
    })).reduce((acc, curr) => {
      const find = acc.find(({ id }) => id === curr.id);
      if (find) {
        find.data = [...find.data, ...curr.data];
        return acc;
      } else {
        return acc.concat([curr]);
      }
    }, []);
}

const preparePie = data => {
  return data.map(({ legend, value, color }) => ({
    id: legend,
    label: legend,
    value,
    color,
  }));
}

const prepareMixedBar = data => {
  return filterType(data, "bar")
    .map(({ id, legend, value, type }) => ({
      id,
      [legend]: value,
      type,
    }))
    .reduce((acc, curr) => {
      if (!acc.length) {
        return [curr];
      }
      let find = false;
      for (const el of acc) {
        if (el.id === curr.id) {
          find = true;
          for (const key in curr) {
            if (Object.hasOwnProperty.call(curr, key) && key !== "id") {
              el[key] = curr[key];
            }
          }
        }
      }
      if (!find) {
        return acc.concat([curr]);
      }
      return acc;
    }, []);
}

const prepareMixedLine = data => {
  return filterType(data, "line")
    .map(({ id, legend, value, type }) => ({
      id,
      color: "rgba(200, 30, 15, 1)",
      data: [
        {
          x: legend,
          y: value,
        },
      ],
      type,
    }))
    .reduce((acc, curr) => {
      const find = acc.find(({ id }) => id === curr.id);
      if (find) {
        find.data = [...find.data, ...curr.data];
        return acc;
      } else {
        return acc.concat([curr]);
      }
    }, []);
}

const prepareMixed = data => {
  const bars = prepareMixedBar(data);
  const lines = prepareMixedLine(data);
  return [...bars, ...lines];
}

const prepareData = (type, data) => {
  switch (type) {
    case "bar": return prepareBar(data);
    case "line": return prepareLine(data);
    case "pie": return preparePie(data);
    case "mixed": return prepareMixed(data)
    default: break;
  }
};

const getProps = (type, data, groupMode, axisXName, axisYName, customConfig) => {
  switch (type) {
    case "bar": {
      return {
        keys: [...new Set(data.map(({ legend }) => legend))],
        groupMode,
        axisBottom: {
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 45,
          legend: axisXName,
          legendPosition: "middle",
          legendOffset: 42,
        },
        axisLeft: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisYName,
          legendPosition: "middle",
          legendOffset: -40,
        },
        ...customConfig
      };
    }
    case "line":
      return {
        enablePoints: true,
        ...customConfig
      };
    case "pie":
      return {
        padAngle: 5,
        startAngle: -166,
        radialLabelsLinkColor: {
          from: "color",
        },
        radialLabelsLinkStrokeWidth: 3,
        radialLabelsTextColor: {
          from: "color",
          modifiers: [["darker", 1.2]],
        },
        radialLabelsLinkDiagonalLength: 24,
        radialLabelsLinkOffset: 12,
        radialLabelsTextXOffset: 12,
        ...customConfig
      };
    case "mixed": {
      const max =
        Math.max(...filterType(data, "line").map(({ value }) => value)) * 1.1;
      return {
        keys: [...new Set(filterType(data, "bar").map(({ legend }) => legend))],
        groupMode: "",
        enablePoints: true,
        axisBottom: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisXName,
          legendPosition: "middle",
          legendOffset: 32,
        },
        axisLeft: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisYName,
          legendPosition: "middle",
          legendOffset: -40,
        },
        yScale: {
          type: "linear",
          min: 0,
          max,
        },
        maxValue: max,
        useMesh: true,
        ...customConfig
      };
    }
    default:
      break;
  }
};

const Chart = ({
  type = "bar",
  data = [],
  title = "",
  height = 300,
  groupMode = "grouped",
  axisXName = "axis X",
  axisYName = "axis y",
  disableLegends = false,
  onClick = () => {},
  chartConfig={}
}) => {
  const Component = selectType(type);
  const [dataState, setDataState] = useState([]);
  const [props, setProps] = useState();
  const legends = useMemo(() => {
    if (disableLegends) {
      return [];
    } else {
      return [
        {
          anchor: "top",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: -45,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ];
    }
  }, [disableLegends]);
  useEffect(() => {
    const state = prepareData(type, data);
    setDataState(state);
    const p = getProps(type, data, groupMode, axisXName, axisYName, chartConfig);
    p.legends = legends;
    setProps(p);
  }, [data, legends]);
  return (
    <>
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: "45px" }}>
        <Col style={{ height: height }}>
          <ThemeProvider>
            <Component
              data={dataState}
              {...props}
              onClick={onClick}
              margin={{
                top: 40,
                right: 50,
                bottom: 70,
                left: 50,
              }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </ThemeProvider>
          <div
            style={{ display: dataState.length ? "none" : "" }}
            className={styles["no-data"]}
          >
            <h4>Sem dados para os filtros selecionados</h4>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Chart;
