const enumValue = (description, translate) => Object.freeze({
    toString: () => description,
    label: () => translate
});

const FIVE_MINUTES = enumValue('FIVE_MINUTES', '5 minutos');
const ONE_HOUR = enumValue('ONE_HOUR', '1 hora');
const ONE_DAY = enumValue('ONE_DAY', '1 dia');
const ONE_WEEK = enumValue('ONE_WEEK', '1 semana');
const ONE_MONTH = enumValue('ONE_MONTH', '1 mês');
const NO_GROUP = enumValue('', 'Sem Agrupamento');

export default {
    NO_GROUP,
    FIVE_MINUTES,
    ONE_HOUR,
    ONE_DAY,
    ONE_WEEK,
    ONE_MONTH,
    values: () => [
        NO_GROUP,
        FIVE_MINUTES,
        ONE_HOUR,
        ONE_DAY,
        ONE_WEEK,
        ONE_MONTH
    ]
}