import React from "react";
import { Card, CardBody, Col, Form, Row } from "reactstrap";
import { useMobile } from "../hooks";
import Collapse from "./Collapse";

export default ({ children, onEnter = () => {} }) => {
    const isMobile = useMobile();
    return (
        <Card>
            <CardBody>
                <Row onKeyPress={event => event.key === 'Enter' && onEnter(event)}>
                    <Col>
                        <Collapse isOpenDefault={!isMobile} showCollapseIcon={isMobile}>
                            <Form>
                                {children}
                            </Form>
                        </Collapse>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}