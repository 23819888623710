import Api, { get } from "./Api";
import {formatDate} from 'utils'

//DOCS -> https://sandbox.carbigdata.com.br/api/v1/dashboard/swagger-ui.html
class DashboardApi extends Api {
  constructor() {
    super(process.env.REACT_APP_DASHBOARD_ENDPOINT);
  }

  getSearches = (
    groupBy,
    { username },
    companyId,
    initialDate,
    finalDate,
    month,
    year
  ) => {
    return get(
      `${this.baseUrl}/search?groupBy=${groupBy}&username=${username ||
      ""}&companyId=${companyId || ""}&initialDate=${formatDate(
        initialDate
      )}&finalDate=${formatDate(finalDate)}&month=${month ||
      ""}&year=${year || ""}`
    );
  }

  getFeedback = (
    groupBy,
    { username },
    companyId,
    initialDate,
    finalDate,
    month,
    year,
    hypothesis
  ) =>
    get(
      `${this.baseUrl}/feedback?groupBy=${groupBy}&username=${username ||
      ""}&companyId=${companyId || ""}&initialDate=${formatDate(
        initialDate
      )}&finalDate=${formatDate(finalDate)}&month=${month ||
      ""}&year=${year || ""}&hypothesis=${hypothesis || ""}`
    );

  getHypothesis = (
    { username },
    companyId,
    initialDate,
    finalDate,
    month,
    year,
    hypothesis
  ) =>
    get(
      `${this.baseUrl}/hypothesis?username=${username ||
      ""}&companyId=${companyId || ""}&initialDate=${formatDate(
        initialDate
      )}&finalDate=${formatDate(finalDate)}&month=${month ||
      ""}&year=${year || ""}&hypothesis=${hypothesis || ""}`
    );
}

export default DashboardApi
