import React from "react";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import { When } from "react-if";
import Pagination from "./pagination";
import Table from "./table";

const PageableTable = ({ headers, children, striped, totalPages, onChange, size, pageSizes, onChangePageSize, total, alertMessage, alertColor, isOpenAlert, currentPage, loading, height }) => (
  <Pagination {...{ currentPage, totalPages, onChange, size, onChangePageSize, total, pageSizes }}>
    <>
      <When condition={!!alertMessage}>
        <Alert isOpen={isOpenAlert} color={alertColor} style={{ margin: 0, paddingTop: 5, paddingBottom: 5 }} transition={{ timeout: { enter: 70, exit: 500 } }}>
          {alertMessage}
        </Alert>
      </When>
      <Table headers={headers} striped={striped} loading={loading} height={height}>
        {children}
      </Table>
    </>
  </Pagination>
);

PageableTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  striped: PropTypes.bool,
  children: PropTypes.node,
  alertColor: PropTypes.string,
  isOpenAlert: PropTypes.func,
  alertMessage: PropTypes.node,
  loading: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PageableTable;
