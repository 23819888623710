class PaginationModel {
  page;
  pageSize;
  order;
  sort;
  constructor(page = 0, pageSize = 10, order = "", sort = "") {
    this.page = page;
    this.pageSize = pageSize;
    this.order = order;
    this.sort = sort;
  }
}

export default PaginationModel;
