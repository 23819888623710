import { useEffect } from "react";
import { useScript } from "hooks";
import jwtDecode from "jwt-decode";
import { Perfil } from "model";

export default () => {
  const [loaded, error] = useScript(
    "https://fast.conpass.io/usrSrBtA767t.js",
    "conpass"
  );
  useEffect(() => {
    if (loaded && !error) {
      const token = localStorage.getItem("TOKEN");
      const { Email: email, Role: roles } = jwtDecode(token);
      const name = localStorage.getItem("USER");
      const perfil = Perfil.getPerfil(roles).toString();
      const custom_fields = { perfil };
      if (window.Conpass) {
        window.Conpass.init({
          name,
          email,
          custom_fields,
        });
      }
    }
  }, [loaded, error]);
  return null;
};
