const enumValue = (value, label) => Object.freeze({ label, value });

const PHONE_STICKER = enumValue('PHONE_STICKER', 'Adesivo de contato');
const STRUCTURE_MODIFICATION = enumValue('STRUCTURE_MODIFICATION', 'Alteração na Estrutura');
const APP = enumValue('APP', 'Aplicativo');
const DAMAGE = enumValue('DAMAGE', 'Avaria');
const MAJOR_DAMAGE = enumValue('MAJOR_DAMAGE', 'Avaria Grande');
const MINOR_DAMAGE = enumValue('MINOR_DAMAGE', 'Avaria Pequena');
const TOW_TRUCK = enumValue('TOW_TRUCK', 'Em Cima do Guincho');
const OTHER = enumValue('OTHER', 'Outros');
const TAXI = enumValue('TAXI', 'Taxi');
const COMMERCIAL_USE = enumValue('COMMERCIAL_USE', 'Uso Comercial');
const GLASS_DAMAGE = enumValue('GLASS_DAMAGE', 'Avaria no vidro');
const PARKED_VEHICLE = enumValue('PARKED_VEHICLE', "Veiculo estacionado");
const UTILITY_TRAILER = enumValue('UTILITY_TRAILER',"Carreta");

export const values = () => [
    PHONE_STICKER,
    STRUCTURE_MODIFICATION,
    APP,
    DAMAGE,
    MAJOR_DAMAGE,
    MINOR_DAMAGE,
    TOW_TRUCK,
    OTHER,
    TAXI,
    COMMERCIAL_USE,
    GLASS_DAMAGE,
    PARKED_VEHICLE,
    UTILITY_TRAILER
];

export const find = value => values().find(item => item.value === value); 