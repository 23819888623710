import { observable, action } from 'mobx';
import { OrderEnum, SortCaptureEnum } from '../model';
import UtilsStore from './UtilsStore';
import { captureApi } from 'api';
import { formatDate } from 'utils';

class Filter {
    @observable
    openField = '';
    @observable
    confidence = [];
    @observable
    startDate = '';
    @observable
    finishDate = '';
    @observable
    devices = [];
    @observable
    provider = '';
    @observable
    startTime = '';
    @observable
    finishTime = '';
    @observable
    spotlight = false;
    @observable
    reproved = false;
    @observable
    avaliate = false;
    @observable
    hash = '';

    @action
    reset() {
        this.openField = '';
        this.confidence = [];
        this.startTime = '';
        this.startDate = '';
        this.finishTime = '';
        this.finishDate = '';
        this.devices = [];
        this.providers = [];
        this.spotlight = false;
        this.avaliate = false;
        this.reproved = false;
    }
}

class CaptureStore {

    @observable
    captures = [];

    @observable
    vehicle = null;

    @observable
    size = 0;

    @observable
    pageSize = 10;

    @observable
    order = OrderEnum.DESC.toString();

    @observable
    sort = SortCaptureEnum.DATE_CAPTURED.toString();

    @observable
    isLastPage = false;

    @observable
    searched = false;

    @observable
    trackingId = '';
    
    filter = new Filter();
    
    constructor(api) {
        this.api = api;
    }

    @action
    setOpenField = value => this.filter.openField = value;

    @action
    setConfidence = value => this.filter.confidence = value;

    @action
    setStartDate = value => this.filter.startDate = value;

    @action
    setStartTime = value => this.filter.startTime = value;

    @action
    setFinishTime = value => this.filter.finishTime = value;

    @action
    setFinishDate = value => this.filter.finishDate = value;

    @action
    setDevices = value => this.filter.devices = value;

    @action
    setProvider = value => this.filter.provider = value;

    @action
    setSpotlight = value => this.filter.spotlight = value;

    @action
    setReproved = value => this.filter.reproved = value;

    @action
    setAvaliate = value => this.filter.avaliate = value;

    @action
    setHash = value => this.filter.hash = value;

    @action
    setPageSize = value => this.pageSize = value;

    @action
    setOrder = value => this.order = value;

    @action
    setSort = value => this.sort = value;

    @action
    setCaptures = value => this.captures = value;

    @action
    setVehicle = value => this.vehicle = value

    @action
    setSize = value => this.size = value;

    @action
    setIsLastPage = value => this.isLastPage = value;
    
    @action
    setSearched = value => this.searched = value;

    @action
    setTrackingId = value => this.trackingId = value;
    
    @action
    reset() {
        this.filter.reset();
        this.setOrder(OrderEnum.DESC.toString());
        this.setSort(SortCaptureEnum.DATE_CAPTURED.toString());
        this.setVehicle(null);
        this.setSearched(false);
    }

    @action
    setImage = (source, index) => {
        const { captures } = this;
        if (index < captures.length) {
            captures[index].imageSrc = source;
        }
    };

    @action.bound
    fetch = async (approved=true, page = 0) => {
        try {
            UtilsStore.setLoading(true);
            const { filter, order, sort, trackingId } = this;
            const { openField, confidence, startDate, finishDate, devices, startTime, finishTime, spotlight, reproved, hash } = filter;
            let confidenceAtt = !!confidence.length ? `&confidence=${confidence}` : '';
            const url =
                `size=${this.pageSize}&page=${page}&openFields=${openField}${confidenceAtt}` +
                `&startDate=${formatDate(startDate)}&startTime=${startTime && startTime.slice(0,5)}&finishDate=${formatDate(finishDate)}` +
                `&finishTime=${finishTime && finishTime.slice(0,5)}&order=${order}&sort=${sort}&spotlight=${spotlight || ''}` +
                `&devices=${devices.map(({ value }) => value).join(',')}&reproved=${reproved || ''}&requestId=${hash}&approved=${!!trackingId&& approved}`;
            const urlNextPage = `size=${this.pageSize}&page=${page + 1}&openFields=${openField}${confidenceAtt}` +
                `&startDate=${formatDate(startDate)}&startTime=${startTime}&finishDate=${formatDate(finishDate)}` +
                `&finishTime=${finishTime}&order=${order}&sort=${sort}&spotlight=${spotlight || ''}&approved=${!!trackingId && approved}` +
                `&devices=${devices.map(({ value }) => value).join(',')}&reproved=${reproved || ''}&requestId=${hash}`;
            const { content, totalElements, vehicle, requestId } = await this.api.fetch(url);
            this.setHash(requestId);
            this.setCaptures(content);
            this.setSize(totalElements);
            this.setSearched(true);
            if (!openField) {
                const { content: nextPageContent } = await this.api.fetch(urlNextPage);
                if (nextPageContent.length === 0) {
                    this.setIsLastPage(true);
                }
            } else {
                this.setIsLastPage(totalElements <= this.pageSize * (page + 1));
            }
            this.setVehicle(vehicle);
            if (!!openField) {
                this.setAvaliate(!!totalElements);
            }
            UtilsStore.setLoading(false);
            return page + 1;
        } catch (err) {
            UtilsStore.setLoading(false);
            throw err
        } 
    }
}

const store = new CaptureStore(captureApi);
export default store;