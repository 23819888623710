export function backgroundDownload(callback, filename) {
    const event = new CustomEvent('download_manager', { detail: { callback, filename } });
    window.dispatchEvent(event);
}

export function finishDownload(uuid) {
    const event = new CustomEvent('download_finish', { detail:  uuid });
    window.dispatchEvent(event);
}

export function errorDownload(uuid) {
    const event = new CustomEvent('download_error', { detail:  uuid });
    window.dispatchEvent(event);
}

export function openDownload() {
    const event = new CustomEvent('download_open');
    window.dispatchEvent(event);
}