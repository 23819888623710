const enumValue = (value, label) =>
    Object.freeze({
        value,
        label,
    });

export const IN_PROGRESS = enumValue('IN_PROGRESS', 'Em progresso');
export const PARTIAL_DONE = enumValue('PARTIAL_DONE', 'Parcialmente Concluído');
export const DONE_WITH_ERROR = enumValue('DONE_WITH_ERROR', 'Concluído com erro');
export const DONE = enumValue('DONE', 'Concluído');
export const ERROR = enumValue('ERROR', 'Com Erro');

export const filterValues = () => [
    IN_PROGRESS,
    DONE,
    ERROR,
];

export const exportValues = () => [ DONE_WITH_ERROR, DONE ];

export const find = status => [
    IN_PROGRESS,
    PARTIAL_DONE,
    DONE_WITH_ERROR,
    DONE,
    ERROR
].find(({ value }) => value === status);