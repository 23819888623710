const enumValue = (description, label) => Object.freeze({
    toString: () => description,
    label: label
});

export const WEEK_1 = enumValue('WEEK_1', '1 Semana')
export const WEEK_2 = enumValue('WEEK_2', '2 Semanas')
export const WEEK_3 = enumValue('WEEK_3', '3 Semanas')
export const WEEK_4 = enumValue('WEEK_4', '4 Semanas')
export const FURTHER = enumValue('FURTHER', 'Mais de 4 semanas')

export const values = () => [
    WEEK_1,
    WEEK_2,
    WEEK_3,
    WEEK_4,
    FURTHER,
]