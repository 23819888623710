import React from 'react';
import { Row, Col, Progress } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

export default ({ name, progress, onCancel, onClean }) => (
    <div className="d-flex flex-column align-items-center justify-content-center py-2 w-100" >
        <Row className="w-100">
            <Col xs="12" className="ellipsis">
                {name}
            </Col>
        </Row>
        <Row className="w-100 d-flex align-items-center justify-content-start">
            <Col xs="3">
                <FaTimes cursor="pointer" onClick={() => progress !== 100 && progress !== 'Error' ? onCancel() : onClean()} />
            </Col>
            <Col xs="9">
                <Progress color={progress !== 'Error' ? 'primary' : 'danger'} value={progress}>{progress !== 'Error' ? progress + '%': progress}</Progress>
            </Col>
        </Row>
    </div>
)