import React, { Children } from "react";
import { Async } from "react-async";
import { Case, Default, Else, If, Switch, Then } from "react-if";
import PulseLoader from "react-spinners/PulseLoader";

function Loader(){
    return (
        <div className="w-100 text-center">
            <PulseLoader />
        </div>
    )

}

export const Load = ({ children }) => children;
export const Reject = ({ children }) => children;
export const Resolve = ({ children }) => children;

const Render = ({ component, value }) => (
    <If condition={typeof component?.props?.children === 'function'}>
        <Then>{ component?.props?.children(value) }</Then>
        <Else>{ component?.props?.children }</Else>
    </If>
);

export default ({ promiseFn, children }) => {
    const loadComponent = Children.toArray(children).find(({ type }) => type === Load );
    const rejectComponent = Children.toArray(children).find(({ type }) => type === Reject );
    const resolveComponent = Children.toArray(children).find(({ type }) => type === Resolve );
    return (
        <Async promiseFn={promiseFn}>
            {({ value , isLoading, isRejected, error }) => (
                <Switch>
                    <Case condition={isLoading}>
                        { loadComponent || <Loader/> }
                    </Case>
                    <Case condition={isRejected || error}>
                        <Render component={rejectComponent} value={error}/>
                    </Case>
                    <Default>
                        <Render component={resolveComponent} value={value}/>
                    </Default>
                </Switch>
            )}
        </Async>
    )
}