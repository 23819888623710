const enumValue = (value, label) => Object.freeze({ value, label });

const SUSPICION_MERCOSUL = enumValue('SUSPICION_MERCOSUL', 'Suspeita Mercosul');
const PROFILE_BREAK = enumValue('PROFILE_BREAK', 'Quebra de Perfil');
const PREEXISTING_DAMAGE = enumValue('PREEXISTING_DAMAGE', 'Dano Preexistente');
const PREEXISTING_COMPREHENSIVE_DAMAGE = enumValue('PREEXISTING_COMPREHENSIVE_DAMAGE', 'Dano Preexistente e Integral');
const VIEW_AFTER_OCCURRENCE = enumValue('VIEW_AFTER_OCCURRENCE', 'Integral visto após a ocorrência');
const VEHICLE_ON_TOW_TRUCK = enumValue('VEHICLE_ON_TOW_TRUCK', 'Veiculo em Guincho');
export const SUSPECTED_CLONED_VEHICLE = enumValue('SUSPECTED_CLONED_VEHICLE', 'Suspeita Dublê');
const LOSS_PROFIT = enumValue('LOSS_PROFIT', 'Lucro Cessante');

export const values = () => [
    SUSPICION_MERCOSUL,
    PROFILE_BREAK,
    PREEXISTING_DAMAGE,
    PREEXISTING_COMPREHENSIVE_DAMAGE,
    VIEW_AFTER_OCCURRENCE,
    VEHICLE_ON_TOW_TRUCK,
    SUSPECTED_CLONED_VEHICLE,
    LOSS_PROFIT
];

export const find = value => values().find(fraud => fraud.value === value);