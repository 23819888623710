import Api, {download} from "./Api";
import axios from 'axios'
import ReportTemplateApi from "./ReportTemplateApi";

class ReportApi extends Api {
  constructor() {
    super("/report");
    this.reportTemplateApi = new ReportTemplateApi();
  }

  getReports = (id, pageNumber, pageSize, sort, order) =>
    axios.all([
      this.fetch(
        `id=${id}&page=${pageNumber || ""}&sort=${sort || ""}&order=${order ||
        ""}&size=${pageSize || ""}`
      ),
      this.reportTemplateApi.get(id),
    ]);

  download = async (id, filename, source) =>
    download(
      `${this.baseUrl}/${id}/download?source=${source}`,
      filename,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
}

export default ReportApi;