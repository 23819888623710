
import React, { useState } from "react";
import {  Row, Col, Collapse, Card, CardHeader, CardBody, ListGroup } from "reactstrap";
import { FaTimes, FaWindowMaximize, FaWindowMinimize } from "react-icons/fa"
import { v4 as uuidV4 } from "uuid";
import { CancelToken } from "axios";
import uuid from "react-uuid";
import { finishDownload, errorDownload } from "utils/events";
import { useEventListener } from "hooks";
import Item from "./Item";
import styles from "./style.module.sass"; 

export default () => {
    const [isOpen, setIsOpen] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [downloads, setDownloads] = useState([]);
    const open = () => {
        setIsOpen(true);
        setMinimized(false);
    }
    const deleteDownload = (uuid) => {
        const downloadClone = [...downloads];
        const index = downloadClone.findIndex(d => d.uuid === uuid)
        downloadClone.splice(index, 1);
        setDownloads(downloadClone);
        if (downloadClone.length === 0) {
            setIsOpen(false);
        }
    }
    useEventListener('download_open', open);
    useEventListener(
        'download_manager',
        async ({ detail: { callback, filename} }) => {
            const source = CancelToken.source();
            const cancel = source.token
            const uuid = uuidV4()
            const download = {
                uuid,
                filename,
                cancel: (uuid) => {
                    source.cancel();
                    deleteDownload(uuid);
                }
            }
            setDownloads(d => [download, ...d]);
            open();
            try{
                await callback(cancel);
            } catch (err) {
                errorDownload(uuid);
            } finally {
                finishDownload(uuid);
            }

        }
    );
    return false && (
        <div className={styles.downloader}>
            <Collapse isOpen={isOpen}>
                <Card className={styles.card}>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>Downloads</h5>
                            </Col>
                                <Col xs="1">
                                    {
                                        !minimized ?
                                            <FaWindowMinimize onClick={() => setMinimized(true)}/>:
                                            <FaWindowMaximize onClick={() => setMinimized(false)}/>

                                    }
                                </Col>
                                <Col xs="1">
                                    <FaTimes onClick={()=> setIsOpen(false)}/>
                                </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={!minimized}>
                        <CardBody className={styles.body}>
                            <ListGroup>
                                { 
                                    downloads.map(
                                        download => <Item key={download.uuid} onDelete={() => deleteDownload(uuid)} {...download}/>
                                    )
                                }
                            </ListGroup>
                        </CardBody>
                    </Collapse>
                </Card>
            </Collapse>
        </div>
    )
  }