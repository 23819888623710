import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './tabSelector.sass';

const TabSelector = ({ activeTab, tabIndex, onClick, children, error, className }) => (
    <NavItem>
        <NavLink
            className={classnames([{ active: activeTab === tabIndex }, className])}
            onClick={() => onClick(tabIndex)}
            style={error ? { color: 'red', cursor: "pointer" } : { cursor: "pointer" }}
        >
            { children }
        </NavLink>
    </NavItem>
)

export default TabSelector
