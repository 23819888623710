import Api, { get, put } from "./Api";

class VehicleParameterApi extends Api {
  constructor() {
    super("/vehicle-parameter");
  }

  getParametherVehicle = uuid => get(`${(process.env.REACT_APP_AUTH_ENDPOINT || "")}/vehicle-parameter/${uuid}`);

  updateParametherVehicle = (uuid, data) => put(`${(process.env.REACT_APP_AUTH_ENDPOINT || "")}/vehicle-parameter/${uuid}`, data);
}

export default VehicleParameterApi