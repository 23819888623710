import { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import uuid from "react-uuid";
import { DeviceType } from "../../model";
import PinType from "./type";

const libraries = ["places", "visualization"];

const transform = ({  position, captures }) => captures?.map(({ position: p }) => new window.google.maps.LatLng(p) ) || [new window.google.maps.LatLng(position)];
const isFixed = ({ deviceType }) => deviceType === DeviceType.FIXED.toString();
const hasCapture = ({ captures, deviceType }) => !isFixed({ deviceType }) && !!captures?.length
const filter = ({ captures, deviceType, type }) => isFixed({ deviceType }) || hasCapture({ captures, deviceType }) || [PinType.HOME, PinType.INSURANCE_CLAIM].includes(type)

export const getPoints = markers => markers.filter(filter).map(transform).reduce((acc, curr) => [...acc, ...curr], []);
export const getHeatMapPoint = markers => markers.filter(({ type }) => [PinType.HIT, PinType.TOP].includes(type)).map(transform).reduce((acc, curr) => [...acc, ...curr], []);

function distanceByZoom(zoom){
    switch (zoom) {
      case 0:
      case 1: return 25;
      case 2: return 10;
      case 3: return 5;
      case 4: return 2.5;
      case 5: return 1;
      case 6: return .5;
      case 7: return .25;
      case 8: return .1;
      case 9: return .05;
      case 10: return .025;
      case 11: return .01;
      case 12: return .005;
      case 13: return .0025;
      case 14: return .001;
      case 15: return .0005;
      case 16: return .00025;
      case 17: return .0001;
      case 18: return .00005;
      case 19: return .000025;
      case 21: return .00001;
      default: return .000005;
    }
}

export function isOnRadius(pointA, pointB, zoom){
    // 0.0001° = 11.1 m
    return Math.sqrt(
      Math.pow(pointA.lat.toFixed(4) - pointB.lat.toFixed(4), 2) +
      Math.pow(pointA.lng.toFixed(4) - pointB.lng.toFixed(4), 2)
    ).toFixed(4) <= distanceByZoom(zoom)
  }

export function useLoad(markers, callback) {
    return map => {
        const points = getPoints(markers);
        const bounds = new window.google.maps.LatLngBounds();
        for (const element of points) {
            bounds.extend(element);
        }
        map.fitBounds(bounds);
        callback(map);
    };
}

export function useMap(){
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_KEY_MAP,
        libraries
    });
    return isLoaded;
}

export function useClusters(zoom, markers){
    const [clusters, setCluster] = useState([]);
    useEffect(() => {
        const aux = markers.filter(({ type }) => [PinType.HIT, PinType.TOP].includes(type))
          .reduce((acc, curr) => [
            ...acc,
            ...(
              isFixed(curr)?
                [curr]:
                curr.captures.reduce((acc2, curr2) => {
                  const found = acc2.find(captures => captures.some(({ id }) => id === curr2.id));
                  if(!found) {
                    acc2.push(
                      curr.captures.filter(({ position }) => isOnRadius(curr2.position, position, zoom))
                    )
                  }
                  return acc2;
                }, []).map(captures => ({ ...curr, captures, position: captures[0].position, address: captures[0].address }))
            )
          ], []).sort((a, b) => b?.captures?.length - a.captures.length);
        if (aux.length){
          const newClusters = aux.reduce((acc, curr) => {
            const found = acc?.find(({ cluster }) => cluster.some(({ device, position }) => device === curr.device && isOnRadius(curr.position, position, zoom)));
            if (!found) {
              const cluster = aux.filter(({ position }) => isOnRadius(curr.position, position, zoom))
              const hits = cluster.reduce((acc, { captures }) => acc + captures.length, 0)
              if (hits) {
                acc.push({
                  id: uuid(),
                  devices: cluster.map(({ device }) => device),
                  hits: cluster.reduce((acc, { captures }) => acc + captures.length, 0),
                  position: curr.position,
                  cluster
                })
              }
            }
            return acc;
          }, []).map(
            c => ({
              ...c,
              type: c.cluster.some(({ type }) => type === PinType.TOP) ? PinType.TOP: PinType.HIT
            })
          )
          setCluster(newClusters);
        }
      }, [markers, zoom]);
    return clusters;
}