import React, { useState, useEffect } from "react";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import { BounceLoader } from "react-spinners";
import { If, Then, Else } from "react-if";
import { Row, Col } from "reactstrap";
import Draw from "./draw";
import styles from "./style.module.sass";

export default ({ toggle, onSave, onOpen, width, height, areas = [] }) => {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const prev = () => setIndex((i) => (i > 0 ? i - 1 : images.length - 1));
  const next = () => setIndex((i) => (i < images.length - 1 ? i + 1 : 0));
  const onKeyPress = ({ key }) => {
    switch (key) {
      case "Escape": {
        toggle();
        break;
      }
      case "ArrowRight": {
        next();
        break;
      }
      case "ArrowLeft": {
        prev();
        break;
      }
      default:
        return;
    }
  };

  const wrapperSave = async (items) => {
    try {
      await onSave(items);
      toggle();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // fetch images
    (async () => {
      const results = await onOpen();
      setImages(results);
      setLoading(false);
    })();
  }, []);
  useEffect(() => {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;";
    window.addEventListener("keydown", onKeyPress);
    return () => {
      document.getElementsByTagName("body")[0].style = "";
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [images]);

  return (
    <Row className={styles.popup} style={{ visibility: "visible", width: window.innerWidth + 15, padding: "100px 0" }}>
      <Col md="1">
        <FaAngleLeft className={styles.arrow} onClick={prev} />
      </Col>
      <Col md="10">
        <FaTimes className={styles.close} onClick={toggle} />
        <Row style={{ height: "calc(100vh-100px)" }}>
          <Col>
            <If condition={loading}>
              <Then>
                <span className={styles["loading-icon"]}>
                  <BounceLoader size={300} color="white" sizeUnit="px" />
                </span>
              </Then>
              <Else>
                <If condition={images.length > 0}>
                  <Then>
                    <Draw src={images[index]} onSave={wrapperSave} areas={areas} { ...{ width, height } } />
                  </Then>
                  <Else>
                    <div className={styles['no-content']}>
                      <div style={{ color: 'white' }}>
                        <b>Não existem capturas nos últimos 90 dias</b>
                      </div>
                    </div>
                  </Else>
                </If>
              </Else>
            </If>
          </Col>
        </Row>
        <Row style={{ height: "100px" }}>
          {images.map((image, i) => (
            <Col>
              <img
                src={image}
                alt="select"
                onClick={() => setIndex(i)}
                style={{
                  cursor: "pointer",
                  border: i === index ? "1px grey solid" : "none",
                  marginTop: "10px",
                }}
                height="100px"
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col md="1">
        <FaAngleRight className={styles.arrow} onClick={next} />
      </Col>
    </Row>
  );
};
