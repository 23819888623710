import React, { useEffect, useState, memo, Fragment } from "react";
import { Card, CardBody, Badge, UncontrolledTooltip } from "reactstrap";
import { FaArrowCircleUp, FaExclamationCircle, FaTimes } from "react-icons/fa";
import { Case, Default, Else, If, Switch, Then, When } from "react-if";
import { OverlayView } from "@react-google-maps/api";
import classnames from "classnames";
import Loader, { Resolve } from "../loader";
import Carousel from "../carousel";
import PinType from "./type";
import { Address, CameraSideEnum } from "model";
import { drawBBoxPlate } from "utils";
import { captureApi } from "api";
import insurancePin from "assets/CEPsinistro.svg"
import homePin from "assets/CEPpernoite.svg"
import styles from "./styles.module.sass";

const style = { cursor: 'pointer' };

const H4 = ({ children, ...props }) => <h4 {...props}>{children}</h4>
const H6 = ({ children, ...props }) => <h6 {...props}>{children}</h6>

const Img = memo(({ uuid, imageCoordinates, storagePath, riskArea, tags }) => {
    const getImage = async () => {
        const source = await captureApi.getImage(storagePath);
        return drawBBoxPlate(source, imageCoordinates)
    }
    return (
        <div className={classnames([styles['image'], 'd-flex'])}>
            <Loader promiseFn={getImage}>
                <Resolve>
                    { 
                    source => 
                        <>
                            <img src={source} alt="capture" className="h-100 m-auto w-auto"/>
                            <When condition={riskArea}>
                                <FaExclamationCircle id={`risk-${uuid}`} color="#f00" />
                                <UncontrolledTooltip placement="top" target={`risk-${uuid}`}>
                                    Área de Risco
                                </UncontrolledTooltip>
                            </When>
                            <When condition={tags.length}>
                                {
                                    tags.map(tag => 
                                        <Badge color="warning" style={{ position: 'absolute', top: 5, left: 5 }}>{tag}</Badge>
                                    )
                                }
                            </When>
                        </>
                    } 
                </Resolve>
            </Loader>
        </div>
    )
});

function InfoOther({ position, type, address: addressObj, onClose }){
    const address = Address.of(addressObj).formatAddress();
    const id = `address-${type}`;
    return (
        <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <Fragment>
                <Card className={classnames([styles.info, 'info-map'])}>
                    <CardBody className="pt-1" style={{ fontSize: 'medium'}}>
                        <div className="text-left">
                            <span className="d-flex mb-1">
                                <strong>
                                    <If condition={type === PinType.HOME}>
                                        <Then>Endereço de pernoite</Then>
                                        <Else>Endereço da ocorrência(Sinistro)</Else>
                                    </If>
                                </strong>
                            </span>
                            <div className="mb-1">
                                <div id={id} className="w-100">{address}</div>
                                <UncontrolledTooltip placement="top" target={id}>{address}</UncontrolledTooltip>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <div className={styles.close}>
                    <FaTimes size={15} onClick={onClose} />
                </div>
            </Fragment>
        </OverlayView>
    )
}

function InfoCam({ position, device: { device, deviceOrientations, deviceTag, captureDate, address: addressObj }, onClose }){
    const address = Address.of(addressObj).formatAddress();
    return (
        <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <Fragment>
                <Card className={classnames([styles.info, 'info-map'])}>
                    <CardBody className="pt-1" style={{ fontSize: 'medium'}}>
                        <div className="text-left">
                            <span className="d-flex mb-1">
                                <strong>{device}</strong>
                                &nbsp;
                                <small>
                                    <Badge>{deviceTag}</Badge>
                                </small>
                            </span>
                            <div className="mb-1">
                                <div>
                                    {new Date(captureDate).toLocaleString('pt-BR')}
                                </div>
                                <div id={`address-${device}`} className="ellipsis w-100">{address}</div>
                                <UncontrolledTooltip placement="top" target={`address-${device}`}>{address}</UncontrolledTooltip>
                            </div>
                            <div>
                            {
                                deviceOrientations?.map?.(orientation => (
                                    <Fragment key={orientation}>
                                        <Badge color="warning">{CameraSideEnum.find(orientation).label}</Badge>
                                        &nbsp;
                                    </Fragment>
                                ))
                            }
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <div className={styles.close}>
                    <FaTimes size={15} onClick={onClose} />
                </div>
            </Fragment>
        </OverlayView>
    );
}

function Info({ position, cluster, onClose }) {
    const [current, setCurrent] = useState(0);
    const [imagemDownloadIndex, setImagemDownloadIndex] = useState(0);

    const captures = cluster.reduce((acc, { captures }) => [...acc, ...captures], []);
    const capture = captures[current];
    const address = Address.of(capture).formatAddress();

    useEffect(() => { current > imagemDownloadIndex && setImagemDownloadIndex(current) },[current]);
    return (
        <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <Fragment>
                <Card className={classnames([styles.info, 'info-map'])} onDoubleClick={e => e.stopPropagation()}>
                    <Carousel showIndicators onNext={setCurrent} onPrevious={setCurrent} >
                        { 
                            captures?.map(
                                ({ uuid, imageCoordinates, storagePath, riskArea, tags }, index) => (
                                    <Fragment key={uuid}>
                                        <When condition={imagemDownloadIndex >= index}>
                                            <Img { ...{ uuid, imageCoordinates, storagePath, riskArea, tags } } />
                                        </When>
                                        <When condition={riskArea}>
                                            <FaExclamationCircle id={`risk-${uuid}`} size={20} color="#f00" />
                                            <UncontrolledTooltip placement="top" target={`risk-${uuid}`}>
                                                Área de Risco
                                            </UncontrolledTooltip>
                                        </When>
                                        <When condition={tags.length}>
                                            { tags.map(tag => <Badge key={tag} color="warning" style={{ position: 'absolute', top: 5, left: 5, fontSize: 'small' }}>{tag}</Badge> ) }
                                        </When>
                                    </Fragment>
                                )
                            )
                        }
                    </Carousel>
                    <hr className="w-75 mx-auto my-1"/>
                    <CardBody className="pt-1" style={{ fontSize: 'medium'}}>
                        <div className="text-left">
                            <span className="d-flex mb-1">
                                <strong>{capture.device}</strong>
                                &nbsp;
                                <When condition={capture.isTopDevice}>
                                    <FaArrowCircleUp id="top" style={{ alignSelf: 'center' }} color="#FD7E14" />
                                    <UncontrolledTooltip placement="right" target="top">
                                        Mais Capturas
                                    </UncontrolledTooltip>
                                    &nbsp;
                                </When>
                                <small>
                                    <Badge>{capture.deviceTag}</Badge>
                                </small>
                            </span>
                            <div className="mb-1">
                                <div>
                                    {new Date(capture.captureDate).toLocaleString('pt-BR')}
                                </div>
                                <div id={`address-${capture.uuid}`} className="ellipsis w-100">{address}</div>
                                <UncontrolledTooltip placement="top" target={`address-${capture.uuid}`}>{address}</UncontrolledTooltip>
                            </div>
                            <div>
                            {
                                capture.deviceOrientations?.map?.(orientation => (
                                    <Fragment key={orientation}>
                                        <Badge color="warning">{CameraSideEnum.find(orientation).label}</Badge>
                                        &nbsp;
                                    </Fragment>
                                ))
                            }
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <div className={styles.close}>
                    <FaTimes size={15} onClick={onClose} />
                </div>
            </Fragment>
        </OverlayView>
    )
}

export default ({ id, devices, hits, position, cluster, type, selected, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const PinComponent = window.outerWidth <= 1920? H6: H4;
    return (
        <>
            <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <PinComponent style={style} onClick={() => setIsOpen(true)}>
                    <div className={classnames(["badge badge-pill", styles.pin, {
                        [styles.device]: type === PinType.DEVICE,
                        [styles.frontier]: cluster?.some(({ captures }) => captures.some(({ deviceTag }) => deviceTag?.toUpperCase() === "FRONTEIRA")),
                        [styles.spotlight]: cluster?.some(({ captures }) => captures.some(({ tags }) => !!tags.length)),
                        [styles.cluster]: devices?.length > 1,
                        [styles.top]: type === PinType.TOP,
                        [styles.selected]: selected && (devices?.includes(selected) || selected === id),
                        [styles['plus-100']]: hits >= 100,
                        [styles['plus-10']]: hits < 100 && hits >= 10,
                        [styles.other]: ![PinType.DEVICE, PinType.HIT, PinType.TOP].includes(type)
                    }])}>
                        <If condition={[PinType.DEVICE, PinType.HIT, PinType.TOP].includes(type)}>
                            <Then>
                                <When condition={type === PinType.DEVICE}>&nbsp;</When>
                                {hits}
                            </Then>
                            <Else>
                                <img src={PinType.HOME === type? homePin: insurancePin} width="32" height="32" alt="pin" />
                            </Else>
                        </If>
                    </div>
                </PinComponent>
            </OverlayView>
            <When condition={isOpen}>
               <Switch>
                    <Case condition={type === PinType.DEVICE}>
                        <InfoCam position={position} device={rest} onClose={() => setIsOpen(false)} />
                    </Case>
                    <Case condition={[PinType.HIT, PinType.TOP].includes(type)}>
                        <Info position={position} cluster={cluster} onClose={() => setIsOpen(false)}/>
                    </Case>
                    <Default>
                        <InfoOther type={type} position={position} address={rest.address} onClose={() => setIsOpen(false)}/>
                    </Default>
                </Switch>
            </When>
        </>
    )
  }