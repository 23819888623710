import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input, Col } from 'reactstrap';

export default class extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { value };
    const crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    const [randomValue] = crypto.getRandomValues(array);
    this.id = 'go-to-' + randomValue;
  }

  static propTypes = {
    value: PropTypes.number.isRequired,
    onBlur: PropTypes.func.isRequired
  }

  onChange =
    ({ target: { value } }) => {
      const intValue = parseInt(value, 10);
      this.setState({ value: !isNaN(intValue) ? intValue : 0 });
    }

  onBlur =
    () =>
      this.setState(({ value }) => {
        if (value < 1) {
          value = 1;
        }
        this.props.onBlur(value);
        return { value };
      });

  onKeyPress =
    event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.onBlur();
      }
    }

  render = () => (
    <Form className="float-right">
      <FormGroup row>
        <Label for={this.id} xs="4">Página:&nbsp;</Label>
        <Col xs="8">
          <Input id={this.id} onChange={this.onChange} onKeyPress={this.onKeyPress}
            value={this.state.value} onBlur={this.onBlur} />
        </Col>
      </FormGroup>
    </Form>
  );
}