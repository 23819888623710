import qs from "qs";
import { onlyNumbers } from "../utils/format";
import Api, { downloadByLink } from "./Api";

export default class extends Api {
    constructor(){
        super(process.env.REACT_APP_TRACKING_ENDPOINT)
    }

    export(
        plate,
        company,
        cpf,
        cnpj,
        chassi,
        spotlight,
        requested,
        lastCapture,
        sinistro,
        frauds,
        order,
        sort
    ) {
        let filter = qs.stringify({
            companyUuid: company,
            startLastCaptureDate: lastCapture.start,
            finishLastCaptureDate: lastCapture.finish,
            startRequestDate: requested.start,
            finishRequestDate: requested.finish,
            tagVehicle: spotlight.join(','),
            insuranceClaim: onlyNumbers(sinistro),
            frauds: frauds.join(','),
            cpf,
            cnpj,
            plate,
            chassi,
            order,
            sort
        }, {
            filter: (_, value) => value || undefined
        });
        downloadByLink(
            `${this.baseUrl}/export?${filter}`,
            `tracking_export_${new Date().getTime()}.csv`
        )
    }
}