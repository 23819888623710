import Api from "./Api";
import ndjson from 'fetch-ndjson'

export default class IssueGroupsApi extends Api {
  constructor() {
    super("/issue-group");
  }

  async getAllStream(queryString = '') {
    if (!!queryString && queryString.charAt(0) !== "?") {
      console.error("invalid queryString, must have ? at index 0")
      return;
    }
    const response = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/${this.baseUrl}${queryString}`, 
      {
        headers:
          {
            Authorization: "Bearer " + localStorage.getItem("TOKEN")
          }
      }
    )
    const groups = [];
    const reader = response.body.getReader();
    const readableStream = ndjson(reader);
    while (true) {
      let { done, value } = await readableStream.next();
      if (value) {
        groups.push(value);
      }
      if(done) {
        return groups;
      }
    }
  }
}