import React, { useState } from "react";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { Else, If, Then, When } from "react-if";
import { Collapse } from "reactstrap";

export default ({ children, isOpenDefault=true, showCollapseIcon=false, label="Filtros" }) => {
    const [isOpen, setIsOpen] = useState(isOpenDefault);
    return  (
        <>
            <When condition={showCollapseIcon}>
                <strong onClick={() => setIsOpen(value => !value)} style={{ textWrap: 'nowrap', marginBottom: "1rem" }}>
                    { label }&nbsp;
                    <If condition={isOpen}>
                        <Then>
                            <FaCaretDown/>
                        </Then>
                        <Else>
                            <FaCaretRight/>
                        </Else>
                    </If>
                </strong>
            </When>
            <Collapse isOpen={isOpen}>
                {children}
            </Collapse>
        </>
    )
}