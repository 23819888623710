import React from "react";
import { observer, inject } from "mobx-react";
import { FaSearch } from "react-icons/fa";
import { Button } from "reactstrap";

export default inject("UtilsStore")(
    observer(
        ({ onSearch, className, size, UtilsStore, disabled, block }) => {
            const search = async e => {
                e.preventDefault();
                try {
                    UtilsStore.setLoading(true);
                    await onSearch(e)
                } catch (err) {
                    throw err
                } finally {
                    UtilsStore.setLoading(false);
                }
            }
            return (
                <Button className={className} size={size} color="primary" onClick={search} disabled={disabled} block={block}>
                    <FaSearch/>&nbsp;Pesquisar
                </Button>
            )
        }
    )
)