import React, { memo } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { When } from "react-if";
import { AuthorizerStore } from "../stores";

export default memo(({ children }) => {
    return (
        <div style={{ height: "100vh", width: "100vw" }}>
            <Header />
            <When condition={AuthorizerStore.authenticated}>
                <Sidebar />
            </When>
            <main>{ children }</main>
        </div>
    )
})