import React from 'react';
import { isValidCpf, isValidCnpj, isValidCep } from 'docs-validator-br';
import { ufs } from 'utils';
import { onlyNumbers, isValidChassi, isValidEmail } from 'utils/format';

const ddd = [
    11, 12, 13, 14, 15, 16, 17, 18, 19,
    21, 22, 24, 27, 28, 31, 32, 33, 34,
    35, 37, 38, 41, 42, 43, 44, 45, 46,
    47, 48, 49, 51, 53, 54, 55, 61, 62,
    64, 63, 65, 66, 67, 68, 69, 71, 73,
    74, 75, 77, 79, 81, 82, 83, 84, 85,
    86, 87, 88, 89, 91, 92, 93, 94, 95,
    96, 97, 98, 99
];
const prefix = [2, 3, 4, 5, 7];

const enumValue = (name, label, validate, description) => Object.freeze({
    toString: () => name,
    label,
    validate,
    description
});

function validateLength(min=1, max=Number.MAX_SAFE_INTEGER){
    return (str) => str?.trim()?.length >= min && str?.trim()?.length <= max
}

function phoneValidate(phone) {
    phone = (phone||'').replaceAll(/\D*\(*\)*/g,"");
    if (!validateLength(10,11)(phone)) {
        return false;
    }
    //Se tiver 11 caracteres, verificar se começa com 9 o celular
    if (phone.length === 11 && parseInt(phone.charAt(2)) !== 9) {
        return false;
    }
    //verifica se o numero foi digitado com todos os dígitos iguais
    const find = phone.match(`${phone.charAt(0)}{${phone.length}}`)
    if(find) {
        return false;
    }
    if (!ddd.includes(parseInt(phone.substring(0, 2)))){
        return false;
    }
    //Se o número só tiver dez digitos não é um celular e por isso o número logo após o DDD deve ser 2, 3, 4, 5 ou 7
    return !(phone.length === 10 && prefix.includes(parseInt(phone.charAt(2))));
}

const descriptionChassi = (
    <>
        O chassi deve seguir a seguinte <a href="https://blog.pecahoje.com.br/numero-chassi/" rel="noopener noreferrer" target="_blank">regra de formatação</a>
    </>
)

export const PLATE = enumValue('PLATE', 'PLACA', plate => plate?.trim()?.length === 7 && plate?.match(/\w{3}\d[0-9A-z]\d{2}/), "O formato válido de uma PLACA é XXX9999 ou XXX9X99");
export const CPF = enumValue('CPF', 'CPF', isValidCpf, 'O formato válido de um CPF é 000.000.000-00 ou 00000000000');
export const CNPJ = enumValue('CNPJ', 'CNPJ', isValidCnpj, 'O formato válido de um CNPJ é 00.000.000/0001-00 ou 00000000000100');
export const DATE = enumValue('DATE', 'DATA', date => date?.match(/\d{1,2}\/\d{1,2}\/\d{2,4}|\d{4}-\d{1,2}-\d{1,2}/), 'O formato válido de uma data é dd/mm/aaaa ou aaaa-mm-dd');
export const TIME = enumValue('TIME', 'HORA', time => time?.match(/\d{1,2}(?::\d{2}){1,2}$/), 'O formato válido de uma hora é hh:mm ou hh:MM:ss');
export const DATETIME = enumValue('DATETIME', 'DATA HORA',  datetime => datetime?.match(/(\d{1,2}\/\d{1,2}\/\d{2,4}\s+|\d{4}-\d{1,2}-\d{1,2}T)\d{1,2}(?::\d{2}){1,2}$/), 'O formato válido de data e hora é dd/mm/aaaa hh:MM:ss ou aaaa-mm-ddThh:MM:ss com especificação de segundos opcional');
export const PHONE = enumValue('PHONE', 'TELEFONE', phoneValidate, "Telefones devem incluir o código DDD e conter 10 digitos para fixo e 11 digitos para celular iniciando com o digito 9 após o DDD");
export const DISTRICT = enumValue('DISTRICT', 'DISTRITO', validateLength(3, 30), "O nome do distrito deve ter entre 3 e 30 caracteres");
export const STREET = enumValue('STREET', 'LOGRADOURO', validateLength(3, 30), "O nome do logradouro deve ter entre 3 e 30 caracteres");
export const ADDRESS_NUMBER = enumValue('ADDRESS_NUMBER', 'NÚMERO DO ENDEREÇO', number => number === '' || !isNaN(number), "O Número do endereço deve conter somente digitos ou ser vazio");
export const CITY = enumValue('CITY', 'CIDADE', validateLength(3, 30), "O nome da cidade deve ter entre 3 e 30 caracteres");
export const STATE = enumValue('STATE', 'ESTADO', validateLength(4, 19), "O nome do estado deve ter entre 4 e 19 caracteres");
export const STATE_SGL = enumValue('STATE_SGL', 'SIGLA DO ESTADO', state => state?.length === 2 && ufs.includes(state), "A sigla do estado é composta por 2 letras");
export const POSTAL_CODE = enumValue('POSTAL_CODE', 'CEP', isValidCep, "O formato válido de um CEP é  88888-888 ou 88888888");
export const NUMERIC = enumValue('NUMERIC', 'NUMÉRICO', number => number !== '' && number !== null && !isNaN(number), "Valores númericos devem conter somente dígitos");
export const ALPHANUMERIC = enumValue('ALPHANUMERIC', 'TEXTO', (text, min, max) => validateLength(min, max)(text), "Verifique o tamanho máximo e minimo deste campo com o administrador");
export const ADDRESS = enumValue('ADDRESS', 'ENDEREÇO', () => true);
export const CHASSI = enumValue('CHASSI', 'CHASSI', isValidChassi, descriptionChassi);
export const MAIL = enumValue('MAIL', 'E-MAIL', isValidEmail, "E-mails devem conter @ em seu corpo");
export const MONEY = enumValue('REAL', 'MONETÁRIO', money => money?.match(/(?:^R\$\s*)?(?:\d{1,3}(?:\.\d{3})*|\d+)(?:,\d{2})?$/), "Valores Monetários deve ser formatados da seguinte maneira R$ 00,00 ou 00,00");
export const SINISTRO = enumValue('INSURANCE_CLAIM', 'SINISTRO', sinistro => validateLength(1, 255)(onlyNumbers(sinistro)), "Números/protocolos de Sinistro devem conter de 1 a 255 caracteres")
export const ENGINE_CODE = enumValue('ENGINE_CODE', 'NÚMERO DO MOTOR', engineCode => validateLength(1, 255)(onlyNumbers(engineCode)), "Número do motor deve conter de 1 a 255 caracteres")

export const values = () => [
    PLATE,
    CHASSI,
    CPF,
    CNPJ,
    DATE,
    TIME,
    DATETIME,
    PHONE,
    MAIL,
    STREET,
    ADDRESS_NUMBER,
    DISTRICT,
    CITY,
    STATE,
    STATE_SGL,
    POSTAL_CODE,
    MONEY,
    NUMERIC,
    ALPHANUMERIC,
    SINISTRO,
    ENGINE_CODE
]

export const addressValues = () => [
    STREET,
    DISTRICT,
    CITY,
    STATE,
    STATE_SGL,
    POSTAL_CODE,
    ADDRESS_NUMBER
];

export const clientDataValues = () => [
    DATE,
    TIME,
    DATETIME,
    PHONE,
    NUMERIC,
    ALPHANUMERIC,
    MAIL,
    MONEY,
    SINISTRO,
    ENGINE_CODE,
    ...addressValues()
];