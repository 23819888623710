const enumValue = (description, label) => Object.freeze({
    toString: () => description,
    label
});

export const CUSTOMER = enumValue('CUSTOMER', 'Cliente');
export const PROSPECTUS = enumValue('PROSPECTUS', 'Prospecção');

export const values = () => [CUSTOMER, PROSPECTUS];

export const find = type => values().find(({ toString }) => toString() === type);
