import React, { useEffect, useState } from "react";
import { createSliderWithTooltip, Range } from "rc-slider";
import moment from "moment";
import { Col, Row } from "reactstrap";

const CBDRange = createSliderWithTooltip(Range);

function calcSizeByRange(range){
    switch(range){
        case 's': return 1000;
        case 'm': return 60 * 1000;
        case 'h': return 60 * 60 * 1000;
        case 'd': return 24 * 60 * 60 * 1000;
        case 'y': return 365 * 24 * 60 * 60 * 1000;
        default: return 1
    }
}

const formatter = value => new Date(value).toLocaleString()

export default ({ onChange, values, max, min, step=1, range='m', placement='bottom' }) => {
    const [value, setValue] = useState([]);
    const [maxDate, setMaxDate] = useState(new Date(max));
    const [minDate, setMinDate] = useState(moment(new Date(min)).subtract(1, 'y').toDate());
    console.log(step);
    const size = calcSizeByRange(range);
    const onChangeWapper = values => onChange(values.map(v => new Date(v)));

    useEffect(() => {
        setValue([new Date(values[0]|| minDate).getTime(), new Date(values[1]|| maxDate).getTime()]);
    }, [minDate, maxDate, values]);

    useEffect(() => {
        const maxDate = moment(max).milliseconds(0).toDate();
        const minDate = moment(min || moment(new Date()).subtract(1, 'y')).milliseconds(0).toDate();
        setMaxDate(maxDate);
        setMinDate(minDate);
    }, [max, min]);
    return (
        <>
            <Row>
                <Col>
                    <CBDRange style={{ width: "95%", marginTop: 20, marginInline: 'auto' }} tipProps={{ placement, overlayStyle: { zIndex: 50 } }} tipFormatter={formatter} allowCross={false} min={minDate.getTime()} max={maxDate.getTime()} value={value} step={step*size} onChange={onChangeWapper} />
                </Col>
            </Row>
            <Row>
                <Col>{new Date(values[0]).toLocaleString()}</Col>
                <Col className="text-right">{new Date(values[1]).toLocaleString()}</Col>
            </Row>
        </>
    )
}