import Api, { get, save } from './Api';

export default class FeedbackApi extends Api {
  constructor() {
    super(process.env.REACT_APP_FEEDBACK_ENDPOINT);
  }

  async download(filename, filter) {
    const response = await get(`${this.baseUrl}/export?${filter}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "text/csv",
      },
    });
    save(response, filename, "text/csv");
  };
}