import Api from "./Api";


export default class CameraParameterApi extends Api {
  constructor() {
    super("/camera-parameter")
  }

  getByCompanyUuid = async (uuid) => {
    return this.get(`${uuid}`);
  }
}