import Api, { del, get, patch, post, put, download } from "./Api";

class TargetListApi extends Api {
  constructor() {
    super("/targetList");
  }

  upload = (params, config, company) =>
    post(`${this.baseUrl}?companyUuid=${company}`, params, config);

  download = async (id, filename, status) =>
    download(
      `${this.baseUrl}/${id}/download/${status}`,
      filename,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

  getTargets = (id, pageNumber, pageSize) =>
    get(
      `${this.baseUrl}/${id}/capture?page=${pageNumber || ""}&size=${pageSize ||
      ""}`
    );
  
  fetchAll = async (queryString) => {
    let contentToReturn = [];
    let page = 0;
    const size = 25;
    let total = 0;
    do {
      const {content, totalElements} = await this.fetch([queryString, `page=${page++}`, `size=${size}`].join("&"));
      total = totalElements
      contentToReturn = contentToReturn.concat(content);
    } while (contentToReturn.length < total);
    return contentToReturn;
  }

  getTargetsForDossier = (id, pageNumber, pageSize, sort, order, openFields) =>
    get(
      `${this.baseUrl}/${id}/target?page=${pageNumber || ""}&size=${pageSize ||
      ""}&sort=${sort || ""}&order=${order || ""}&openFields=${openFields ||
      ""}`
    );

  getAllDossierByTargetList = (id, filename) =>
    download(`${this.baseUrl}/${id}/dossie`, filename + ".csv", "text/csv");

  countTargetsToApproveDisapprove = (idTargetList, idTarget) =>
    get(`${this.baseUrl}/${idTargetList}/capture/${idTarget}/count`);

  countTargetsForDossier = (idTargetList) =>
    get(`${this.baseUrl}/${idTargetList}/target/count`);

  countTargetsForDossierPdf = (idTargetList, type) =>
    get(`${this.baseUrl}/${idTargetList}/dossie/count?exportType=${type}`);

  requestAllDossie = (idTargetList, enrich) =>
    post(`${this.baseUrl}/${idTargetList}/dossie?enrich=${enrich}`);

  requestAllDossiePdf = (idTargetList, type) =>
    post(`${this.baseUrl}/${idTargetList}/dossie/export?exportType=${type}`);

  match = (id, start, end) =>
    post(`${this.baseUrl}/${id}?startInterval=${start}&endInterval=${end}`);

  qualify = (id) => put(`${this.baseUrl}/${id}`);

  reset = (id) => patch(`${this.baseUrl}/${id}/reset`);

  getTargetToAnalize = (id) => del(`${this.baseUrl}/${id}/target`);
}

export default TargetListApi;