import { ReportStatusEnum, OrderEnum, SortReportEnum } from '../model';
import { observable, action, runInAction } from 'mobx';
import UtilsStore from './UtilsStore';
import { reportTemplateApi } from '../api';

class ReportStore {

    @observable
    reports = [];

    @observable
    size = 0;

    @observable
    pageSize = 10;

    @observable
    order = OrderEnum.DESC.toString();

    @observable
    sort = SortReportEnum.DATE_CREATED.toString();
    showReport = false;

    constructor(api){
        this.api = api;
    }

    @action
    setPageSize = value => this.pageSize = value;

    @action
    setOrder = value => this.order = value;

    @action
    setSort = value => this.sort = value;

    @action
    setShowReport = value => this.showReport = value;

    updateStatus = index => {
        const report = this.reports[index];
        this.api.getStatus(report.id).then(
            data => runInAction(
                () => {
                    this.reports[index] = { ...report, ...data };
                }
            )
        );
    }

    delete = index => {
        let report = this.reports[index];
        return this.api.delete(report.id);
    }

    @action
    processReport = index => {
        let report = this.reports[index];
        const oldStatus = report.status;
        const processingStatus = ReportStatusEnum.PROCESSING.toString();
        if (report.status !== processingStatus ){
            report.status = processingStatus;
            this.api.processReport(report.id)
                .catch(
                    () => runInAction(
                        () => report.status = oldStatus
                    )
                );
        }
    }

    @action
    fetch(page = 0) {
        UtilsStore.setLoading(true);
        const { order, sort } = this;
        this.api.fetch(`size=${this.pageSize}&page=${page}&order=${order}&sort=${sort}&showReport=${this.showReport}`)
            .then(this.success)
            .catch(err => {
                console.error(err);
                UtilsStore.setLoading(false);
            });
    }

    @action.bound
    success(data){
        const { content, size } = data;
        this.reports = content;
        this.size = size;
        UtilsStore.setLoading(false);
    }
}

const store = new ReportStore(reportTemplateApi);
export default store;