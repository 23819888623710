import React, { useState } from "react";
import { Button, Col, FormGroup, Label, Popover, PopoverBody, Row } from "reactstrap";
import Slider, { createSliderWithTooltip } from "rc-slider";
import { FaCaretRight, FaCaretUp } from "react-icons/fa";
import { Else, If, Then } from "react-if";
import { omit } from "lodash";

const TooltipSlider = createSliderWithTooltip(Slider)

export default ({ filters, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Col>
            <Button id="config-popover" color="primary" block onClick={() => setIsOpen(v => !v)}>
                Configurações
                <If condition={isOpen}>
                    <Then>
                        <FaCaretUp />
                    </Then>
                    <Else>
                        <FaCaretRight />
                    </Else>
                </If>
            </Button>
            <Popover className="w-100" isOpen={isOpen} target="config-popover" placement="top-end" hideArrow toggle={() => setIsOpen(v => !v)} >
                <PopoverBody>
                    {
                        Object.keys(omit(filters, ['url'])).map(
                            filter => (
                                <Row className="w-100">
                                    <Col key={filter}>
                                        <FormGroup>
                                            <Label for={filter}>
                                                <strong>{filter}</strong>
                                            </Label>
                                            <TooltipSlider id={filter} className="w-100" style={{ zIndex: 120 }} defaultValue={0} value={filters[filter]} onChange={value => onChange(filter, value)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )
                        )
                    }
                </PopoverBody>
            </Popover>
        </Col>
    )
}