import React from "react";
import { Card, CardBody, Col, Form, Row } from "reactstrap";
import PresetsSelect from "./PresetsSelect";
import * as Presets  from "./presetsEnum";
import { useImgFilter } from "./hooks";
import PresetFilters from "./presets";
import ManualFilters from "./ManualFilters";
import { When } from "react-if";

function getFilterStyle({ contrast, brightness, saturate, sepia, gray, invert, url }) {
    return `contrast(${contrast*2}%) brightness(${brightness*2}%) saturate(${saturate*2}%) sepia(${sepia}%) grayScale(${gray}%) invert(${invert}%) url(#${url})`
}

export default ({ children, show }) => {
    const [filters, setFilter, reset] = useImgFilter();
    const onChange = value => {
        reset();
        switch (value) {
            case Presets.NIGHT.value: {
                setFilter('brightness', 100);
                break;
            }
            case Presets.TEXT.value: {
                setFilter('url', 'lines');
                setFilter('contrast', 100);
                setFilter('saturate', 0);
                setFilter('brightness', 100);
                break;
            }
            case Presets.DAMAGE.value:{
                setFilter('url', 'damage');
                setFilter('contrast', 81);
                setFilter('brightness', 85);
                break;
            }
            default: break;
        }
    }

    return (
        <>
            <Row style={{ height: `calc(100% - ${show? 115: 0}px)`}}>
                <Col>
                    { children(getFilterStyle(filters)) }
                </Col>
            </Row>
            <When condition={show}>
                <Row>
                    <Col>
                        <Card className="w-100">
                            <CardBody>
                                <Form className="d-flex justify-content-center w-100">
                                    <Row className="w-100 text-justify text-center">
                                        <PresetsSelect onChange={onChange}/>
                                        <ManualFilters filters={filters} onChange={setFilter} />
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <PresetFilters/>
            </When>
        </>
    )
}