import Api, { post, download } from "./Api";

class DossieApi extends Api {
  constructor() {
    super("/dossie");
  }

  requestDossie = (id, enrich) =>
    post(`${this.baseUrl}?enrich=${enrich}`, { id });

  exportDossie = (id, type) =>
    post(`${this.baseUrl}/${id}/export?exportType=${type}`);

  download = async (id, type) =>
    download(
      `${this.baseUrl}/${id}/download?exportType=${type}`,
      `DOSSIE_${id}.pdf`,
      "application/pdf"
    );
}

export default DossieApi