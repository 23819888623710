import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { configure } from 'mobx';
import App from './App';
import './index.sass';
import './api/http';

if (process.env.NODE_ENV !== "development") {
  configure({
    enforceActions: "observed",
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
