import Api from "./Api";
import ndjson from 'fetch-ndjson';

class CameraApi extends Api {

  constructor() {
    super(process.env.REACT_APP_CAMERA_ENDPOINT);
  }

  getCameras = (page = 0, size = '', status = '', providers = []) =>
    this.fetch(`page=${page}&size=${size}&status=${status}${providers.length > 0 ? `&providers=${providers}` : ''} 
    `);

  getAllStream = async (queryParams) => {
    const response = (await fetch(`${process.env.REACT_APP_CAMERA_ENDPOINT}/stream?${new URLSearchParams(queryParams)}` ,
      {
        headers:
        {
          Authorization: "Bearer " + localStorage.getItem("TOKEN")
        }
      }
    ))
    const cameras = [];
    const reader = response.body.getReader();
    const readableStream = ndjson(reader);
    let endStream = false;
    while (!endStream) {
      let { done, value } = await readableStream.next();
      if (value) {
        cameras.push(value);
      }
      endStream = done;
    }
    return cameras;
  }
}

export default CameraApi;