import React, { useEffect, useRef, useState } from "react";
import { BounceLoader } from "react-spinners";
import styles from "./style.module.sass"
import { When } from "react-if";

export default ({ initialFetch, fetchMore, isLoading, hasMore }) => {
    const containerRef = useRef();
    const [page, setPage] = useState(0);
    
    const initialFetchWapper = async () => {
        await initialFetch();
        setPage(1);
    }

    useEffect(() => {
        initialFetchWapper()
    }, []);
    
    useEffect(() => {
        if (isLoading || !hasMore) {
            return;
        }
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };        

        const observer = new IntersectionObserver(async ([entry]) => {
            if (entry.isIntersecting) {
                observer.disconnect();
                await fetchMore(page);
                setPage(page + 1);
            }
        }, options);
        observer.observe(containerRef.current);

        return () => {
            observer.disconnect();
        };
    }, [isLoading, page]);

    return (
        <>
            <When condition={isLoading}>
                <div className={styles.loader}>
                    <BounceLoader/>
                </div>
            </When>
            <div ref={containerRef}/>
        </>
    );
};