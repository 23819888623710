const enumValue = (label, value) => Object.freeze({ label, value });

export const NONE = enumValue('Padrão', 'NONE');
export const NIGHT = enumValue('Noite', 'NIGHT');
export const TEXT = enumValue('Textos', 'TEXT')
export const DAMAGE = enumValue('Avarias', 'DAMAGE')

export const values = () => [
    NONE,
    NIGHT,
    TEXT,
    DAMAGE
]