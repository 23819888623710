import React from "react"; 
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg">
        <filter id="lines">
            <feColorMatrix type="matrix" values="1 1 1  0 0 1 1 1  0 0 1 1 1  0 0  0 0 0 1 0" />
            <feConvolveMatrix order="3 3" preserveAlpha="true" divisor="1" bias="0.85" kernelMatrix="-1,-1,-1 -1,9,-1 -1,-1,-1"/>
            <fecolormatrix type="saturate" values="0" />
            <feComponentTransfer color-interpolation-filters="sRGB">
                <feFuncR type="table" tableValues="0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"/>
                <feFuncG type="table" tableValues="0.8,0.8,0.79,0.78,0.77,0.76,0.76,0.75,0.74,0.73,0.72,0.72,0.71,0.7,0.69,0.68,0.68,0.67,0.66,0.65,0.64,0.64,0.63,0.62,0.61,0.6,0.59,0.59,0.58,0.57,0.56,0.55,0.55,0.54,0.53,0.52,0.51,0.51,0.5,0.49,0.48,0.47,0.47,0.46,0.45,0.44,0.43,0.43,0.42,0.41,0.4,0.39,0.39,0.38,0.37,0.36,0.35,0.35,0.34,0.33,0.32,0.31,0.31,0.3,0.29,0.28,0.27,0.27,0.26,0.25,0.24,0.23,0.23,0.22,0.21,0.2,0.19,0.18,0.18,0.17,0.16,0.15,0.14,0.14,0.13,0.12,0.11,0.1,0.1,0.09,0.08,0.07,0.06,0.06,0.05,0.04,0.03,0.02,0.02,0.01,0"/>
                <feFuncB type="table" tableValues="0.91,0.9,0.89,0.88,0.87,0.86,0.86,0.85,0.84,0.83,0.82,0.81,0.8,0.79,0.78,0.77,0.76,0.76,0.75,0.74,0.73,0.72,0.71,0.7,0.69,0.68,0.67,0.66,0.66,0.65,0.64,0.63,0.62,0.61,0.6,0.59,0.58,0.57,0.56,0.55,0.55,0.54,0.53,0.52,0.51,0.5,0.49,0.48,0.47,0.46,0.45,0.45,0.44,0.43,0.42,0.41,0.4,0.39,0.38,0.37,0.36,0.35,0.35,0.34,0.33,0.32,0.31,0.3,0.29,0.28,0.27,0.26,0.25,0.25,0.24,0.23,0.22,0.21,0.2,0.19,0.18,0.17,0.16,0.15,0.15,0.14,0.13,0.12,0.11,0.1,0.09,0.08,0.07,0.06,0.05,0.05,0.04,0.03,0.02,0.01,0"/>
            </feComponentTransfer>
            <feComponentTransfer>
                <feFuncR type="discrete" tableValues=" 0 1"/>
                <feFuncG type="discrete" tableValues=" 0 1"/>
                <feFuncB type="discrete" tableValues=" 0 1"/>
            </feComponentTransfer>
        </filter>
        <filter id="damage">
            <fecolormatrix type="saturate" values="0" />
            <feComponentTransfer color-interpolation-filters="sRGB">
                <feFuncR type="table" tableValues="0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.9,0.89,0.87,0.86,0.84,0.83,0.82,0.8,0.79,0.77,0.76,0.74,0.73,0.72,0.7,0.69,0.67,0.66,0.64,0.63,0.62,0.6,0.59,0.57,0.56,0.54,0.53,0.52,0.5,0.49,0.47,0.46,0.44,0.43,0.42,0.4,0.39,0.37,0.36,0.34,0.33,0.31,0.3,0.29,0.27,0.26,0.24,0.23,0.21,0.2,0.19,0.17,0.16,0.14,0.13,0.11,0.1,0.09,0.07,0.06,0.04,0.03,0.01,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"/>
                <feFuncG type="table" tableValues="0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.49,0.49,0.48,0.47,0.46,0.45,0.45,0.44,0.43,0.42,0.41,0.41,0.4,0.39,0.38,0.37,0.37,0.36,0.35,0.34,0.33,0.33,0.32,0.31,0.3,0.29,0.29,0.28,0.27,0.26,0.25,0.25,0.24,0.23,0.22,0.22,0.21,0.2,0.19,0.18,0.18,0.17,0.16,0.15,0.14,0.14,0.13,0.12,0.11,0.1,0.1,0.09,0.08,0.07,0.06,0.06,0.05,0.04,0.03,0.02,0.02,0.01,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"/>
                <feFuncB type="table" tableValues="0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0.01,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"/>
            </feComponentTransfer>
        </filter>
    </svg>
)