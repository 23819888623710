const enumValue = description => Object.freeze({
    toString: () => description
});

export const UPLOADED = enumValue('UPLOADED');
export const IMPORTING = enumValue('IMPORTING');
export const IMPORT_ERROR = enumValue('IMPORT_ERROR');
export const IMPORTED = enumValue('IMPORTED');
export const MATCHING = enumValue('MATCHING');
export const MATCH_EXPORTING = enumValue('MATCH_EXPORTING');
export const MATCH_ERROR = enumValue('MATCH_ERROR');
export const MATCHED = enumValue('MATCHED');
export const QUALIFYING = enumValue('QUALIFYING');
export const QUALIFIED = enumValue('QUALIFIED');
export const CLASSIFYING = enumValue('CLASSIFYING');
export const CLASSIFIED = enumValue('CLASSIFIED');
export const CLASSIFY_ERROR = enumValue('CLASSIFY_ERROR');
export const EXPORTING = enumValue('EXPORTING');
export const EXPORTED = enumValue('EXPORTED');
export const EXPORT_ERROR = enumValue('EXPORT_ERROR');
export const QUALIFY_ERROR = enumValue('QUALIFY_ERROR');


const statusValue = (valid, error) => ({
    valid, error
});

export const values = () => [
    statusValue(UPLOADED),
    statusValue(IMPORTING),
    statusValue(IMPORTED, IMPORT_ERROR),
    statusValue(MATCHING),
    statusValue(MATCHED, MATCH_ERROR),
    statusValue(QUALIFYING),
    statusValue(QUALIFIED, QUALIFY_ERROR),
    statusValue(CLASSIFYING),
    statusValue(CLASSIFIED, CLASSIFY_ERROR),
    statusValue(EXPORTING),
    statusValue(EXPORTED, EXPORT_ERROR)

];

export const processingValues = () => [
    UPLOADED,
    IMPORTING,
    MATCHING,
    MATCH_EXPORTING,
    QUALIFYING,
    QUALIFIED,
    CLASSIFYING,
    EXPORTING
];

export const errorValues = () => [
    IMPORT_ERROR,
    MATCH_ERROR,
    QUALIFY_ERROR,
    CLASSIFY_ERROR,
    EXPORT_ERROR
];

export const statusDownloadValues = () => [
    MATCHED,
    QUALIFYING,
    EXPORTING,
    EXPORTED,
    QUALIFIED,
    QUALIFY_ERROR,
    EXPORT_ERROR,
    CLASSIFIED,
    CLASSIFY_ERROR
];

export const standbyValues = () => [
    IMPORTED,
    MATCHED,
    EXPORTED
];

export const allValues = () => [
    UPLOADED,
    IMPORTING,
    IMPORT_ERROR,
    IMPORTED,
    MATCHING,
    MATCH_ERROR,
    MATCHED,
    QUALIFYING,
    QUALIFY_ERROR,
    QUALIFIED,
    CLASSIFYING,
    CLASSIFIED,
    CLASSIFY_ERROR,
    EXPORTING,
    EXPORTED,
    EXPORT_ERROR
    
];