import Api, { download } from "./Api";

class ClonedApi extends Api {
  constructor() {
    super(process.env.REACT_APP_CLONED_ENDPOINT);
  }

  export = (filter) =>
    download(
        `${this.baseUrl}/export?${filter}`,
        `suspeitas_${new Date().getTime()}.csv`,
        'text/csv'
    );
}

export default ClonedApi
