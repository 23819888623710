const enumValue = (description, label) => Object.freeze({toString: () => description, label});

export const DONE = enumValue('DONE', 'Avaliado');
export const PENDING = enumValue('PENDING', 'Pendente de avaliação');
export const NOT_REQUESTED = enumValue('NOT_REQUESTED', 'Não pesquisado');

export const find = value => [
    DONE,
    PENDING,
    NOT_REQUESTED   
].find(status => status.toString() === value);