import React, { Component } from 'react';
import { Else, If, Then } from 'react-if';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

const getInitialState = ({ originalSrc }) => ({
  cropped: false,
  crop: {
    x: 0,
    y: 0
  },
  originalSize: {},
  src: '',
  originalSrc
});

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props);
  }

  static defaultProps = {
    style: {},
    imageStyle: {}
  };

  undoCrop = () => {
    if (this.state.cropped) {
      const state = getInitialState(this.props);
      this.setState(state);
    }
  };

  onCropChange = crop => {
    this.undoCrop();
    this.setState({ crop });
  };

  onCropComplete = async (crop, pixelCrop) => {
    if (this.imageRef && crop.width && crop.height && !this.state.cropped) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, pixelCrop);
      this.setState({ src: croppedImageUrl, cropped: true, crop: {} });
      const { onCropComplete } = this.props;
      onCropComplete && onCropComplete(crop, pixelCrop);
    }
  };

  getCroppedImg(image, pixelCrop) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    try {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
      );
    } catch (e) {
      console.log(this.state);
      console.error(e);
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        setTimeout(() => canvas.remove(), 100)
        if (!blob) {
          reject('Canvas is empty');
        } else {
          blob.name = 'newFile.jpeg';
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        }
      }, 'image/jpeg');
    });
  }

  onImageLoaded = image => {
    const { onImageLoaded } = this.props;
    this.imageRef = image;
    const { naturalHeight: height, naturalWidth: width } = image;
    this.setState({
      originalSize: {
        height,
        width
      }
    });
    onImageLoaded && onImageLoaded(image);
  }

  render() {
    const { src, crop, originalSrc, originalSize: { width } } = this.state;
    let { style, imageStyle } = this.props;
    const defaultStyle = {
      maxWidth: width, display: "flex", alignItems: "center",
      justifyContent: "center", backgroundColor: "transparent", margin: "auto"
    };
    if (!!src) {
      defaultStyle['minWidth'] = '1000px';
    }
    return (
      <If condition={!src}>
        <Then>
          <ReactCrop crop={crop} onChange={this.onCropChange} onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete} style={{ ...defaultStyle, ...style }}
            src={originalSrc} imageStyle={{ ...defaultStyle, ...imageStyle, zIndex: 0 }} alt="Image cropper" />
        </Then>
        <Else>
          <img style={{ ...defaultStyle, ...imageStyle, zIndex: 100 }} onClick={() => this.setState(getInitialState(this.props))} src={src} alt="Cropped" />
        </Else>
      </If>
    );
  }
}
