import React, { useEffect, useState } from 'react';
import { Col, Row, Nav, NavItem, NavLink, TabContent, Modal, ModalBody } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import classnames from 'classnames';
import { When } from 'react-if';
import TabProfile from './TabProfile';
import TabPassword from './TabPassword';
import { getCurrentUser } from 'utils';
import './style.sass';

export default ( { isOpen, toggleProfile } ) => {
    const [ activeTab, setActiveTab ] = useState('1')
    const [ fullName, setFullName ] = useState(localStorage.getItem('USER'));
    const [ loading, setLoading ] = useState(false);
    const { Email: user, Role: roles, exp, iat } = getCurrentUser();
    const tokenTimeExpiration = (exp - iat) * 1000;
    const isMobile = window.innerWidth <= 768;
    const loadCallback = isLoading => setLoading(isLoading);
    const toggle = tab => activeTab !== tab && setActiveTab(tab);
    const onUpdate = () => setFullName(localStorage.getItem('USER'));
    const wrapperToggle = () => {
        if(!loading){
            toggleProfile();
            toggle('1');
        }
    };
    
    useEffect(() => { isOpen && setFullName(localStorage.getItem('USER')) }, [isOpen]);

    return (
        <When condition={isOpen}>
            <Modal { ...{ isOpen } } toggle={wrapperToggle} style={ { maxWidth: `${isMobile? 100 : 50}%` } }>
                <LoadingOverlay active={loading} fadeSpeed={200} spinner styles={{ wrapper: base => ({...base, height: 'auto !important'})}}>
                    <ModalBody>
                        <Row>
                            <Col md="3" style={{ borderRight: '1px solid #ced4da'}}>
                                <Nav vertical={!isMobile} tabs style={!isMobile ? { borderBottom: '0'}: { marginBottom: '5px' }}>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>
                                            Editar perfil
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                            Alterar senha
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col>        
                                <h2>{fullName}</h2>
                                <TabContent activeTab={activeTab}>
                                    <TabProfile { ...{ fullName, tokenTimeExpiration, roles, user, onUpdate, loadCallback, close: wrapperToggle } }/>
                                    <TabPassword { ...{ user, loadCallback, close: wrapperToggle } }/>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
            </LoadingOverlay>
            </Modal>
        </When>
    );
}