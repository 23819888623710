import React from "react";
import { ResponsiveWrapper } from "@nivo/core";
import Mixed from "./chart";

const ResponsiveMixed = (props) => (
  <ResponsiveWrapper>
    {({ width, height }) => <Mixed width={width} height={height} {...props} />}
  </ResponsiveWrapper>
);

export default ResponsiveMixed;
