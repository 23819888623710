import { useState } from "react";

export function useImgFilter(){
    const [contrast, setContrast] = useState(50);
    const [brightness, setBrightness] = useState(50);
    const [saturate, setSaturate] = useState(50);
    const [sepia, setSepia] = useState(0);
    const [gray, setGray] = useState(0);
    const [invert, setInvert] = useState(0);
    const [url, setUrl] = useState('');
    const setters = {
        contrast: setContrast,
        brightness: setBrightness,
        saturate: setSaturate,
        sepia: setSepia,
        gray: setGray,
        invert: setInvert,
        url: setUrl
    } 
    const reset = () => {
        setContrast(50);
        setBrightness(50);
        setSaturate(50);
        setSepia(0);
        setGray(0);
        setInvert(0);
        setUrl('');
    }
    return [{
        contrast,
        brightness,
        saturate,
        sepia,
        gray,
        invert,
        url
    },
    (filter, value) => setters[filter](value),
    reset]
}