import { observable, action, computed } from 'mobx';
import UtilsStore from './UtilsStore';
import { agentApi } from '../api';

class Filter{
    @observable
    city = '';
    @observable
    agent = '';
}

class CameraStore{

    @observable
    cameras = [];

    filter = new Filter()

    constructor(api){
        this.api = api;
    }

    @computed
    get agent() {
        return this.filter.agent
    }

    @action
    setAgent = agent => this.filter.agent = agent;

    @computed
    get city(){
        return this.filter.city;
    }

    @action
    setCity = city => this.filter.city = city;

    @action
    reset(){
        this.setCity('');
        this.setAgent('');
        this.cameras = [];
    }

    @action
    fetch(){
        UtilsStore.setLoading(true);
        this.api.fetch()
            .then(this.success)
            .catch(err => {
                console.error(err);
                UtilsStore.setLoading(false);
            });
    }

    @action.bound
    success(data){
        this.cameras = data.map(
            ({
                cameras,
                captureType: agent
            }) => 
                cameras.map(
                    ({
                        codCamera,
                        address: {
                            latitude: lat,
                            longitude: lng,
                            city
                        },
                        url
                    }) => ({
                        id: codCamera,
                        lat, lng, city,
                        agent, url
                    })
                )
        ).reduce(
            (coordinatesMatrix, coordinates) => coordinates.concat(coordinatesMatrix)
        );
        UtilsStore.setLoading(false);
    }
}

const store = new CameraStore(agentApi);
export default store;