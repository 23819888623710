import React, { Component } from 'react';
import { Row, Col, Table as TableStrap } from 'reactstrap';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';
import { Else, If, Then } from 'react-if';
import PropTypes from 'prop-types';
import { OrderEnum } from '../../model';
import styles from './style.module.sass';


class OrderHeader extends Component{
    constructor(props){
        super(props);
        this.state = {
            order: props.initialOrder
        };
        this.style = {
            cursor: props.ordained ? 'pointer': 'default',
            ...props.style
        };
    }

    static propTypes = {
        initialOrder: PropTypes.string,
        ordained: PropTypes.bool,
        onClick: PropTypes.func,
        style: PropTypes.object
    };

    static defaultProps = {
        initialOrder: OrderEnum.NO_ORDER.toString(),
        ordained: false
    };

    componentDidUpdate(){
        const { afterUpdate } = this.props;
        afterUpdate && afterUpdate.bind(this)();
    }
    
    order(){
        const { ordained } = this.props;
        if (ordained){
            const { order } = this.state;
            const { ASC, DESC } = OrderEnum;
            switch (order) {
                case ASC.toString(): return <FaSortUp/>;
                case DESC.toString(): return <FaSortDown/>;
                default: return null;
            }
        }
        return null;
    }

    onClick = 
        () => {
            const order = OrderEnum.getNext(this.state.order);
            const { ordained, onClick, sortColumn } = this.props;
            if(ordained){
                onClick(order, sortColumn);
                this.setState({ order });
            }
        };

    render(){
        const { title, className} = this.props;
        return (
            <th className={className} onClick={this.onClick} style={this.style}> {title} {this.order()}</th>
        )
    }
}

const Table = ({ headers, children, striped, loading, height='', style={} }) => (
    <Row>
        <Col className={styles['table-wapper']} style={{ ...style, height }}>
            <TableStrap striped={striped} className={styles.table}>
                <thead>
                    <tr className="text-center">
                        {headers.map((header, index) => <OrderHeader key={index} {...header}/>) }
                    </tr>
                </thead>
                <tbody>
                    <If condition={loading}>
                        <Then>
                            <tr className="text-center">
                                <td colSpan={headers.length}>
                                    <PulseLoader />
                                </td>
                            </tr>
                        </Then>
                        <Else>
                            <If condition={children?.length}>
                                <Then>{children}</Then>
                                <Else>
                                    <tr className="text-center">
                                        <td colSpan={headers.length}>
                                            <b>Nenhum registro encontrado</b>
                                        </td>
                                    </tr>
                                </Else>
                            </If>
                        </Else>
                    </If>
                </tbody>
            </TableStrap>
        </Col>
    </Row>
);

Table.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object).isRequired,
    children: PropTypes.arrayOf(PropTypes.node),
    striped: PropTypes.bool
};

export default Table;