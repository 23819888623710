import Api, { get, patch } from "./Api";

class TargetApi extends Api {
  constructor() {
    super("/target");
  }

  getCaptures = (id, page, groupBy) =>
    get(
      `${this.baseUrl}/${id}/capture?page=${page}&size=18&groupBy=${groupBy}`
    );

  qualify = (id, captures) => patch(`${this.baseUrl}/${id}/qualify`, captures);
}

export default TargetApi