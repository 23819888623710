import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import './dateTimePicker.sass'

export default ({ timePick, onChangeTime, labelText, max, min, disabled, invalid}) => (
    <FormGroup className={`time-input ${invalid && "is-invalid"}`} style={disabled ? {backgroundColor: '#E3E3E3'}: {}}>
        <Label for="time-initial" style={{ color: '#6C757D', marginRight: "0.25rem" }}>
            { labelText }
        </Label>
        <input type="time" value={timePick} max={max} min={min} onChange={onChangeTime} style={{ border: 0 }} disabled={disabled} />
    </FormGroup>
);
