import React, { memo, useCallback, useEffect } from "react";
import { Col, FormGroup, FormText, Input, Label } from "reactstrap";
import { Else, If, Then } from "react-if";
import { useFeedbackModalContext } from "./Context";
import Feedback from "./Feedback";
import Inspect from "./Inspect";

export default memo(function ({ isInspector, feedback, hasError }) {
    const [{ insuranceClaim, note }, { setFeedback, setNote }] = useFeedbackModalContext();
    const changeNote = useCallback(({ target: { value } }) => setNote(value), []);
    useEffect(() => { setFeedback(feedback)}, [feedback?.id]);

    return (
        <>
            <FormGroup row>
                <Label md="3">
                    <b>Sinitro</b>
                </Label>
                <Col>
                    { insuranceClaim || '--'}
                </Col>
            </FormGroup>
            <If condition={!isInspector}>
                <Then>
                    <Feedback hasError={hasError}/>
                </Then>
                <Else>
                    <Inspect hasError={hasError}/>
                </Else>
            </If>
            <FormGroup>
              <Label for="note" className="font-weight-bold">
                Observação
              </Label>
              <Input type="textarea" id="note" value={note} rows="6" style={{ resize: "none" }} onChange={changeNote}/>
              <FormText color="muted" className="float-right">
                {note?.length || 0}/10.000
              </FormText>
            </FormGroup>
        </>
    )
}, (prev, next) => prev?.id === next?.id && prev?.hasError === next?.hasError);