import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { FaTimes, FaPlusCircle } from "react-icons/fa";
import ProgressBar from "./ProgressBar";
import DropZone from "react-dropzone";
import axios from "axios";
import "./drop.sass";
import BarLoader from "react-spinners/BarLoader";
import { css } from '@emotion/react'
import classnames from "classnames";
import { Else, If, Then, When } from "react-if";

const startState = () => ({
  isTop: true,
  requests: [],
  files: [],
});

export default class Drop extends Component {
  state = startState();

  constructor(props) {
    super(props);
    this.props = props;
  }

  static  defaultProps = {
    icon: FaPlusCircle,
    color: 'primary',
    label: 'Novo'
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => this.setState({ isTop: window.scrollY <= 100 });

  onUpload = (files, args) => {
    const requests = this.props.onUpload(files, (index) => (newProgress) => {
      this.setState(({ requests }) => {
        requests[index].progress = Math.floor(
          (newProgress.loaded * 100) / newProgress.total
        );
        return { requests };
      });
    }, args);
    requests.forEach((req, index) => {
      req.request
        .then(() => this.onClean(index))
        .catch(() => !axios.isCancel(req.request) && (req.progress = "Error"));
    });
    this.setState({ requests });
  };

  onDrop = (files) => {
    this.setState({ files }, () => {
      const { immediate, onDrop } = this.props;
      if (immediate) {
        this.onUpload(this.state.files);
      } else {
        onDrop(this.state.files);
      }
    });
  };

  onCancel = (index) => {
    this.setState(({ requests }) => {
      requests[index].cancel();
      return { requests };
    });
    this.onClean(index);
  };

  onClean = (index) => {
    this.setState(({ requests }) => {
      requests[index] = null;
      return {
        requests: !!requests.filter((request) => !!request).length
          ? requests
          : [],
      };
    });
    if (this.state.requests.length === 0 && this.props.onFinish) {
      this.props.onFinish();
    }
  }

  onCancelAll = () =>
    this.setState(({ requests }) => {
      requests.forEach(
        (request) => !axios.isCancel(request) && request.cancel()
      );
      return startState();
    });

  render() {
    const { requests, isTop, files } = this.state;
    const { accept, className, component: Comp, icon: Icon, disabled, onDropRejected, color, id } = this.props;
    return (
      <>
        <div className={classnames(['file-list', { 'd-none': !(!!requests.length && !isTop) }])}>
          <div>
            <Row className="w-100">
              <Col>
                <FaTimes cursor="pointer" onClick={this.onCancelAll} />
              </Col>
            </Row>
          </div>
          <div>
            {requests.map((request, index) => (
              <When condition={!!request} >
                <ProgressBar name={request?.file?.name} progress={request?.progress} onCancel={() => this.onCancel(index)} onClean={() => this.onClean(index)}/>
              </When>
            ))}
          </div>
        </div>
        <DropZone
          onDropAccepted={this.onDrop}
          accept={accept}
          maxFiles={this.props.maxFiles || 0}
          onDropRejected={onDropRejected}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
          }) => (
            <div
              id={id}
              {...getRootProps()}
              className={classnames(['drop-zone', 'w-100', 'btn', `btn-${color}`, className, {
                'reject': isDragReject,
                'active': isDragActive,
                disabled
              }])} style={{cursor: disabled ? "default" : "pointer"}}
            >
              <input {...getInputProps()} disabled={disabled} />
              <Icon />&nbsp;
              {this.props.label}
            </div>
          )}
        </DropZone>
        <Row>
          {requests.map((request, index) => (
            <If condition={!!request} key={index}>
              <Then>
                <ProgressBar name={request?.file?.name} progress={request?.progress} onCancel={() => this.onCancel(index)} onClean={() => this.onClean(index)}/>
              </Then>
              <Else>
                <Col xs="12" className="d-flex align-items-center justify-content-center pb-2" style={{ minHeight: 55 }}>
                  <span className="pr-2">Aguarde...</span>&nbsp;
                  <BarLoader height={15} color={"#007BFF"} css={css`width:100%`} />
                </Col>
              </Else>
            </If>
          ))}
        </Row>
        {!!Comp && (
          <Comp onConfirm={args => this.onUpload(files, args)} files={files} />
        )}
      </>
    );
  }
}
