import Api, { get } from "./Api";

export default class KeyAuthApi extends Api {
  constructor(){
    super((process.env.REACT_APP_AUTH_ENDPOINT || "") + "/key-auth");
  }

  getModules(){
    return get(`${this.baseUrl}/modules`);
  }
}