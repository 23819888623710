/*
 * This file is part of the nivo project.
 *
 * Copyright 2016-present, Raphaël Benitte.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { Fragment, useState } from "react";
import { TransitionMotion, spring } from "react-motion";
import {
  bindDefs,
  LegacyContainer,
  SvgWrapper,
  CartesianMarkers,
  useDimensions,
} from "@nivo/core";
import { useInheritedColor } from "@nivo/colors";
import { Axes, Grid } from "@nivo/axes";
import { BoxLegendSvg } from "@nivo/legends";
import { useLine } from "@nivo/line";
import { Lines, Points, Mesh } from "./line";
// import { useLine } from "./line/hooks";
import { generateStackedBars, getLegendData } from "./bar/compute";
import setDisplayName from "recompose/setDisplayName";
import enhance from "./enhance";
import { MixedDefaultProps } from "./props";
import BarAnnotations from "./bar/BarAnnotations";

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const barWillEnterVertical = ({ style }) => ({
  x: style.x.val,
  y: style.y.val + style.height.val,
  width: style.width.val,
  height: 0,
});

const barWillLeaveVertical = (springConfig) => ({ style }) => ({
  x: style.x,
  y: spring(style.y.val + style.height.val, springConfig),
  width: style.width,
  height: spring(0, springConfig),
});

const Mixed = (props) => {
  const {
    data,
    getIndex,
    keys,

    groupMode,
    layout,
    reverse,
    minValue,
    maxValue,

    valueScale,
    indexScale,

    margin,
    outerWidth,
    outerHeight,
    width,
    height,
    padding,
    innerPadding,

    axisTop,
    axisRight,
    axisBottom,
    axisLeft,
    enableGridX,
    enableGridY,
    gridXValues,
    gridYValues,

    layers,
    barComponent,

    enableLabel,
    getLabel,
    labelSkipWidth,
    labelSkipHeight,
    getLabelTextColor,

    markers,

    theme,
    getColor,
    defs,
    fill,
    borderRadius,
    borderWidth,
    getBorderColor,

    annotations,

    isInteractive,
    getTooltipLabel,
    tooltip,
    onClick,
    onMouseEnter,
    onMouseLeave,

    legends,

    animate,
    motionStiffness,
    motionDamping,

    role,
    xScale: xScaleSpec,
    xFormat,
    yScale: yScaleSpec,
    curve,

    colors,

    lineWidth,

    enablePoints,
    pointSymbol,
    pointColor,
    pointBorderColor,
    pointLabel,
    pointLabelYOffset,

    useMesh,
    debugMesh,
  } = props;

  const result = generateStackedBars({
    layout,
    reverse,
    data: data.filter(({ type }) => type === "bar"),
    getIndex,
    keys,
    minValue,
    maxValue,
    width,
    height,
    getColor,
    padding,
    innerPadding,
    valueScale,
    indexScale,
  });

  const motionProps = {
    animate,
    motionDamping,
    motionStiffness,
  };

  const springConfig = {
    damping: motionDamping,
    stiffness: motionStiffness,
  };

  const willEnter = barWillEnterVertical;
  const willLeave = barWillLeaveVertical(springConfig);

  const shouldRenderLabel = ({ width, height }) => {
    if (!enableLabel) return false;
    if (labelSkipWidth > 0 && width < labelSkipWidth) return false;
    if (labelSkipHeight > 0 && height < labelSkipHeight) return false;
    return true;
  };

  const boundDefs = bindDefs(defs, result.bars, fill, {
    dataKey: "data",
    targetKey: "data.fill",
  });

  margin.left = 90;

  const {
    innerWidth: lineInnerWidth,
    outerWidth: lineOuterWidth,
    outerHeight: lineOuterHeight,
  } = useDimensions(width, height, margin);

  const lines = data.filter(({ type }) => type === "line");

  const { lineGenerator, series, points } = useLine({
    data: lines,
    xScale: xScaleSpec,
    xFormat,
    yScale: yScaleSpec,
    yFormat: (y) => formatter.format(y),
    width:
      ((lineInnerWidth + lineOuterWidth) / 2 + lineOuterWidth) / 2 -
      (30 - (lines[0]?.data?.length || 0)),
    height: lineOuterHeight,
    colors,
    curve,
    pointColor,
    pointBorderColor,
    enableSlices: false,
    theme,
  });

  axisBottom.tickSize = 0;
  axisBottom.tickRotation = 45;
  axisBottom.legendOffset = 40;

  axisLeft.legendOffset = -55;
  axisLeft.format = (v) => formatter.format(v);

  const getPointColor = useInheritedColor(pointColor, theme);
  const getPointBorderColor = useInheritedColor(pointBorderColor, theme);
  const [currentPoint, setCurrentPoint] = useState(null);

  return (
    <LegacyContainer
      isInteractive={isInteractive}
      theme={theme}
      animate={animate}
      motionStiffness={motionStiffness}
      motionDamping={motionDamping}
    >
      {({ showTooltip, hideTooltip }) => {
        const commonProps = {
          borderRadius,
          borderWidth,
          enableLabel,
          labelSkipWidth,
          labelSkipHeight,
          showTooltip,
          hideTooltip,
          onClick,
          onMouseEnter,
          onMouseLeave,
          theme,
          getTooltipLabel,
          tooltipFormat: (v) => formatter.format(v),
          tooltip,
        };

        let bars;
        if (animate === true) {
          bars = (
            <TransitionMotion
              key="bars"
              willEnter={willEnter}
              willLeave={willLeave}
              styles={result.bars
                .filter((bar) => bar.data.value !== null)
                .map((bar) => ({
                  key: bar.key,
                  data: bar,
                  style: {
                    x: spring(bar.x, springConfig),
                    y: spring(bar.y, springConfig),
                    width: spring(bar.width, springConfig),
                    height: spring(bar.height, springConfig),
                  },
                }))}
            >
              {(interpolatedStyles) => (
                <g>
                  {interpolatedStyles.map(({ key, style, data: bar }) => {
                    const baseProps = { ...bar, ...style };

                    return React.createElement(barComponent, {
                      key,
                      ...baseProps,
                      ...commonProps,
                      shouldRenderLabel: shouldRenderLabel(baseProps),
                      width: Math.max(style.width, 0),
                      height: Math.max(style.height, 0),
                      label: formatter.format(getLabel(bar.data)),
                      labelColor: getLabelTextColor(baseProps, theme),
                      borderColor: getBorderColor(baseProps),
                      theme,
                    });
                  })}
                </g>
              )}
            </TransitionMotion>
          );
        } else {
          bars = result.bars
            .filter((bar) => bar.data.value !== null)
            .map((d) =>
              React.createElement(barComponent, {
                key: d.key,
                ...d,
                ...commonProps,
                label: formatter.format(getLabel(d.data)),
                shouldRenderLabel: shouldRenderLabel(d),
                labelColor: getLabelTextColor(d, theme),
                borderColor: getBorderColor(d),
                theme,
              })
            );
        }

        const layerById = {
          grid: (
            <Grid
              key="grid"
              width={width}
              height={height}
              xScale={enableGridX ? result.xScale : null}
              yScale={enableGridY ? result.yScale : null}
              xValues={gridXValues}
              yValues={gridYValues}
            />
          ),
          axes: (
            <Axes
              key="axes"
              xScale={result.xScale}
              yScale={result.yScale}
              width={width}
              height={height}
              top={axisTop}
              right={axisRight}
              bottom={axisBottom}
              left={axisLeft}
            />
          ),
          bars,
          lines: (
            <Lines
              key="lines"
              lines={series}
              lineGenerator={lineGenerator}
              lineWidth={lineWidth}
            />
          ),
          points: null,
          mesh: null,
          markers: (
            <CartesianMarkers
              key="markers"
              markers={markers}
              width={width}
              height={height}
              xScale={result.xScale}
              yScale={result.yScale}
              theme={theme}
            />
          ),
          legends: legends.map((legend, i) => {
            const legendData = getLegendData({
              from: legend.dataFrom,
              bars: result.bars,
              layout,
              direction: legend.direction,
              groupMode,
              reverse,
            });

            if (legendData === undefined) return null;

            return (
              <BoxLegendSvg
                key={i}
                {...legend}
                containerWidth={width}
                containerHeight={height}
                data={legendData}
                theme={theme}
              />
            );
          }),
          annotations: (
            <BarAnnotations
              key="annotations"
              innerWidth={width}
              innerHeight={height}
              bars={result.bars}
              annotations={annotations}
              {...motionProps}
            />
          ),
        };

        if (enablePoints) {
          layerById.points = (
            <Points
              key="points"
              points={points}
              symbol={pointSymbol}
              size={10}
              color={getPointColor}
              borderWidth={2}
              borderColor={getPointBorderColor}
              enableLabel={points.length <= 10}
              label={pointLabel}
              labelYOffset={pointLabelYOffset}
            />
          );
        }
        if (isInteractive && useMesh) {
          layerById.mesh = (
            <Mesh
              key="mesh"
              points={points}
              width={lineOuterWidth}
              height={lineOuterHeight}
              margin={margin}
              current={currentPoint}
              setCurrent={setCurrentPoint}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={onClick}
              tooltip={tooltip}
              debug={debugMesh}
            />
          );
        }
        return (
          <SvgWrapper
            width={outerWidth}
            height={outerHeight}
            margin={margin}
            defs={boundDefs}
            theme={theme}
            role={role}
          >
            {layers.map((layer, i) => {
              if (typeof layer === "function") {
                return (
                  <Fragment key={i}>
                    {layer({
                      ...props,
                      ...result,
                      showTooltip,
                      hideTooltip,
                      currentPoint,
                      setCurrentPoint,
                    })}
                  </Fragment>
                );
              }
              return layerById[layer];
            })}
          </SvgWrapper>
        );
      }}
    </LegacyContainer>
  );
};
Mixed.defaultProps = MixedDefaultProps;

export default setDisplayName("Mixed")(enhance(Mixed));
