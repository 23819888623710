import { BarItem } from "@nivo/bar";
import { noop } from "@nivo/core";

import PointTooltip from "./line/PointTooltip";

export const MixedDefaultProps = {
  curve: "linear",

  xScale: {
    type: "point",
  },
  yScale: {
    type: "linear",
    min: 0,
    max: "auto",
  },
  indexBy: "id",
  keys: ["value"],
  layers: [
    "grid",
    "axes",
    "mesh",
    "bars",
    "lines",
    "points",
    "markers",
    "legends",
    "annotations",
  ],

  groupMode: "stacked",
  layout: "vertical",
  reverse: false,

  minValue: "auto",
  maxValue: "auto",

  valueScale: { type: "linear" },
  indexScale: { type: "band", round: true },

  padding: 0.1,
  innerPadding: 0,

  axisBottom: {
    tickRotation: 90,
  },
  axisLeft: {},
  enableGridX: true,
  enableGridY: true,

  barComponent: BarItem,

  enableLabel: true,
  label: "value",
  labelSkipWidth: 0,
  labelSkipHeight: 0,
  labelLinkColor: "theme",
  labelTextColor: "theme",

  enablePoints: true,
  pointSize: 6,
  pointColor: { from: "color" },
  pointBorderWidth: 0,
  pointBorderColor: { theme: "background" },
  enablePointLabel: false,
  pointLabel: "yFormatted",

  colors: { scheme: "nivo" },
  colorBy: "id",
  defs: [],
  fill: [],
  borderRadius: 0,
  borderWidth: 0,
  borderColor: { from: "color" },

  enableArea: false,
  areaBaselineValue: 0,
  areaOpacity: 0.2,
  areaBlendMode: "normal",
  lineWidth: 2,

  isInteractive: true,
  onClick: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  tooltip: PointTooltip,
  debugSlices: false,
  debugMesh: false,
  enableCrosshair: true,
  crosshairType: "bottom-left",

  useMesh: false,
  animate: true,
  motionConfig: "gentle",

  legends: [],

  annotations: [],

  pixelRatio:
    global.window && global.window.devicePixelRatio
      ? global.window.devicePixelRatio
      : 1,
  role: "img",
};
