import React from "react";
import InputMask from "react-input-mask";
import { Input } from "reactstrap";

export default ({ id, value, onChange, placeholder }) => (
    <InputMask
        id={id}
        mask="HHHHHHHH-HHHH-HHHH-HHHH-HHHHHHHHHHHH"
        formatChars={{H:"[0-9A-Fa-f]"}}
        value={value}
        alwaysShowMask={false}
        maskChar=""
        placeholder={placeholder}
        onChange={({ target: { value } }) => onChange(value)}>
        { inputProps => <Input {...inputProps}/> }
    </InputMask>
)