const orderEnumValue = description => Object.freeze({toString: () => description});

export const ASC = orderEnumValue('ASC');
export const DESC = orderEnumValue('DESC');
export const NO_ORDER = orderEnumValue('');

export function find(order){
    return [
        ASC,
        DESC,
        NO_ORDER
    ].find(o => o.toString() === order)
}

export function getNext(current){
    switch (current) {
        case ASC.toString(): return DESC.toString();
        case DESC.toString(): return ASC.toString();
        case NO_ORDER.toString(): return ASC.toString();
        default: return NO_ORDER.toString();
    }
};