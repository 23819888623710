import React, { memo, useCallback, useEffect, useState } from "react";
import { Badge, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Else, If, Then, When } from "react-if";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import classnames from "classnames"
import uuid from "react-uuid";
import { adminItems, apiItems, productItems } from "./items";
import { getCurrentUser, isAllow } from "../utils";
import { AuthorizerStore } from "../stores";
import ProfilePage from "../pages/profile";
import { useMobile } from "../hooks";
import cbdLogo from "../assets/cbdpage.png";
import styles from "./style.module.sass";

const paths = ["/forgot", "/ativar"];
const Empty = () => <></>;

const HeaderItem = memo(({ title, target, notification, onClick=() => {}, roles=[], icon: Icon = Empty, subItems=[] }) => {
    const [notifications, setNotifications] = useState(0);
    useEffect(() => {
        const id = notification?.run(setNotifications);
        return () => !!id && clearInterval(id);
    },[])
    return (
        <When condition={isAllow(roles)}>
            <If condition={!subItems.length}>
                <Then>
                    <NavItem className="pl-2 d-md-none">
                        <NavLink tag={Link} to={target} onClick={onClick}>
                            <Icon size={25} />
                            <span>&nbsp;{ title }&nbsp;</span>
                            <When condition={notifications}>
                                <Badge color="danger">{notifications}</Badge>
                            </When>
                        </NavLink>
                    </NavItem>
                </Then>
                <Else>
                    <UncontrolledDropdown nav inNavbar className="pl-2 d-md-none">
                        <DropdownToggle nav caret>
                            <Icon size={25} />
                            <span>&nbsp;{ title }</span>
                        </DropdownToggle>
                        <DropdownMenu right className="bg-dark" style={{ border: 0 }}>
                            {
                                subItems.map(item => {
                                    const SubIcon = item.icon;
                                    return (
                                        <When key={`item-${uuid()}`} condition={isAllow(item.roles)}>
                                            <DropdownItem className="bg-dark" style={{ cursor: "pointer", color: "rgba(255, 255, 255, 0.5)" }} tag={Link} to={item.target} onClick={onClick}>
                                                <SubIcon size={25}/>
                                                <span className="d-inline nav-link">&nbsp;{item.title}</span>
                                            </DropdownItem>
                                        </When>
                                    )
                                })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Else>
            </If>
        </When>
    );
});

export default memo(() => {
    const isMobile = useMobile();
    const history = useHistory();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(!isMobile);
    const [isOpenProfile, setIsOpenProfile] = useState(false);
    const toggle = useCallback(() => setIsOpen(v=> !v), []);
    const onClick = useCallback(() => {
        setIsOpenProfile(v=> !v);
        setIsOpen(false);
    }, []);
    const logout = () => {
        AuthorizerStore.setToken("");
        history.push('/login');
    };
    if (AuthorizerStore.authenticated && paths.includes(location.pathname)) {
      history.replace("/login");
    }
    useEffect(() => { !AuthorizerStore.authenticated && setIsOpenProfile(false); }, [AuthorizerStore.authenticated]);
    return (
        <>
            <Navbar color="dark" dark expand="md" fixed="fixed" className={styles.navbar}>
                <NavbarBrand role="button" tag={Link} to="/" onClick={() => sessionStorage.clear()} className={classnames([{ 'pl-2': !isMobile }])}>
                    <img src={cbdLogo} alt='logo' style={{ height: 41 }} />
                </NavbarBrand>
                <small className="pl-1 d-inline" style={{ color: "white" }}>
                    {process.env.VERSION}
                    <When condition={!isMobile}>
                        <span style={{ color: 'transparent' }}>
                        .{process.env.BUILD}
                        </span>
                    </When>
                </small>
                <When condition={AuthorizerStore.authenticated}>
                    <div className="d-md-none">
                        <NavbarToggler onClick={toggle} />
                    </div>
                    <div className="w-100 d-flex">
                        <Collapse isOpen={isOpen} navbar >
                            <br className="d-md-none"/>
                            <Nav navbar>
                                <NavItem className={classnames([{ 'pl-2': isMobile, [styles.profile]: !isMobile }])}>
                                    <NavLink tag={Link} onClick={onClick}>
                                        <FaUserCircle size={25} />
                                        <span>&nbsp;{ getCurrentUser()?.GivenName }</span>
                                    </NavLink>
                                </NavItem>
                                { productItems.map(item => <HeaderItem key={`item_${uuid()}`} { ...item } onClick={toggle} />) }
                                { apiItems.map(item => <HeaderItem key={`item_${uuid()}`} { ...item } onClick={toggle} />) }
                                { adminItems.map(item => <HeaderItem key={`item_${uuid()}`} { ...item } onClick={toggle} />) }
                                <HeaderItem title="Logout" icon={MdLogout} onClick={logout}/>
                            </Nav>
                        </Collapse>
                    </div>
                </When>
            </Navbar>
            <ProfilePage isOpen={isOpenProfile} toggleProfile={onClick} />
        </>
    )
});