import React, { useMemo, useState } from "react";
import { GoogleMap, HeatmapLayer } from "@react-google-maps/api";
import { Else, If, Then, When } from "react-if";
import MapControls, { HeartMapControl, DevicesMapControl } from "./controls";
import { getPoints, useLoad, useMap, useClusters } from "./utils";
import Directions from "./Directions";
import Legend from "./Legend";
import PinType from "./type";
import Pin from "./Pin";

const heatOptions = {
    radius: 15,
    gradient: [
      "rgba(0, 255, 255, 0)",
      "rgba(0, 255, 255, 1)",
      "rgba(0, 191, 255, 1)",
      "rgba(0, 127, 255, 1)",
      "rgba(0, 63, 255, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(0, 0, 223, 1)",
      "rgba(0, 0, 191, 1)",
      "rgba(0, 0, 159, 1)",
      "rgba(0, 0, 127, 1)",
      "rgba(63, 0, 91, 1)",
      "rgba(127, 0, 63, 1)",
      "rgba(191, 0, 31, 1)",
      "rgba(255, 0, 0, 1)",
    ]
}

export default props => {
    const isLoaded = useMap();
    return (
        <When condition={props.markers.length && isLoaded}>
            <Map {...props}/>
        </When>
    )

}

const Map = ({ children, selected, markers=[], showHertmapDefault=false, showDevicesDefault=false, showDirectionsDefault=false, showLegend=true, controlComponents=[HeartMapControl, DevicesMapControl], controls={} }) => {
    const [showHertmap, setShowHertmap] = useState(showHertmapDefault);
    const [showDevices, setShowDevices] = useState(showDevicesDefault);
    const [showDirections] = useState(showDirectionsDefault);
    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState(10);
    
    const onLoad = useLoad(markers, setMap);
    const clusters = useClusters(zoom, markers);
    
    const devices = markers.filter(({ type }) => type === PinType.DEVICE);
    const others = markers.filter(({ type }) => ![PinType.DEVICE, PinType.HIT, PinType.TOP].includes(type));

    const showHertmapMemorize = useMemo(() => showHertmapDefault, []);
    const showDevicesMemorize = useMemo(() => showDevicesDefault, []);
    // const showDirectionsMemorize = useMemo(() => showDirectionsDefault, []);
    const defaultControls = useMemo(() => ({
        [HeartMapControl.name]: {
            defaultValue: showHertmapMemorize,
            onChange: () => setShowHertmap(v => !v)
        },
        [DevicesMapControl.name]: {
            defaultValue: !showDevicesMemorize,
            onChange: () => setShowDevices(v => !v)
        }
    }), []);
    
    return (
        <>
            <MapControls controlComponents={controlComponents} controls={{...defaultControls, ...controls}}/>
            <When condition={showLegend}>
                { children || <Legend/> }
            </When>
            <GoogleMap mapContainerClassName="w-100 h-100" zoom={10} onLoad={onLoad} onZoomChanged={() => setZoom(map?.zoom)}>
                <When condition={showDirections}>
                    <Directions origin={markers[0].position} destination={markers[markers.length - 1].position}/>
                </When>
                <When condition={showDevices}>
                    {devices.map(d => <Pin key={d.device} {...d}/>)}
                </When>
                { others.map(({ id, type, position, address }) => <Pin key={id} type={type} position={position} address={address}/>) }
                <If condition={!showHertmap}>
                    <Then>
                        { clusters.map(cluster => <Pin key={cluster.id} selected={selected} {...cluster}/>)}
                    </Then>
                    <Else>
                        <HeatmapLayer options={heatOptions} data={getPoints(markers)} />
                    </Else>
                </If>
            </GoogleMap>
        </>
    )
}