import { lazy } from 'react'
export const ActivatePage = lazy(() => import('./activate'));
export const CameraPages = lazy(() => import('./camera'));
export const TrackingPage = lazy(() => import('./cartracking'));
export const ComingSoonPage = lazy(() => import('./coming-soon'));
export const CompaniesPage = lazy(() => import('./companies'));
export const CustomersPages = lazy(() => import('./customers'));
export const DashboardPage = lazy(() => import('./dashboard'));
export const DossierPages = lazy(() => import('./dossier'));
export const FeedbackPages = lazy(() => import('./feedback'));
export const ForgotPage = lazy(() => import('./forgot'));
export const HitPage = lazy(() => import('./hit'));
export const TrackerPages = lazy(() => import('./tracker'));
export const LoginPage = lazy(() => import('./login'));
export const NotificationsPage = lazy(() => import('./notifications'));
export const QualiApiPage = lazy(() => import('./quali-api'));
export const QualityPage = lazy(() => import('./quality'));
export const TargetListPage = lazy(() => import('./targetList'));
export const UserPages = lazy(() => import('./user'));
export const UploaderPage = lazy(() => import('./uploader'));
export const IntegratedPage = lazy(() => import('./integrated'));
export const CataplacaPage = lazy(() => import('./cataplaca'));
export const SolicitationPage = lazy(() => import('./enrollment'));
export const ProviderPages = lazy(() => import('./providers'));
export const ClonedPages = lazy(() => import('./cloned'));
